import {
  CalendarOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  ExclamationCircleOutlined,
  FileAddOutlined,
  FileTextOutlined,
  FormOutlined,
  MinusCircleOutlined,
  PercentageOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
  UnorderedListOutlined,
  UserOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Statistic,
  Tag,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { callStatus } from '../utils/constants';
import { compareObject, convertDate } from '../utils/funtions';
import { useHistory } from 'react-router-dom';
import { getBySearch as getProductosBySearch } from '../redux/actions/productoActions';
import { getAll as depositos } from '../redux/actions/depositoActions';
import { getAll as ubicaciones } from '../redux/actions/ubicacionActions';
import {
  get,
  save,
  setPending,
} from '../redux/actions/productoMovimientoActions';

const { confirm, info, error, success } = Modal;
const { Option } = Select;

const { Content, Header, Footer } = Layout;

const TitleCard = styled.div`
  display: flex;
  align-items: center;
`;

const ProductoMovimientoEditor = ({
  dispatch,
  stateData,
  ubicacion,
  deposito,
  producto,
  productoMovimientoEstado,
  match: {
    params: { id },
  },
}) => {
  const [form] = Form.useForm();
  const [formProducto] = Form.useForm();
  const [initial, setInitial] = useState({});
  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  const history = useHistory();
  const productoRef = useRef(null);

  const [depositoList, setDepositoList] = useState([]);

  const [ubicacionId, setUbicacionId] = useState(null);
  const [productoId, setProductoId] = useState(null);
  const [depositoId, setDepositoId] = useState(null);
  const [productoList, setProductoList] = useState([]);
  const [errorItemList, setErrorItemList] = useState(null);
  const [countNid, setCountNid] = useState(-1);

  useEffect(() => {
    if (id && id !== '0') {
      dispatch(get(id));
    }

    depositos(dispatch);
    ubicaciones(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setProductoList(producto.list.data);
    // eslint-disable-next-line
  }, [producto]);

  useEffect(() => {
    if (stateData.object.status === callStatus.OBJECT_PENDING) {
      let data = cloneDeep(stateData.object.data);
      data['fecha'] = moment(moment(data.fecha));
      data['fecha_recepcion'] = moment(moment(data.fecha_recepcion));
      form.setFieldsValue(data);
      setInitial(data);
    } else if (
      stateData.object.status === callStatus.OBJECT_SUCCESS ||
      stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS
    ) {
      let data = cloneDeep(stateData.object.data);
      data['fecha'] = dayjs(
        new Date(moment(moment(convertDate(data.fecha)))).toISOString()
      ).format(formatDisplay);

      if (data.estado_id === 1) {
        data['fecha_recepcion'] = moment(new Date());
      } else {
        data['fecha_recepcion'] = dayjs(
          new Date(
            moment(moment(convertDate(data.fecha_recepcion)))
          ).toISOString()
        ).format(formatDisplay);
      }

      data['items'] = data.items.map((item) => {
        return {
          producto_nid: item.id,
          id: item.id,
          producto_nombre: item.producto.nombre,
          producto_cantidad: item.cantidad,
          producto_id: item.producto_id ? item.producto_id : null,

          deposito_origen_id: item.deposito_origen_id,
          deposito_origen_nombre: `${item.deposito_origen.nombre} (${item.deposito_origen.ubicacion.nombre})`,

          deposito_destino_id: item.deposito_destino_id,
        };
      });
      form.setFieldsValue(data);
      setInitial(data);
      if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
        handleSaveSuccess('El movimiento se guardó con éxito');
      }
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleSaveError(stateData.object.error);
    }
    // eslint-disable-next-line
  }, [stateData.object]);

  const handleInfo = (message) => {
    info({
      title: message,
      onOk() {},
    });
  };
  const handleSaveError = (message) => {
    error({
      title: message,
      onOk() {},
    });
  };

  const handleSaveSuccess = (message) => {
    success({
      title: message,
      onOk() {
        history.push(`/producto-movimientos`);
      },
    });
  };

  const initProductoValues = () => {
    setProductoId(null);
    setDepositoList([]);
    setDepositoId(null);

    formProducto.setFieldsValue({
      deposito_origen_id: null,
      producto_id: null,
      producto_nombre: null,
      producto_cantidad: null,
      producto_stock: null,
      producto_stock_minimo: null,
    });
  };
  const handleOnSearchDebouncedProducto = useCallback(
    debounce((value) => {
      if (value && value.length > 3) {
        dispatch(getProductosBySearch(value));
      }
    }, 500),
    []
  );

  const handleOnSearchProducto = (value) => {
    setProductoList([]);
    handleOnSearchDebouncedProducto(value);
  };

  const handleOnChangeDeposito = () => {
    const id = formProducto.getFieldValue('deposito_origen_id');
    onChangeProducto(productoId, null, id);
  };

  const onChangeProducto = (value, comboLista = null, comboDeposito = null) => {
    const result = productoList.find((item) => item.id === value);

    if (result) {
      let ubicacion = form.getFieldValue('ubicacion_origen_id');
      let depositosResult = result.depositos
        ? result.depositos.filter(
            (item) => item.deposito.ubicacion_id === ubicacion
          )
        : [];

      if (depositosResult.length === 0) {
        if (value !== undefined) {
          handleInfo(
            'El producto seleccionado debe estar en un Depósito de la Ubicación de origen'
          );
        }
        initProductoValues();
        setProductoList([]);
      } else {
        let productodeposito = [];

        productodeposito = comboDeposito
          ? depositosResult.find((item) => item.id === comboDeposito)
          : depositosResult[0];

        let cantidad = !formProducto.getFieldValue('producto_cantidad')
          ? 1
          : formProducto.getFieldValue('producto_cantidad');

        formProducto.setFieldsValue({
          producto_nombre: result.nombre,
          producto_cantidad: cantidad,
          producto_stock: productodeposito.cantidad,
          producto_stock_minimo: productodeposito.cantidad_minima,
          deposito_origen_nombre: `${productodeposito.deposito.nombre} (${productodeposito.deposito.ubicacion.nombre})`,
        });

        setProductoId(result.id);
        setDepositoId(productodeposito.deposito.id);
        setDepositoList(depositosResult);

        formProducto.setFieldsValue({
          deposito_origen_id: productodeposito.id,
        });
      }
    } else {
      initProductoValues();
      setProductoList([]);
    }
  };
  const getDetalleProductoCantidad = (id) => {
    let updateItems = form.getFieldValue('items');
    let cantidad = 0;
    if (updateItems) {
      const productos = [
        ...updateItems.filter(
          (item) => item.producto_id === id && item.producto_tipo === 'producto'
        ),
      ];
      productos.forEach((item) => {
        cantidad = cantidad + parseFloat(item.producto_cantidad);
      });
    }
    return cantidad;
  };

  const handleAddItems = (item) => {
    const items = form.getFieldValue('items');

    if (items) {
      form.setFieldsValue({
        items: [
          ...items,
          {
            ...item,
            producto_nid: countNid,
            producto_new: true,
          },
        ],
      });
    } else {
      form.setFieldsValue({
        items: [
          {
            ...item,
            producto_nid: countNid,
            producto_new: true,
          },
        ],
      });
    }

    setCountNid((prev) => prev - 1);
    initProductoValues();
    setErrorItemList(null);
    setProductoList([]);
  };

  const handleUpdateItems = (item = undefined) => {
    initProductoValues();
    setErrorItemList(null);
  };

  const onChangeUbicacion = (value) => {
    setUbicacionId(value);
    initProductoValues();
  };

  const onCreate = (data, estado) => {
    data.estado_id = estado;

    if (estado === 4 && (!data.observacion || data.observacion === '')) {
      handleSaveError('Debe escribir un motivo de rechazo como observación');
    } else {
      let message = '';
      switch (estado) {
        case 1:
          data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);
          message =
            'Esta a punto de generar un pedido de movimiento de productos entre depósitos, desea continuar?';
          break;
        case 3:
          data.fecha = stateData.object.data.fecha;
          data.fecha_recepcion = dayjs(
            new Date(data.fecha_recepcion).toISOString()
          ).format(format);
          message =
            'Esta a punto de aceptar el movimiento de productos entre despósitos, desea continuar?';
          break;
        case 4:
          data.fecha = stateData.object.data.fecha;
          data.fecha_recepcion = dayjs(
            new Date(data.fecha_recepcion).toISOString()
          ).format(format);
          message =
            'Esta a punto de rechazar el movimiento de productos entre despósitos, desea continuar?';
          break;
        default:
          break;
      }

      confirm({
        title: message,
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          dispatch(save(data));
        },
        onCancel() {},
      });
    }
  };

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          dispatch(setPending());
          history.push(`/producto-movimientos`);
        },
        onCancel() {},
      });
    } else {
      dispatch(setPending());
      history.push(`/producto-movimientos`);
    }
  };

  return (
    <Spin spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}>
      <Layout style={{ height: '100%' }}>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={stateData.object.data}
          size="small"
        >
          <Header>
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="id" label="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Card
              size="small"
              style={{ marginBottom: '10px' }}
              extra={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {stateData.object.data.id && (
                    <>
                      <span style={{ fontSize: '18px', marginRight: '10px' }}>
                        <b> Comprobante N°: {stateData.object.data.id}</b>
                      </span>
                      {stateData.object.data.estado_id === 4 && (
                        <Tag color="#e90e0e">Rechazado</Tag>
                      )}

                      {stateData.object.data.estado_id === 3 && (
                        <Tag color="#159e0d">Aceptado</Tag>
                      )}
                    </>
                  )}
                </div>
              }
              title={
                <TitleCard>
                  <Avatar
                    icon={<CalendarOutlined />}
                    size="small"
                    style={{ backgroundColor: '#4f43ba' }}
                  />
                  <span style={{ marginLeft: '3px' }}> Datos Generales</span>
                </TitleCard>
              }
            >
              <Row gutter={[8, 0]} align="top">
                <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                  <Form.Item
                    name="fecha"
                    label="Fecha emisión"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    {!stateData.object.data.id ? (
                      <DatePicker
                        format={formatDisplay}
                        style={{ width: '100%' }}
                        initialValue={moment(new Date())}
                      />
                    ) : (
                      <Input readOnly />
                    )}
                  </Form.Item>
                </Col>
                {stateData.object.data.id && (
                  <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                    <Form.Item
                      name="fecha_recepcion"
                      label="Fecha recepción"
                      rules={[
                        {
                          required: true,
                          message: 'Requerido',
                        },
                      ]}
                    >
                      {stateData.object.data.estado_id === 1 ? (
                        <DatePicker
                          format={formatDisplay}
                          style={{ width: '100%' }}
                          initialValue={moment(new Date())}
                        />
                      ) : (
                        <Input readOnly />
                      )}
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24} sm={12} md={6} lg={4} xl={4}>
                  <Form.Item
                    name="ubicacion_origen_id"
                    label="Ubicación Origen"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select
                      onChange={onChangeUbicacion}
                      open={stateData.object.data.id ? false : undefined}
                    >
                      {ubicacion.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6} lg={4} xl={4}>
                  <Form.Item
                    name="ubicacion_destino_id"
                    label="Ubicación Destino"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select open={stateData.object.data.id ? false : undefined}>
                      {ubicacion.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="observacion" label="Observaciones">
                    <Input.TextArea rows={2} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Header>
          <Content>
            <Card
              title={
                <TitleCard>
                  <Avatar
                    icon={<UnorderedListOutlined />}
                    size="small"
                    style={{ backgroundColor: '#4f43ba' }}
                  />
                  <span style={{ marginLeft: '3px' }}> Items</span>
                </TitleCard>
              }
              style={{ marginBottom: '10px' }}
              size="small"
            >
              <Form
                layout="vertical"
                form={formProducto}
                style={{ width: '100%' }}
              >
                {!stateData.object.data.id && (
                  <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={18} lg={12} xl={12}>
                      <Form.Item
                        name="producto_id"
                        label="Item"
                        rules={[
                          {
                            required: true,
                            message: 'Requerido',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={false}
                          onSearch={handleOnSearchProducto}
                          onChange={(e) => onChangeProducto(e)}
                          notFoundContent={null}
                          allowClear
                          ref={productoRef}
                          disabled={!ubicacionId}
                        >
                          {productoList.map((item, index) => (
                            <Option key={index} value={item.id}>
                              {item.codigo
                                ? `${item.codigo} - ${item.nombre}`
                                : item.nombre}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                      <Form.Item name="producto_stock" label="Stock Actual">
                        <Input readOnly={true} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                      <Form.Item name="producto_stock_minimo" label="Stock Min">
                        <Input readOnly={true} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                      <Form.Item
                        name="deposito_origen_id"
                        label="Depósito"
                        rules={[
                          {
                            required: true,
                            message: 'Requerido',
                          },
                        ]}
                      >
                        <Select
                          disabled={!ubicacionId}
                          open={depositoList.length === 1 ? false : undefined}
                          onChange={handleOnChangeDeposito}
                        >
                          {depositoList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {`${item.deposito.nombre} (${item.deposito.ubicacion.nombre})`}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                      <Form.Item
                        name="producto_cantidad"
                        style={{ textAlign: 'right' }}
                        label="Cantidad"
                        rules={[
                          {
                            required: true,
                            message: 'Requerido',
                          },
                        ]}
                      >
                        <Input
                          min={0}
                          type="number"
                          style={{ textAlign: 'right' }}
                          disabled={!ubicacionId}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Form>

              <Row gutter={[8, 0]} align="middle">
                <Form.List
                  name="items"
                  rules={[
                    {
                      validator: async (_, items) => {
                        if (!items || !items.length) {
                          return Promise.reject(
                            new Error('Ingrese al menos un item')
                          );
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {(!stateData.object.data.id ||
                        (stateData.object.data.tipo_id < 0 &&
                          !stateData.object.data.parent_asociado)) && (
                        <Row
                          gutter={[8, 0]}
                          style={{ width: '100%' }}
                          justify="center"
                          align="top"
                        >
                          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => {
                                  formProducto.validateFields([
                                    'deposito_origen_id',
                                    'producto_id',
                                    'producto_nombre',
                                    'producto_cantidad',
                                  ]);

                                  if (
                                    formProducto.getFieldValue(
                                      'producto_cantidad'
                                    )
                                  ) {
                                    const item = {
                                      producto_nid: null,

                                      deposito_origen_id: depositoId,
                                      deposito_origen_nombre:
                                        formProducto.getFieldValue(
                                          'deposito_origen_nombre'
                                        ),
                                      producto_id:
                                        formProducto.getFieldValue(
                                          'producto_id'
                                        ),
                                      producto_nombre:
                                        formProducto.getFieldValue(
                                          'producto_nombre'
                                        ),

                                      producto_cantidad:
                                        formProducto.getFieldValue(
                                          'producto_cantidad'
                                        ),
                                    };
                                    let error = false;
                                    if (
                                      form.getFieldValue('items') &&
                                      form
                                        .getFieldValue('items')
                                        .some((value) => {
                                          return (
                                            value.producto_id ===
                                            item.producto_id
                                          );
                                        })
                                    ) {
                                      setErrorItemList(
                                        'El item seleccionado ya existe'
                                      );
                                      error = true;
                                    }

                                    if (
                                      !error &&
                                      formProducto.getFieldValue(
                                        'producto_stock'
                                      )
                                    ) {
                                      const stock =
                                        formProducto.getFieldValue(
                                          'producto_stock'
                                        );
                                      const cantidad = item.producto_cantidad;
                                      const productoCantidad =
                                        parseFloat(cantidad) +
                                        getDetalleProductoCantidad(
                                          item.producto_id
                                        );
                                      if (
                                        productoCantidad > parseFloat(stock)
                                      ) {
                                        setErrorItemList(
                                          'La cantidad en stock del producto no es suficiente'
                                        );
                                        error = true;
                                      }
                                    }

                                    if (!error) {
                                      handleAddItems(item);
                                      handleUpdateItems();
                                    }
                                  }
                                }}
                                block
                                icon={<PlusOutlined />}
                                danger={
                                  errors.length || errorItemList ? true : false
                                }
                              >
                                Agregar item
                              </Button>
                              <span style={{ color: 'red' }}>
                                {errorItemList}
                              </span>

                              <Form.ErrorList errors={errors} />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                      <Row
                        gutter={[8, 0]}
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          fontWeight: '500',
                          display:
                            (!form.getFieldValue('items') ||
                              form.getFieldValue('items').length === 0) &&
                            'none',
                        }}
                        align="top"
                        justify="center"
                      >
                        <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                          Item
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                          Depósito origen
                        </Col>
                        {stateData.object.data.id && (
                          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                            Depósito destino
                          </Col>
                        )}
                        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                          Cantidad
                        </Col>

                        <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
                      </Row>
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, index) => (
                          <Row
                            gutter={[8, 0]}
                            style={{ width: '100%' }}
                            key={index}
                            align="top"
                            justify="center"
                          >
                            {form.getFieldValue('items')[index]
                              .producto_parent && (
                              <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                            )}

                            <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                              <Form.Item
                                {...restField}
                                name={[name, 'producto_nombre']}
                                fieldKey={[fieldKey, 'producto_nombre']}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                              <Form.Item
                                {...restField}
                                name={[name, 'deposito_origen_nombre']}
                                fieldKey={[fieldKey, 'deposito_origen_nombre']}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                            {stateData.object.data.id && (
                              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'deposito_destino_id']}
                                  fieldKey={[fieldKey, 'deposito_destino_id']}
                                  rules={[
                                    { required: true, message: 'Requerido' },
                                  ]}
                                >
                                  <Select
                                    open={
                                      stateData.object.data.estado_id !== 1
                                        ? false
                                        : undefined
                                    }
                                  >
                                    {deposito.list.data
                                      .filter(
                                        (item) =>
                                          item.ubicacion_id ===
                                          stateData.object.data
                                            .ubicacion_destino_id
                                      )
                                      .map((item) => (
                                        <Select.Option
                                          key={item.id}
                                          value={item.id}
                                        >
                                          {`${item.nombre} (${item.ubicacion.nombre})`}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            )}
                            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                              <Form.Item
                                {...restField}
                                name={[name, 'producto_cantidad']}
                                fieldKey={[fieldKey, 'producto_cantidad']}
                                rules={[
                                  { required: true, message: 'Requerido' },
                                ]}
                              >
                                <Input
                                  min={0}
                                  readOnly
                                  // type="number"
                                  style={{ textAlign: 'right' }}
                                  // onBlur={() => calcSubtotalDetalle(index)}
                                />
                              </Form.Item>
                            </Col>

                            {!stateData.object.data.id && (
                              <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                                <MinusCircleOutlined
                                  onClick={() => {
                                    const itemDeleted =
                                      form.getFieldValue('items')[index];
                                    remove(name);
                                    handleUpdateItems(itemDeleted);
                                  }}
                                  style={{ color: 'red' }}
                                />
                                {form.getFieldValue('items')[index]
                                  .producto_asociado && (
                                  <FileAddOutlined
                                    style={{
                                      marginLeft: '10px',
                                      color: 'blue',
                                    }}
                                  />
                                )}
                              </Col>
                            )}
                          </Row>
                        )
                      )}
                    </>
                  )}
                </Form.List>
              </Row>
            </Card>
          </Content>
          <Footer style={{ paddingRight: 0 }}>
            <Row gutter={[8, 0]} justify="end" style={{ marginBottom: '10px' }}>
              <Col>
                <Button
                  type="default"
                  icon={<CloseCircleOutlined />}
                  size="small"
                  onClick={(e) => handleOnCancel()}
                >
                  Salir
                </Button>
              </Col>
              {!stateData.object.data.id && (
                <Col>
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    size="small"
                    onClick={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          onCreate(values, 1);
                        })
                        .catch((info) => {
                          console.log('Validate Failed:', info);
                        });
                    }}
                  >
                    Generar Movimiento
                  </Button>
                </Col>
              )}
              {stateData.object.data.id &&
                stateData.object.data.estado_id === 1 && (
                  <>
                    <Col>
                      <Button
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        size="small"
                        style={{
                          color: 'white',
                          background: 'green',
                          borderColor: 'green',
                        }}
                        onClick={() => {
                          form
                            .validateFields()
                            .then((values) => {
                              onCreate(values, 3);
                            })
                            .catch((info) => {
                              console.log('Validate Failed:', info);
                            });
                        }}
                      >
                        Aceptar Movimiento
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        danger
                        icon={<CloseCircleOutlined />}
                        size="small"
                        onClick={() => {
                          form
                            .validateFields()
                            .then((values) => {
                              onCreate(values, 4);
                            })
                            .catch((info) => {
                              console.log('Validate Failed:', info);
                            });
                        }}
                      >
                        Rechazar Movimiento
                      </Button>
                    </Col>
                  </>
                )}
            </Row>
          </Footer>
        </Form>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  const {
    productoMovimiento,
    deposito,
    productoDeposito,
    ubicacion,
    producto,
    productoMovimientoEstado,
  } = state;
  const stateData = productoMovimiento;
  return {
    stateData,
    deposito,
    productoDeposito,
    ubicacion,
    producto,
    productoMovimientoEstado,
  };
};
export default connect(mapStateToProps)(ProductoMovimientoEditor);
