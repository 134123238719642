import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import ComprobanteViewerAlicuotas from './ComprobanteViewerAlicuotas';
import { formatNumber, viewAlicuotas } from '../../utils/funtions';

// Create styles
const styles = StyleSheet.create({
  contacto: {
    marginBottom: 2,
    fontSize: 10,
  },
});

const PieTotales = ({ data, qr }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        border: 1,
        padding: 3,
        justifyContent: viewAlicuotas(data.tipo_id)
          ? 'space-between'
          : 'flex-end',
      }}
    >
      {viewAlicuotas(data.tipo_id) && (
        <ComprobanteViewerAlicuotas data={data} />
      )}

      <View
        style={{
          flexDirection: 'column',
          fontSize: 14,
          width: '170px',
        }}
      >
        {viewAlicuotas(data.tipo_id) && (
          <>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text style={[styles.contacto, { width: '70px' }]}>
                Neto Gravado:{' '}
              </Text>
              <Text
                style={[
                  styles.contacto,
                  { width: '100px', textAlign: 'right' },
                ]}
              >
                ${formatNumber(data.neto)}
              </Text>
            </View>

            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <Text style={[styles.contacto, { width: '70px' }]}>IVA: </Text>
              <Text
                style={[
                  styles.contacto,
                  { width: '100px', textAlign: 'right' },
                ]}
              >
                ${formatNumber(data.iva)}
              </Text>
            </View>
          </>
        )}

        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <Text style={[styles.contacto, { width: '70px' }]}>Total: </Text>
          <Text
            style={[styles.contacto, { width: '100px', textAlign: 'right' }]}
          >
            ${formatNumber(data.total)}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default PieTotales;
