import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import { Modal } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import EncabezadoEmpresa from './EncabezadoEmpresa';
import CtaCteViewerItems from './CtaCteViewerItems';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { callStatus } from '../../utils/constants';
import CobroComisionesViewerItems from './CobroComisionesViewerItems';
import moment from 'moment';
import { convertDate } from '../../utils/funtions';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    flexGrow: 1,
  },
  text: {
    marginBottom: 3,
    fontSize: 14,
  },
  contacto: {
    marginBottom: 2,
    fontSize: 10,
  },
  tableHeader: {
    fontSize: 10,
  },
  tableData: {
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 10,
  },
  // firma: {
  //   border: 1,
  //   height: '100px',
  //   flex: 1,
  //   marginLeft: '10px',
  //   marginTop: '15px',
  // },
});

const MyDocument = ({ stateData, totales, tipo, config }) => {
  let data = cloneDeep(stateData);
  const formatDisplay = 'DD/MM/YYYY';

  const currentDate = dayjs(new Date().toISOString()).format(formatDisplay);

  data = [
    ...data.map((item) => {
      return {
        ...item,
        fecha: dayjs(
          new Date(moment(moment(convertDate(item.fecha)))).toISOString()
        ).format(formatDisplay),
        cliente: item.cliente.nombre,
      };
    }),
  ];

  return (
    <Document size="A4">
      <Page style={{ padding: 15 }} wrap>
        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: 5,
            borderBottom: 1,
          }}
        >
          <View
            style={{
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Image
              style={{
                width: '200px',
              }}
              src="logo.png"
            />
          </View>

          <View
            style={{
              flexDirection: 'column',
            }}
          >
            <Text style={[styles.contacto, { fontSize: 18 }]}>Comisiones</Text>
            <Text style={styles.contacto}>Fecha Emisión: {currentDate}</Text>
          </View>
        </View>

        <EncabezadoEmpresa data={config} />

        <View style={{ flex: 1, flexDirection: 'column', marginTop: '10px' }}>
          <CobroComisionesViewerItems data={data} />
        </View>

        <View
          style={{
            flexDirection: 'row',
            border: 1,
            padding: 3,
            justifyContent: 'flex-end',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <Text style={[styles.contacto, { fontSize: '12px' }]}>
              Suma saldos:
            </Text>
            <Text
              style={[
                styles.contacto,
                { fontSize: '12px', textAlign: 'right' },
              ]}
            >
              ${totales.saldo}
            </Text>

            {tipo === 2 && (
              <>
                <Text
                  style={[
                    styles.contacto,
                    { fontSize: '12px', marginLeft: '8px' },
                  ]}
                >
                  Porcentaje comisión:
                </Text>
                <Text
                  style={[
                    styles.contacto,
                    { fontSize: '12px', textAlign: 'right' },
                  ]}
                >
                  {totales.comision}%
                </Text>
                <Text
                  style={[
                    styles.contacto,
                    { fontSize: '12px', marginLeft: '8px' },
                  ]}
                >
                  Total comisión:
                </Text>
                <Text
                  style={[
                    styles.contacto,
                    { fontSize: '12px', textAlign: 'right' },
                  ]}
                >
                  ${totales.total}
                </Text>
              </>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
};

const CobroComisionesViewer = ({
  data,
  totales,
  tipo,
  configData,
  visible,
  onCancel,
}) => {
  return configData.list.status === callStatus.LIST_SUCCESS ? (
    <Modal
      centered
      maskClosable={false}
      visible={visible}
      title="Impresión Comisiones"
      onCancel={onCancel}
      width={700}
      bodyStyle={{ padding: 0, background: '#3a3d3f ' }}
      footer={null}
    >
      <PDFViewer
        style={{
          width: '100%',
          height: '400px',
          border: 'none',
          margin: 0,
          padding: 0,
          marginTop: '-2px',
        }}
      >
        <MyDocument
          stateData={data}
          totales={totales}
          tipo={tipo}
          config={configData.list.data}
        />
      </PDFViewer>
    </Modal>
  ) : null;
};

const mapStateToProps = (state) => {
  const { configuracion } = state;
  const configData = configuracion;
  return {
    configData,
  };
};
export default connect(mapStateToProps)(CobroComisionesViewer);
