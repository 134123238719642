import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import { Modal } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import EncabezadoEmpresa from './EncabezadoEmpresa';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { callStatus } from '../../utils/constants';
import ClienteDeudoresViewerItems from './ClienteDeudoresViewerItems';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    flexGrow: 1,
  },
  text: {
    marginBottom: 3,
    fontSize: 14,
  },
  contacto: {
    marginBottom: 2,
    fontSize: 10,
  },
  tableHeader: {
    fontSize: 10,
  },
  tableData: {
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 10,
  },
});

const MyDocument = ({ stateData, tipo, config }) => {
  let data = cloneDeep(stateData);
  const formatDisplay = 'DD/MM/YYYY';

  const currentDate = dayjs(new Date().toISOString()).format(formatDisplay);

  data = [
    ...data.map((item) => {
      return {
        ...item,
        cliente: item.nombre,
        total: tipo === 0 ? item.saldo_final : item.debito,
      };
    }),
  ];

  return (
    <Document size="A4">
      <Page style={{ padding: 15 }} wrap>
        <View
          fixed
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: 5,
            borderBottom: 1,
          }}
        >
          <View
            style={{
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Image
              style={{
                width: '200px',
              }}
              src="logo.png"
            />
          </View>

          <View
            style={{
              flexDirection: 'column',
            }}
          >
            <Text style={[styles.contacto, { fontSize: 18 }]}>
              {tipo === 0
                ? 'Deudores Cta Cte'
                : tipo === 1
                ? 'Deudores Maquinaria'
                : 'Deudores Respuestos'}
            </Text>
            <Text style={styles.contacto}>Fecha Emisión: {currentDate}</Text>
          </View>
        </View>

        <EncabezadoEmpresa data={config} />

        <View style={{ flex: 1, flexDirection: 'column', marginTop: '10px' }}>
          <ClienteDeudoresViewerItems data={data} />
        </View>
      </Page>
    </Document>
  );
};

const ClienteDeudoresViewer = ({
  data,
  tipo,
  configData,
  visible,
  onCancel,
}) => {
  return configData.list.status === callStatus.LIST_SUCCESS ? (
    <Modal
      centered
      maskClosable={false}
      visible={visible}
      title={
        tipo === 0
          ? 'Impresión Deudores Cta Cte'
          : tipo === 1
          ? 'Impresión Deudores Maquinaria'
          : 'Impresión Deudores Respuestos'
      }
      onCancel={onCancel}
      width={700}
      bodyStyle={{ padding: 0, background: '#3a3d3f ' }}
      footer={null}
    >
      <PDFViewer
        style={{
          width: '100%',
          height: '400px',
          border: 'none',
          margin: 0,
          padding: 0,
          marginTop: '-2px',
        }}
      >
        <MyDocument
          stateData={data}
          tipo={tipo}
          config={configData.list.data}
        />
      </PDFViewer>
    </Modal>
  ) : null;
};

const mapStateToProps = (state) => {
  const { configuracion } = state;
  const configData = configuracion;
  return {
    configData,
  };
};
export default connect(mapStateToProps)(ClienteDeudoresViewer);
