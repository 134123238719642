import React, { useState, useEffect } from 'react';
import { Button, Col, Row, Select, Space } from 'antd';

import { useHistory } from 'react-router-dom';
import MetaTable from './base/MetaTable';
import { clienteApi } from '../utils/api';
import CustomCellRender from './base/CustomCellRender';
import { getAll as rubros } from '../redux/actions/rubroActions';
import { connect } from 'react-redux';
import ClienteDeudoresViewer from './viewer/ClienteDeudoresViewer';

const ClienteDeudores = ({ dispatch, rubro }) => {
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rubroSelected, setRubroSelected] = useState(0);
  const [columns, setColumns] = useState([]);
  const [showViewer, setShowViewer] = useState(false);

  useEffect(() => {
    rubros(dispatch);
    setColumns([
      {
        width: '70%',
        ellipsis: true,
        title: 'Nombre/Razón Social',
        dataIndex: 'nombre',
        sorter: (a, b) => a.nombre.localeCompare(b.nombre),
        search: true,
      },
      {
        width: 100,
        ellipsis: true,
        title: 'Saldo',
        dataIndex: 'saldo_final',
        render: (text) => (
          <CustomCellRender
            text={text}
            justify={'space-between'}
            prefix={'$'}
          />
        ),
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
            <Button onClick={(e) => handleCtaCte(record)}>CtaCte</Button>
          </Space>
        ),
        width: 100,
      },
    ]);
    getDeuda();
    // eslint-disable-next-line
  }, []);

  const getDeuda = () => {
    setLoading(true);
    clienteApi
      .get(`/deudores?rubro=${rubroSelected}`)
      .then((response) => {
        setRows(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // const handleImprimir = (data) => {};

  const handleCtaCte = (data) => {
    history.push(`/clientes/ctacte/${data.id}`);
  };

  const handlePrint = () => {
    setShowViewer(true);
  };

  const handleHideViewer = () => {
    setShowViewer(false);
  };

  const onChangeRubro = (value) => {
    setRubroSelected(value);
    setRows([]);
    if (value === 0) {
      setColumns([
        {
          width: '70%',
          ellipsis: true,
          title: 'Nombre/Razón Social',
          dataIndex: 'nombre',
          sorter: (a, b) => a.nombre.localeCompare(b.nombre),
          search: true,
        },
        {
          width: 100,
          ellipsis: true,
          title: 'Saldo',
          dataIndex: 'saldo_final',
          render: (text) => (
            <CustomCellRender
              text={text}
              justify={'space-between'}
              prefix={'$'}
            />
          ),
        },
        {
          title: '',
          key: 'action',
          render: (text, record) => (
            <Space size="middle">
              <Button onClick={(e) => handleCtaCte(record)}>CtaCte</Button>
            </Space>
          ),
          width: 100,
        },
      ]);
    } else {
      setColumns([
        {
          width: '70%',
          ellipsis: true,
          title: 'Nombre/Razón Social',
          dataIndex: 'nombre',
          sorter: (a, b) => a.nombre.localeCompare(b.nombre),
          search: true,
        },
        {
          width: 100,
          ellipsis: true,
          title: 'Deuda',
          dataIndex: 'debito',
          render: (text) => (
            <CustomCellRender
              text={text}
              justify={'space-between'}
              prefix={'$'}
            />
          ),
        },
        {
          width: 100,
          ellipsis: true,
          title: 'Crédito',
          dataIndex: 'credito',
          render: (text) => (
            <CustomCellRender
              text={text}
              justify={'space-between'}
              prefix={'$'}
            />
          ),
        },
        {
          title: '',
          key: 'action',
          render: (text, record) => (
            <Space size="middle">
              <Button onClick={(e) => handleCtaCte(record)}>CtaCte</Button>
            </Space>
          ),
          width: 100,
        },
      ]);
    }
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Row gutter={[8, 0]}>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <Select
            size="small"
            value={rubroSelected}
            style={{ width: '100%' }}
            onChange={(e) => onChangeRubro(e)}
          >
            {[{ id: 0, nombre: 'Cuenta Corriente' }, ...rubro.list.data].map(
              (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.nombre}
                </Select.Option>
              )
            )}
          </Select>
        </Col>
        <Col xs={24} sm={6} md={3} lg={3} xl={3}>
          <Button
            type="primary"
            size="small"
            onClick={() => getDeuda()}
            style={{ width: '100%' }}
          >
            Filtrar
          </Button>
        </Col>
        <Col xs={24} sm={6} md={3} lg={3} xl={3}>
          <Button
            type="primary"
            size="small"
            onClick={() => handlePrint()}
            style={{ width: '100%' }}
            disabled={rows.length === 0}
          >
            Imprimir
          </Button>
        </Col>
      </Row>

      <div style={{ flex: 1 }}>
        <MetaTable
          dataSource={rows}
          dataColumns={columns}
          bordered={false}
          selection={false}
          loading={loading}
        />
      </div>

      {showViewer && (
        <ClienteDeudoresViewer
          data={rows}
          onCancel={handleHideViewer}
          visible={true}
          tipo={rubroSelected}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { rubro } = state;
  return {
    rubro,
  };
};
export default connect(mapStateToProps)(ClienteDeudores);
