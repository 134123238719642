import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { formatNumber } from '../../utils/funtions';

// Create styles
const styles = StyleSheet.create({
  text: {
    marginBottom: 3,
    fontSize: 14,
  },
  tableHeader: {
    fontSize: 10,
  },
  tableData: {
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 10,
  },
});

const ComprobanteViewerWithoutItems = ({ data }) => {
  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          border: 1,
          padding: 3,
        }}
      >
        <Text style={[styles.tableHeader, { paddingLeft: '3px', flex: 1 }]}>
          Descripción
        </Text>
        <Text
          style={[styles.tableHeader, { paddingLeft: '3px', width: '110px' }]}
        >
          Subtotal
        </Text>
      </View>
      <View style={{ flexDirection: 'row', padding: 3 }}>
        <Text style={[styles.tableData, { textAlign: 'left', flex: 1 }]}>
          {data.observacion}
        </Text>
        <Text
          style={[styles.tableData, { textAlign: 'right', width: '110px' }]}
        >
          {formatNumber(data.total)}
        </Text>
      </View>
    </>
  );
};
export default ComprobanteViewerWithoutItems;
