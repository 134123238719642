import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import { Modal } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import EncabezadoEmpresa from './EncabezadoEmpresa';
import CtaCteViewerItems from './CtaCteViewerItems';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { callStatus } from '../../utils/constants';
import { formatNumber } from '../../utils/funtions';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    flexGrow: 1,
  },
  text: {
    marginBottom: 3,
    fontSize: 14,
  },
  contacto: {
    marginBottom: 2,
    fontSize: 10,
  },
  tableHeader: {
    fontSize: 10,
  },
  tableData: {
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 10,
  },
  // firma: {
  //   border: 1,
  //   height: '100px',
  //   flex: 1,
  //   marginLeft: '10px',
  //   marginTop: '15px',
  // },
});

const MyDocument = ({ stateData, config }) => {
  let data = cloneDeep(stateData);
  const formatDisplay = 'DD/MM/YYYY';

  const currentDate = dayjs(new Date().toISOString()).format(formatDisplay);

  return (
    <Document>
      <Page size="A4">
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 15,
            height: '96%',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 5,
              borderBottom: 1,
            }}
          >
            <View
              style={{
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Image
                style={{
                  width: '200px',
                }}
                src="logo.png"
              />
            </View>

            <View
              style={{
                flexDirection: 'column',
              }}
            >
              <Text style={[styles.contacto, { fontSize: 18 }]}>
                Cuenta Corriente
              </Text>
              <Text style={styles.contacto}>Fecha Emisión: {currentDate}</Text>
            </View>
          </View>

          <EncabezadoEmpresa data={config} />

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              paddingBottom: 5,
              marginTop: '10px',
            }}
          >
            <View
              style={{
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Text style={styles.contacto}>
                {data.nombre} CUIT: {data.cuit}
              </Text>
              <Text style={styles.contacto}>{data.direccion}</Text>
            </View>
            <View
              style={{
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Text style={styles.contacto}>
                Condición IVA: {data.condicion_iva.nombre}
              </Text>
            </View>
          </View>

          <View style={{ flex: 1, flexDirection: 'column' }}>
            <CtaCteViewerItems data={data} />
          </View>
          <View
            style={{
              flexDirection: 'row',
              border: 1,
              padding: 3,
              justifyContent: 'flex-end',
            }}
          >
            <View
              style={{
                flexDirection: 'column',
                fontSize: 14,
                width: '170px',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <Text style={[styles.contacto, { width: '70px' }]}>
                  Saldo:{' '}
                </Text>
                <Text
                  style={[
                    styles.contacto,
                    { width: '100px', textAlign: 'right' },
                  ]}
                >
                  ${formatNumber(data.saldo_final)}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const CtaCteViewer = ({ data, configData, visible, onCancel }) => {
  return configData.list.status === callStatus.LIST_SUCCESS ? (
    <Modal
      centered
      maskClosable={false}
      visible={visible}
      title="Impresión CtaCte"
      onCancel={onCancel}
      width={700}
      bodyStyle={{ padding: 0, background: '#3a3d3f ' }}
      footer={null}
    >
      <PDFViewer
        style={{
          width: '100%',
          height: '400px',
          border: 'none',
          margin: 0,
          padding: 0,
          marginTop: '-2px',
        }}
      >
        <MyDocument stateData={data} config={configData.list.data} />
      </PDFViewer>
    </Modal>
  ) : null;
};

const mapStateToProps = (state) => {
  const { configuracion } = state;
  const configData = configuracion;
  return {
    configData,
  };
};
export default connect(mapStateToProps)(CtaCteViewer);
