import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { columnsCobroMediosPrint } from '../../utils/constants';
import { convertDate2, formatNumber } from '../../utils/funtions';

// Create styles
const styles = StyleSheet.create({
  text: {
    marginBottom: 3,
    fontSize: 14,
  },
  tableHeader: {
    fontSize: 10,
  },
  tableData: {
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 10,
  },
});

const CobroViewerMedios = ({ data }) => {
  if (
    data.efectivo > 0 ||
    data.transferencias.length > 0 ||
    data.tarjetas.length > 0 ||
    data.cheques.length > 0 ||
    data.retenciones.length > 0
  ) {
    return (
      <>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <Text style={styles.text}>Medios de Pago</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            border: 1,
            padding: 3,
            marginTop: '5px',
          }}
        >
          {columnsCobroMediosPrint.map((col, idx) => {
            return (
              <Text
                style={[
                  styles.tableHeader,
                  { paddingLeft: '3px' },
                  col.index === 'detalle' ? { flex: 1 } : { width: col.width },
                  col.index === 'importe' ? {} : { borderRight: 1 },
                ]}
                key={idx}
              >
                {col.title}
              </Text>
            );
          })}
        </View>
        {data.efectivo > 0 && (
          <View style={{ flexDirection: 'row', padding: 3 }}>
            <Text
              style={[styles.tableData, { textAlign: 'left', width: '100px' }]}
            >
              Efectivo
            </Text>
            <Text
              style={[styles.tableData, { textAlign: 'left', flex: 1 }]}
            ></Text>
            <Text
              style={[styles.tableData, { textAlign: 'right', width: '100px' }]}
            >
              {formatNumber(data.efectivo)}
            </Text>
          </View>
        )}

        {data.transferencias.map((item, index) => (
          <View style={{ flexDirection: 'row', padding: 3 }} key={index}>
            <Text
              style={[styles.tableData, { textAlign: 'left', width: '100px' }]}
            >
              Tranferencia
            </Text>
            <Text style={[styles.tableData, { textAlign: 'left', flex: 1 }]}>
              {convertDate2(item.fecha)}
            </Text>
            <Text
              style={[styles.tableData, { textAlign: 'right', width: '100px' }]}
            >
              {formatNumber(item.total)}
            </Text>
          </View>
        ))}
        {data.tarjetas.map((item, index) => (
          <View style={{ flexDirection: 'row', padding: 3 }} key={index}>
            <Text
              style={[styles.tableData, { textAlign: 'left', width: '100px' }]}
            >
              Tarjeta
            </Text>
            <Text style={[styles.tableData, { textAlign: 'left', flex: 1 }]}>
              {`${item.tipo_nombre} ${item.empresa_nombre}`}
            </Text>
            <Text
              style={[styles.tableData, { textAlign: 'right', width: '100px' }]}
            >
              {formatNumber(item.total)}
            </Text>
          </View>
        ))}
        {data.cheques.map((item, index) => (
          <View style={{ flexDirection: 'row', padding: 3 }} key={index}>
            <Text
              style={[styles.tableData, { textAlign: 'left', width: '100px' }]}
            >
              Cheque
            </Text>
            <Text style={[styles.tableData, { textAlign: 'left', flex: 1 }]}>
              {`${item.numero} | ${convertDate2(item.fecha_cobro)}${
                item.emisor ? ' | ' + item.emisor : ''
              }${item.portador ? ' | ' + item.portador : ''} | ${
                item.bancos?.nombre
              }`}
            </Text>
            <Text
              style={[styles.tableData, { textAlign: 'right', width: '100px' }]}
            >
              {formatNumber(item.importe)}
            </Text>
          </View>
        ))}
        {data.retenciones.map((item, index) => (
          <View style={{ flexDirection: 'row', padding: 3 }} key={index}>
            <Text
              style={[styles.tableData, { textAlign: 'left', width: '100px' }]}
            >
              Retención
            </Text>
            <Text style={[styles.tableData, { textAlign: 'left', flex: 1 }]}>
              {`${item.numero}  ${item.tipo_nombre} FA: ${item.factura_numero}`}
            </Text>
            <Text
              style={[styles.tableData, { textAlign: 'right', width: '100px' }]}
            >
              {formatNumber(item.total)}
            </Text>
          </View>
        ))}
      </>
    );
  } else {
    return null;
  }
};
export default CobroViewerMedios;
