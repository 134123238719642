import React, { useState, useEffect } from 'react';
import { Button, Col, DatePicker, Row, Select, Space, Statistic } from 'antd';

import { useHistory } from 'react-router-dom';
import MetaTable from './base/MetaTable';
import { cobroApi } from '../utils/api';
import CustomCellRender from './base/CustomCellRender';
import { connect } from 'react-redux';
import { getAll as rubros } from '../redux/actions/rubroActions';
import moment from 'moment';
import dayjs from 'dayjs';
import CobroComisionesViewer from './viewer/CobroComisionesViewer';
import { convertDate } from '../utils/funtions';

const CobroComisiones = ({ dispatch, rubro }) => {
  const history = useHistory();
  const today = new Date();
  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';
  const [showViewer, setShowViewer] = useState(false);
  const [rows, setRows] = useState([]);
  const [totales, setTotales] = useState({ saldo: 0, comision: 0, total: 0 });

  const [loading, setLoading] = useState(true);
  const [desde, setDesde] = useState(
    moment(
      moment(
        formatDate(
          new Date(
            today.getFullYear(),
            today.getDate() > 15 ? today.getMonth() : today.getMonth() - 1,
            16
          )
        )
      )
    )
  );
  const [hasta, setHasta] = useState(
    moment(
      moment(
        formatDate(
          new Date(
            today.getFullYear(),
            today.getDate() > 15 ? today.getMonth() + 1 : today.getMonth(),
            15
          )
        )
      )
    )
  );
  const [rubroSelected, setRubroSelected] = useState(1);

  useEffect(() => {
    rubros(dispatch);

    getComisiones();
    // eslint-disable-next-line
  }, []);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const getComisiones = () => {
    const desdeResult = dayjs(new Date(desde).toISOString()).format(format);
    const hastaResult = dayjs(new Date(hasta).toISOString()).format(format);

    setLoading(true);
    cobroApi
      .get(
        `/comisiones?desde=${desdeResult}&hasta=${hastaResult}&rubro=${rubroSelected}`
      )
      .then((response) => {
        let sumaSaldo = 0;
        const data = [
          ...response.data.map((item) => {
            sumaSaldo = sumaSaldo + item.total_comision;
            return {
              ...item,
              fecha: dayjs(
                new Date(moment(moment(convertDate(item.fecha)))).toISOString()
              ).format(formatDisplay),
            };
          }),
        ];

        setTotales({
          saldo: sumaSaldo.toFixed(2),
          comision: 3.5,
          total: ((3.5 * sumaSaldo) / 100).toFixed(2),
        });

        setRows(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleCobro = (data) => {
    history.push(`/cobros/cobro/${data.id}`);
  };

  const handlePrint = () => {
    setShowViewer(true);
  };

  const handleHideViewer = () => {
    setShowViewer(false);
  };

  const columns = [
    {
      width: '70%',
      ellipsis: true,
      title: 'Cliente',
      dataIndex: 'nombre',
      sorter: (a, b) => a.cliente.nombre.localeCompare(b.cliente.nombre),
      onFilter: (value, record) => {
        return record.cliente_id === value;
      },
      render: (text, record) => record.cliente.nombre,
    },
    {
      width: 150,
      title: 'Fecha',
      dataIndex: 'fecha',
    },
    {
      width: 150,
      ellipsis: true,
      title: 'Número',
      dataIndex: 'numero',
      sorter: (a, b) => a.numero.localeCompare(b.numero),
      search: true,
    },

    {
      width: 150,
      ellipsis: true,
      title: 'Saldo',
      dataIndex: 'total_comision',
      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'$'} />
      ),
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={(e) => handleCobro(record)}>Cobro</Button>
        </Space>
      ),
      width: 100,
    },
  ];

  const onChangeDesde = (value) => {
    setDesde(value);
    setRows([]);
  };
  const onChangeHasta = (value) => {
    setHasta(value);
    setRows([]);
  };
  const onChangeRubro = (value) => {
    setRubroSelected(value);
    setRows([]);
  };
  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Row gutter={[8, 0]}>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <DatePicker
            size="small"
            format={formatDisplay}
            style={{ width: '100%' }}
            value={desde}
            onChange={(e) => onChangeDesde(e)}
          />
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <DatePicker
            size="small"
            format={formatDisplay}
            style={{ width: '100%' }}
            value={hasta}
            onChange={(e) => onChangeHasta(e)}
          />
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
          <Select
            size="small"
            value={rubroSelected}
            style={{ width: '100%' }}
            onChange={(e) => onChangeRubro(e)}
          >
            {rubro.list.data.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.nombre}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={6} md={3} lg={3} xl={3}>
          <Button
            type="primary"
            size="small"
            onClick={() => getComisiones()}
            style={{ width: '100%' }}
          >
            Filtrar
          </Button>
        </Col>
        <Col xs={24} sm={6} md={3} lg={3} xl={3}>
          <Button
            type="primary"
            size="small"
            onClick={() => handlePrint()}
            style={{ width: '100%' }}
            disabled={rows.length === 0}
          >
            Imprimir
          </Button>
        </Col>
      </Row>

      <div style={{ flex: 1 }}>
        <MetaTable
          dataSource={rows}
          dataColumns={columns}
          bordered={false}
          selection={false}
          loading={loading}
        />
      </div>
      <Row justify="end">
        <Col>
          <Statistic
            title="Suma saldos"
            prefix="$"
            precision={2}
            value={totales.saldo}
            groupSeparator="."
            decimalSeparator=","
          />
        </Col>
        {rubroSelected === 2 && (
          <>
            <Col style={{ marginLeft: '20px' }}>
              <Statistic
                title="Porcentaje comisión"
                prefix="%"
                precision={2}
                value={totales.comision}
                groupSeparator="."
                decimalSeparator=","
              />
            </Col>
            <Col style={{ marginLeft: '20px' }}>
              <Statistic
                title="Total comisión"
                prefix="$"
                precision={2}
                valueStyle={{ color: '#3f8600' }}
                value={totales.total}
                groupSeparator="."
                decimalSeparator=","
              />
            </Col>
          </>
        )}
      </Row>

      {showViewer && (
        <CobroComisionesViewer
          data={rows}
          onCancel={handleHideViewer}
          visible={true}
          totales={totales}
          tipo={rubroSelected}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { rubro } = state;
  return {
    rubro,
  };
};
export default connect(mapStateToProps)(CobroComisiones);
