import {
  CalendarOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  ExclamationCircleOutlined,
  FileAddOutlined,
  FileTextOutlined,
  FormOutlined,
  MinusCircleOutlined,
  PercentageOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
  UnorderedListOutlined,
  UserOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Statistic,
  Tag,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  getAll as clientes,
  setPending as setPendingCliente,
} from '../redux/actions/clienteActions';
import { get, save, setPending } from '../redux/actions/comprobanteActions';
import { save as saveConfiguracion } from '../redux/actions/configuracionActions';
import {
  compareObject,
  convertDate2,
  hasPermiso,
  validateAsociados,
  validateItems,
  viewImportes,
  viewItems,
} from '../utils/funtions';

import { useHistory } from 'react-router-dom';
import { getAll as depositos } from '../redux/actions/depositoActions';
import { getAll as listas } from '../redux/actions/listaActions';
import flattenDeep from 'lodash/flattenDeep';
import styled from 'styled-components';
import { callStatus } from '../utils/constants';
import { convertDate } from '../utils/funtions';
import AsociadosPopup from './AsociadosPopup';
import ClienteEditor from './ClienteEditor';
import ProductoListaPrecioEditor from './ProductoListaPrecioEditor';
import ServicioEditor from './ServicioEditor';
import CuotaList from './Cuota/CuotaList';
import { getAll as rubros } from '../redux/actions/rubroActions';
import { getAll as ubicaciones } from '../redux/actions/ubicacionActions';
import {
  getBySearch as getProductosBySearch,
  setPending as setPendingProducto,
} from '../redux/actions/productoActions';
import { getBySearch as getServiciosBySearch } from '../redux/actions/servicioActions';
import { setPending as setPendingProductoListaPrecio } from '../redux/actions/productoListaPrecioActions';

import { debounce } from 'lodash';

const { confirm, info, error, success } = Modal;
const { Option } = Select;

const { Content, Header, Footer } = Layout;

const TitleCard = styled.div`
  display: flex;
  align-items: center;
`;

const ComprobanteEditor = ({
  dispatch,
  stateData,
  visible,
  onCancel,
  cliente,
  comprobanteTipo,
  puntoVenta,
  servicio,
  configuracion,
  alicuota,
  condicionVenta,
  comprobanteObservacion,
  formaPago,
  rubro,
  ubicacion,
  producto,
  productoTipo,
  match: {
    params: { id },
  },
}) => {
  const [form] = Form.useForm();
  const [formProducto] = Form.useForm();

  const [initial, setInitial] = useState({});

  const [showEditCliente, setShowEditCliente] = useState(false);
  const [showEditProducto, setShowEditProducto] = useState(false);
  const [showAddAsociados, setShowAddAsociados] = useState(false);

  const [devolucion, setDevolucion] = useState(true);

  const [puntoVentaId, setPuntoVentaId] = useState(null);
  const [tipoId, setTipoId] = useState(null);
  const [listaId, setListaId] = useState(null);
  const [productoListaId, setProductoListaId] = useState(null);

  const [depositoId, setDepositoId] = useState(null);
  const [parentId, setParentId] = useState(null);

  // const [countParentId, setCountParentId] = useState(-1);
  const [countNid, setCountNid] = useState(-1);

  const [clienteId, setClienteId] = useState(null);
  const [productoId, setProductoId] = useState(null);
  const [tipoItem, setTipoItem] = useState('producto');

  const [productoList, setProductoList] = useState([]);
  const [listaList, setListaList] = useState([]);
  const [depositoList, setDepositoList] = useState([]);

  const [agrupadores, setAgrupadores] = useState([]);

  const [cotizacionDolar, setCotizacionDolar] = useState(null);

  // const [itemsAsociados, setItemsAsociados] = useState([]);

  const [resultSearchCliente, setResultSearchCliente] = useState([]);
  const [clienteList, setClienteList] = useState([]);

  const [resultSearchObservaciones, setResultSearchObservaciones] = useState(
    []
  );
  const [observacionesList, setObservacionesList] = useState([]);

  const [errorItemList, setErrorItemList] = useState(null);

  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  const history = useHistory();
  const productoRef = useRef(null);

  const clienteRef = useRef(null);

  // const [exitEditor, setExitEditor] = useState(false);

  useEffect(() => {
    if (id && id !== '0') {
      dispatch(get(id));
    }

    listas(dispatch);
    depositos(dispatch);
    clientes(dispatch);
    rubros(dispatch);
    ubicaciones(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);
    if (stateData.object.status === callStatus.OBJECT_PENDING) {
      data['condicion_venta_id'] = 2;
      data['forma_pago_id'] = 1;
      data['total'] = 0;
      data['neto'] = 0;
      data['iva'] = 0;
      data['descuento'] = 0;
      data['mora'] = 0;
      data['fecha'] = moment(moment(data.fecha));
    } else if (
      stateData.object.status === callStatus.OBJECT_SUCCESS ||
      stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS
    ) {
      if (data.tipo_id > 0) {
        data['fecha'] = dayjs(
          new Date(moment(moment(convertDate(data.fecha)))).toISOString()
        ).format(formatDisplay);

        data['cliente_id'] = `${data.cliente.cuit} - ${data.cliente.nombre}`;
      } else {
        if (data.parent_asociado) {
          data['fecha'] = dayjs(
            new Date(moment(moment(convertDate(data.fecha)))).toISOString()
          ).format(formatDisplay);
          data['cliente_id'] = `${data.cliente.cuit} - ${data.cliente.nombre}`;
        } else {
          data['fecha'] = moment(moment(data.fecha));
        }
      }

      data['alicuotas'] = data.alicuotas.map((item) => {
        return {
          alicuota_base: item.base_imponible,
          alicuota_id: item.alicuota_id,
          alicuota_importe: item.importe,
          alicuota_nombre: item.alicuota,
        };
      });

      data['asociados'] = data.asociados.map((item) => {
        return {
          asociado_id: item.asociado_id,
          asociado_fecha: dayjs(
            new Date(moment(moment(convertDate(item.fecha)))).toISOString()
          ).format(formatDisplay),
          asociado_numero: item.numero,
          asociado_tipo: item.tipo,
          asociado_tipo_id: item.tipo_id,
          asociado_total: item.total,
          asociado_cotizacion: item.cotizacion_dolar,
        };
      });

      data['items'] = data.items.map((item) => {
        return {
          producto_nid: item.id,
          producto_alicuota: item.alicuota,
          producto_alicuota_id: item.alicuota_id,
          producto_nombre: item.descripcion,
          producto_cantidad: item.cantidad,
          producto_id: item.producto_id
            ? item.producto_id
            : item.servicio_id
            ? item.servicio_id
            : null,
          producto_iva: item.iva,
          producto_neto: item.neto,
          producto_descuento: item.descuento,
          producto_mora: item.mora,
          producto_precio_venta: item.precio_unitario,
          producto_precio: item.precio,
          producto_subtotal: item.subtotal,
          producto_tipo_id: item.tipo_id,
          producto_tipo: getTipoItemName(item.tipo_id),
          producto_lista: item.lista_id,
          producto_deposito: item.deposito_id,
          producto_parent: item.parent_id,
          producto_asociado: item.asociado_id,
          producto_moneda_id: item.moneda_id,
          producto_cotizacion: item.cotizacion_dolar,
          producto_precio_real: item.precio_real,
        };
      });
      data['cuotas'] = data.cuotas.map((item) => {
        return {
          ...item,
          key: item.id,
          fecha: dayjs(
            new Date(moment(moment(convertDate(item.fecha)))).toISOString()
          ).format(formatDisplay),
        };
      });

      setTipoId(stateData.object.data.tipo_id);

      if (data.items.length === 0) {
        setDevolucion(false);
        data['totalNoItem'] = data.total;
        data['alicuota_id'] = data.alicuotas[0].alicuota_id;
      }

      if (stateData.object.data.cliente) {
        setResultSearchCliente([stateData.object.data.cliente]);
        setClienteId(stateData.object.data.cliente_id);
      }

      if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
        handleSaveSuccess('El comprobante se guardó con éxito');
      }
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleSaveError(stateData.object.error);
    }
    form.setFieldsValue(data);
    setInitial(data);

    existeDetalleDolarDistinct();
    handleSetAgrupadores();
    handleReorderItems();
    // eslint-disable-next-line
  }, [stateData.object]);

  const handleReorderItems = () => {
    const items = form.getFieldValue('items');
    if (items) {
      let auxItems = [];
      let newItems = [];
      const agr = items.filter((item) => item.producto_tipo === 'agrupador');

      if (agr.length > 0) {
        auxItems = items.filter(
          (item) => item.producto_tipo === 'agrupador' || !item.producto_parent
        );

        auxItems.forEach((item) => {
          newItems.push(item);
          if (item.producto_tipo === 'agrupador') {
            const childs = items.filter(
              (value) => value.producto_parent === item.producto_nid
            );
            childs.forEach((child) => {
              newItems.push(child);
            });
          }
        });
      } else {
        newItems = items;
      }

      form.setFieldsValue({
        items: newItems,
      });
    }
  };

  const validateAgrupados = () => {
    const items = form.getFieldValue('items');
    let error = false;
    if (items) {
      items.forEach((item) => {
        if (item.producto_tipo === 'agrupador') {
          const childs = items.filter(
            (value) => value.producto_parent === item.producto_nid
          );

          if (childs.length === 0) {
            error = true;
            return;
          }
        }
      });
    }

    return error;
  };

  useEffect(() => {
    if (configuracion.list.status === callStatus.LIST_SUCCESS) {
      setCotizacionDolar(
        configuracion.list.data.find(
          (value) => value.nombre === 'cotizacion_dolar'
        ).valor
      );
    }
  }, [configuracion.list]);

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);

    let totalCuotas = 0;
    data.cuotas = data.cuotas.map((item) => {
      totalCuotas = totalCuotas + parseFloat(item.importe);
      return {
        ...item,
        fecha: convertDate(item.fecha),
      };
    });

    if (validateAsociados(data.tipo_id, devolucion, data.asociados)) {
      handleSaveError('Debe elegir un Comprobante Asociado');
    } else if (validateAgrupados()) {
      handleSaveError('Debe agregar al menos un item a los agrupadores');
    } else if (!data.forma_pago_id) {
      handleSaveError(
        'Debe ingresar una Forma de Pago, podrá cambiarla a futuro'
      );
    } else if (
      data.forma_pago_id === 2 &&
      (!data.cuotas || data.cuotas.length === 0)
    ) {
      handleSaveError(
        'Debe ingresar las Cuotas correspondientes para el Pago del Comprobante, podrá cambiarlas a futuro'
      );
    } else if (
      data.forma_pago_id === 2 &&
      totalCuotas.toFixed(2) !== data.total
    ) {
      handleSaveError(
        'La suma de las cuotas debe ser igual al total del comprobante'
      );
    } else {
      dispatch(save(data));
    }
  };

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          dispatch(setPending());
          history.push(`/comprobantes`);
        },
        onCancel() {},
      });
    } else {
      dispatch(setPending());
      history.push(`/comprobantes`);
    }
  };

  // useEffect(() => {
  //   const unblock = history.block((location, action) => {
  //     if (exitEditor) {
  //       return true;
  //     } else {
  //       if (compareObject(initial, form.getFieldsValue())) {
  //         confirm({
  //           title: 'Posee cambios sin guardar, desea continuar?',
  //           icon: <ExclamationCircleOutlined />,
  //           okText: 'Si',
  //           cancelText: 'No',
  //           autoFocusButton: 'cancel',
  //           onOk() {
  //             setExitEditor(true);
  //           },
  //           onCancel() {
  //             setExitEditor(false);
  //           },
  //         });

  //         return false;
  //       } else {
  //         return true;
  //       }
  //     }

  //     // return window.confirm('Navigate Back?');
  //   });

  //   return () => {
  //     unblock();
  //   };
  //   // eslint-disable-next-line
  // }, [initial]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      dispatch(setPending());
      return true;
    });

    return () => {
      unlisten();
    };
  });

  const onChangeTipo = (value) => {
    setTipoId(value);

    switch (value) {
      case -6:
      case -7:
      case 2:
      case 3:
      case 7:
      case 8:
      case 12:
      case 13:
        form.setFieldsValue({
          asociados: undefined,
          items: undefined,
          alicuotas: undefined,
          total: 0,
          neto: 0,
          iva: 0,
          descuento: 0,
          mora: 0,
          totalNoItem: undefined,
          alicuota_id: undefined,
          observacion: undefined,
        });
        break;

      default:
        form.setFieldsValue({
          totalNoItem: undefined,
          alicuota_id: undefined,
        });
        handleRemoveAllAsociados();
        break;
    }
  };

  const onChangePuntoVenta = (value) => {
    setPuntoVentaId(value);
    handleRemoveAllAsociados();
  };

  const onChangeFormaPago = (value) => {
    form.setFieldsValue({
      cuotas: undefined,
      forma_pago_id: value,
    });
  };

  // CLIENTE /////////////////////////////////////////////////////////////

  const initClienteValues = () => {
    setClienteId(null);
    setResultSearchCliente([]);
    form.setFieldsValue({
      cliente_id: null,
      cliente_nombre: null,
      cliente_condicion_iva: null,
      cliente_distancia: null,
      cliente_descuento: null,
      cliente_mora: null,
    });
  };

  useEffect(() => {
    setClienteList(cliente.list.data);
    // eslint-disable-next-line
  }, [cliente]);

  const handleOnSearchClienteEnter = (e) => {
    const value = e.target.value;
    e.preventDefault();
    e.stopPropagation();
    form.validateFields(['cliente_id']);

    if (value) {
      handleConfirm();
    }
  };

  const handleOnSearchClienteBlur = (e) => {
    const value = e.target.value;
    e.preventDefault();
    e.stopPropagation();
    form.validateFields(['cliente_id']);

    if (value) {
      handleConfirm();
    }
  };

  const handleHideEditorCliente = () => {
    setShowEditCliente(false);
    dispatch(setPendingCliente());
  };

  useEffect(() => {
    if (clienteId && clienteList) {
      if (!stateData.object.data.id) {
        const result = clienteList.find((item) => item.id === clienteId);
        if (result) {
          onChangeCliente(result.id);
        }
      }
    }
    // eslint-disable-next-line
  }, [clienteId, clienteList]);

  const onSearchCliente = (value) => {
    if (value) {
      let result = [];
      result = clienteList.filter(
        (item) =>
          item.nombre.toLowerCase().includes(value.toLowerCase()) ||
          item.cuit.toLowerCase().includes(value.toLowerCase())
      );
      setResultSearchCliente(result);
    } else {
      setResultSearchCliente([]);
    }
  };

  const onChangeCliente = (value) => {
    const result = clienteList.find((item) => item.id === value);

    if (result) {
      setResultSearchCliente([{ ...result }]);

      form.setFieldsValue({
        cliente_nombre: result.nombre,
        cliente_condicion_iva: result.condicion_iva_nombre,
        cliente_distancia: result.distancia,
        cliente_descuento: result.descuento,
        cliente_mora: result.recargo_producto,
      });
      setClienteId(result.id);
    } else {
      initClienteValues();
    }

    handleRemoveAllAsociados();
  };

  const handleInfo = (message) => {
    info({
      title: message,
      onOk() {},
    });
  };

  const handleSaveError = (message) => {
    error({
      title: message,
      onOk() {},
    });
  };

  const handleSaveSuccess = (message) => {
    success({
      title: message,
      onOk() {
        history.push(`/comprobantes`);
      },
    });
  };

  const handleConfirm = () => {
    confirm({
      title: 'El cliente no existe, desea cargarlo?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        setShowEditCliente(true);
      },
      onCancel() {},
    });
  };

  // ASOCIADO /////////////////////////////////////////////////////////////

  const handleAsociadosCancel = () => {
    setShowAddAsociados(false);
  };

  const handleAsociadosOk = (data) => {
    let asociados =
      // form.getFieldValue('asociados') &&
      // tipoId !== -6 &&
      // tipoId !== 3 &&
      // tipoId !== 8
      form.getFieldValue('asociados') ? form.getFieldValue('asociados') : [];

    let items =
      // form.getFieldValue('items') &&
      // tipoId !== -6 &&
      // tipoId !== 3 &&
      // tipoId !== 8
      form.getFieldValue('items') ? form.getFieldValue('items') : [];

    asociados = [
      ...asociados,
      ...data.map((item) => {
        if (!asociados.some((value) => value.asociado_id === item.id)) {
          return {
            asociado_id: item.id,
            asociado_fecha: dayjs(
              new Date(moment(moment(convertDate(item.fecha)))).toISOString()
            ).format(formatDisplay),
            asociado_numero: item.numero,
            asociado_total: item.total,
            asociado_tipo: item.tipo_nombre,
            asociado_tipo_id: item.tipo_id,
            asociado_cotizacion: item.cotizacion_dolar,
          };
        } else {
          return null;
        }
      }),
    ];

    if (devolucion && tipoId !== -7 && tipoId !== 2 && tipoId !== 7) {
      data.forEach((comp) => {
        items = [
          ...items,
          comp.items.map((item) => {
            return {
              producto_new: true,
              producto_nid: item.id,
              producto_alicuota: item.alicuota,
              producto_alicuota_id: item.alicuota_id,
              producto_nombre: item.descripcion,
              producto_cantidad: item.cantidad,
              producto_id: item.producto_id
                ? item.producto_id
                : item.servicio_id
                ? item.servicio_id
                : null,
              producto_iva: item.iva,
              producto_neto: item.neto,
              producto_descuento: item.descuento,
              producto_mora: item.mora,
              producto_precio_venta: item.precio_unitario,
              producto_precio: item.precio,
              producto_subtotal: item.subtotal,
              producto_tipo_id: item.tipo_id,
              producto_tipo: getTipoItemName(item.tipo_id),
              producto_asociado: comp.id,
              producto_lista: item.lista_id,
              producto_deposito: item.deposito_id,
              producto_parent: item.parent_id,
              producto_moneda_id: item.moneda_id,
              producto_precio_real: item.precio_real,
              producto_cotizacion: item.cotizacion_dolar,
            };
          }),
        ];
      });

      items = flattenDeep(items);

      form.setFieldsValue({
        asociados: asociados.filter((item) => item !== null),
        items: items,
      });
    } else {
      form.setFieldsValue({
        asociados: asociados.filter((item) => item !== null),
        items: undefined,
      });
    }

    setShowAddAsociados(false);
    calcTotales();
    handleSetAgrupadores();
  };

  const handleAsociadosSearch = () => {
    if (clienteId && tipoId) {
      setShowAddAsociados(true);
    } else {
      handleInfo(
        'Debe seleccionar Tipo de Comprobante y Cliente para asociar comprobantes'
      );
    }
  };

  const handleRemoveAllAsociados = () => {
    if (form.getFieldValue('items') && form.getFieldValue('asociados')) {
      const itemsAsociados = form
        .getFieldValue('items')
        .filter((item) => !item.producto_asociado);

      form.setFieldsValue({
        items: itemsAsociados,
        asociados: undefined,
      });
    }

    calcTotales();
  };

  const handleRemoveAsociados = (value) => {
    const items = form.getFieldValue('items');

    if (items) {
      const itemsAsociados = items.filter(
        (item) => item.producto_asociado !== value.asociado_id
      );

      form.setFieldsValue({
        items: itemsAsociados,
      });
    }

    calcTotales();
  };

  // PRODUCTOS ////////////////////////////////////////////////////////////////

  const initProductoValues = (tipo = undefined) => {
    setProductoId(null);
    setListaList([]);
    setListaId(null);
    setProductoListaId(null);
    setDepositoList([]);
    setDepositoId(null);
    if (!tipo) {
      tipo = tipoItem;
    }

    if (tipo === 'agrupador') {
      formProducto.setFieldsValue({
        producto_id: null,
        producto_nombre: null,
        producto_cantidad: '1',
        producto_precio_venta: '0',
        producto_precio: '0',
        producto_alicuota: '0',
        producto_alicuota_id: 3,
        producto_neto: '0',
        producto_iva: '0',
        producto_subtotal: '0',
        producto_moneda_id: null,
        producto_viaje: null,
        producto_descuento: '0',
        producto_mora: '0',
        precio_updated: null,
        tipo_producto: null,
        producto_stock: null,
        producto_stock_minimo: null,
      });
    } else {
      formProducto.setFieldsValue({
        lista_id: null,
        deposito_id: null,
        producto_id: null,
        producto_nombre: null,
        producto_precio_venta: null,
        producto_precio: null,
        producto_alicuota: null,
        producto_alicuota_id: null,
        producto_iva: null,
        producto_cantidad: null,
        producto_neto: null,
        producto_subtotal: null,
        producto_moneda_id: null,
        producto_descuento: null,
        producto_mora: null,
        producto_viaje: null,
        precio_updated: null,
        tipo_producto: null,
        producto_stock: null,
        producto_stock_minimo: null,
      });
    }
  };

  const handleHideEditorProducto = () => {
    setShowEditProducto(false);
    dispatch(setPendingProductoListaPrecio());
  };

  useEffect(() => {
    setProductoList(producto.list.data);
    // eslint-disable-next-line
  }, [producto]);

  useEffect(() => {
    setProductoList(servicio.list.data);
    // eslint-disable-next-line
  }, [servicio]);

  useEffect(() => {
    if (productoId) {
      const result = productoList.find((item) => item.id === productoId);
      if (result) {
        onChangeProducto(result.id);
      }
    }
    // eslint-disable-next-line
  }, [productoList]);

  const handleOnChangeLista = () => {
    // initProductoValues();
    const id = formProducto.getFieldValue('lista_id');
    // setListaId(id);

    onChangeProducto(productoId, id, null);
  };

  const handleOnChangeDeposito = () => {
    // initProductoValues();
    const id = formProducto.getFieldValue('deposito_id');
    // setDepositoId(id);

    onChangeProducto(productoId, null, id);
  };

  const handleOnChangeParent = () => {
    const id = formProducto.getFieldValue('parent_id');
    setParentId(id);
  };

  const handleOnSearchDebouncedProducto = useCallback(
    debounce((value) => {
      if (value && value.length > 3) {
        dispatch(getProductosBySearch(value));
      }
    }, 500),
    []
  );

  const handleOnSearchDebouncedServicio = useCallback(
    debounce((value) => {
      dispatch(getServiciosBySearch(value));
    }, 500),
    []
  );
  const handleOnSearchProducto = (value) => {
    setProductoList([]);
    handleOnSearchDebouncedProducto(value);
  };

  const handleOnSearchServicio = (value) => {
    setProductoList([]);
    handleOnSearchDebouncedServicio(value);
  };

  const onChangeProducto = (value, comboLista = null, comboDeposito = null) => {
    const result = productoList.find((item) => item.id === value);

    if (result) {
      if (
        tipoItem === 'producto' &&
        (!result.listasprecios ||
          result.listasprecios.length === 0 ||
          !result.depositos ||
          result.depositos.length === 0)
      ) {
        if (value !== undefined) {
          handleInfo(
            'El producto seleccionado debe estar en una Lista de precios y un Depósito'
          );
        }
        initProductoValues();
        // dispatch(setPendingProducto());
        setProductoList([]);
      } else {
        let productolistaprecio = [];
        let productodeposito = [];

        if (tipoItem === 'producto') {
          productolistaprecio = comboLista
            ? result.listasprecios.find((item) => item.id === comboLista)
            : result.listasprecios[0];
          productodeposito = comboDeposito
            ? result.depositos.find((item) => item.id === comboDeposito)
            : result.depositos[0];
        }

        const precio_real =
          tipoItem === 'producto' ? productolistaprecio.precio : result.precio;

        const alicuota =
          tipoItem === 'producto'
            ? productolistaprecio.alicuota.nombre
            : result.alicuota.nombre;

        let cantidad = !formProducto.getFieldValue('producto_cantidad')
          ? 1
          : formProducto.getFieldValue('producto_cantidad');

        let viaje = !formProducto.getFieldValue('producto_viaje')
          ? 1
          : formProducto.getFieldValue('producto_viaje');

        if (isMovilidad()) {
          cantidad =
            parseFloat(form.getFieldValue('cliente_distancia')) *
            parseFloat(viaje);
        }

        const descuento = formProducto.getFieldValue('producto_descuento')
          ? formProducto.getFieldValue('producto_descuento')
          : form.getFieldValue('cliente_descuento')
          ? form.getFieldValue('cliente_descuento')
          : 0;

        const mora = formProducto.getFieldValue('producto_mora')
          ? formProducto.getFieldValue('producto_mora')
          : form.getFieldValue('cliente_mora')
          ? form.getFieldValue('cliente_mora')
          : 0;

        const cotizacion =
          result.moneda_id && result.moneda_id === 1 ? 1 : cotizacionDolar;

        const precio_venta = precio_real * cotizacion;

        const precio = (
          parseFloat(precio_venta) -
          (parseFloat(descuento) * parseFloat(precio_venta)) / 100 +
          (parseFloat(mora) * parseFloat(precio_venta)) / 100
        ).toFixed(2);

        const iva = (
          (parseFloat(precio) * parseFloat(cantidad) * parseFloat(alicuota)) /
          100
        ).toFixed(2);
        const neto = (parseFloat(precio) * parseFloat(cantidad)).toFixed(2);
        const subtotal = (parseFloat(iva) + parseFloat(neto)).toFixed(2);

        formProducto.setFieldsValue({
          producto_nombre: result.nombre,
          producto_precio_real: precio_real,
          producto_precio_venta: precio_venta.toFixed(2),
          producto_precio: precio,
          producto_alicuota_id:
            tipoItem === 'producto'
              ? productolistaprecio.alicuota_id
              : result.alicuota_id,
          producto_alicuota:
            tipoItem === 'producto'
              ? productolistaprecio.alicuota.nombre
              : result.alicuota.nombre,

          producto_cantidad: cantidad,
          producto_iva: iva,
          producto_neto: neto,
          producto_subtotal: subtotal,
          producto_moneda_id: result.moneda_id,
          producto_cotizacion: cotizacion,

          producto_descuento: descuento,
          producto_mora: mora,
          producto_viaje: viaje,
          precio_updated:
            tipoItem === 'producto'
              ? convertDate2(productolistaprecio.updated_at)
              : null,
          tipo_producto:
            tipoItem === 'producto' && result.tipo ? result.tipo.nombre : null,
          producto_stock:
            tipoItem === 'producto' ? productodeposito.cantidad : null,
          producto_stock_minimo:
            tipoItem === 'producto' ? productodeposito.cantidad_minima : null,
        });

        setProductoId(result.id);

        setDepositoId(
          tipoItem === 'producto' ? productodeposito.deposito.id : null
        );
        setListaId(
          tipoItem === 'producto' ? productolistaprecio.lista.id : null
        );
        setProductoListaId(
          tipoItem === 'producto' ? productolistaprecio.id : null
        );

        setDepositoList(tipoItem === 'producto' ? result.depositos : []);
        setListaList(tipoItem === 'producto' ? result.listasprecios : []);

        formProducto.setFieldsValue({
          deposito_id: tipoItem === 'producto' ? productodeposito.id : null,
          lista_id: tipoItem === 'producto' ? productolistaprecio.id : null,
        });
        // setProductoList([]);
      }
    } else {
      initProductoValues();
      setProductoList([]);
      // dispatch(setPendingProducto());
    }
  };

  const handleChangeRadio = (e) => {
    const value = e.target.value;
    setTipoItem(value);

    initProductoValues(value);

    if (value === 'producto') {
      if (listaList.length === 1) {
        setListaId(listaList[0].id);
        formProducto.setFieldsValue({ lista_id: listaList[0].id });
      }

      if (depositoList.length === 1) {
        setDepositoId(depositoList[0].id);
        formProducto.setFieldsValue({ deposito_id: depositoList[0].id });
      }
    } else {
      formProducto.setFieldsValue({ lista_id: null });
      setListaId(null);
      formProducto.setFieldsValue({ deposito_id: null });
      setDepositoId(null);
      if (value === 'agrupador') {
        formProducto.setFieldsValue({ parent_id: null });
        setParentId(null);
      }
    }
  };

  const handleShowEditProducto = () => {
    // if (listaId) {
    //   setShowEditProducto(true);
    // } else {
    //   handleInfo(
    //     'Debe seleccionar una Lista de Precio par agregar un Producto a la misma'
    //   );
    // }
    setShowEditProducto(true);
  };

  // CALCULO DE TOTALES //////////////////////////////////////////////////////////////////////

  const calcTotales = () => {
    const items = form.getFieldValue('items');

    const totalNoItem = form.getFieldValue('totalNoItem');
    const alicuotaNoItem = form.getFieldValue('alicuota_id');

    let neto = 0;
    let iva = 0;
    let total = 0;
    let alicuotas = [];

    if (items && items.length > 0) {
      const detalle = items.filter((value) => !value.producto_parent);

      detalle.forEach((item) => {
        neto = neto + parseFloat(item.producto_neto);
        iva = iva + parseFloat(item.producto_iva);
        total = total + parseFloat(item.producto_subtotal);

        if (
          !alicuotas.some(
            (alic) => alic.alicuota_id === item.producto_alicuota_id
          )
        ) {
          alicuotas.push({
            alicuota_id: item.producto_alicuota_id,
            alicuota_nombre: item.producto_alicuota,
            alicuota_importe: item.producto_iva,
            alicuota_base: item.producto_neto,
          });
        } else {
          alicuotas = alicuotas.map((alic) => {
            if (alic.alicuota_id === item.producto_alicuota_id) {
              return {
                ...alic,
                alicuota_importe: (
                  parseFloat(alic.alicuota_importe) +
                  parseFloat(item.producto_iva)
                ).toFixed(2),
                alicuota_base: (
                  parseFloat(alic.alicuota_base) +
                  parseFloat(item.producto_neto)
                ).toFixed(2),
              };
            } else {
              return alic;
            }
          });
        }
      });
    } else {
      if (totalNoItem && alicuotaNoItem) {
        const alicuotaValor = alicuota.list.data.find(
          (item) => item.id === alicuotaNoItem
        ).nombre;

        total = parseFloat(totalNoItem);
        neto = total / (1 + parseFloat(alicuotaValor) / 100);
        iva = total - neto;

        alicuotas.push({
          alicuota_id: alicuotaNoItem,
          alicuota_nombre: alicuotaValor,
          alicuota_importe: iva.toFixed(2),
          alicuota_base: neto.toFixed(2),
        });
      }
    }

    form.setFieldsValue({
      producto_id: null,
      neto: neto.toFixed(2),
      iva: iva.toFixed(2),
      total: total.toFixed(2),
      alicuotas: alicuotas,
      cotizacion_dolar: cotizacionDolar,
    });

    existeDetalleDolarDistinct();
  };

  const calcCantidad = () => {
    const cantidad = form.getFieldValue('cliente_distancia');
    const viaje = formProducto.getFieldValue('producto_viaje');

    formProducto.setFieldsValue({
      producto_cantidad: parseFloat(cantidad) * parseFloat(viaje),
    });

    calcSubtotal();
  };

  const getDetalleProductoCantidad = (id) => {
    let updateItems = form.getFieldValue('items');
    let cantidad = 0;
    if (updateItems) {
      const productos = [
        ...updateItems.filter(
          (item) => item.producto_id === id && item.producto_tipo === 'producto'
        ),
      ];
      productos.forEach((item) => {
        cantidad = cantidad + parseFloat(item.producto_cantidad);
      });
    }
    return cantidad;
  };

  const calcSubtotal = () => {
    const cantidad = formProducto.getFieldValue('producto_cantidad');
    const precio_venta = formProducto.getFieldValue('producto_precio_venta');
    const alicuota = formProducto.getFieldValue('producto_alicuota');
    const descuento = !formProducto.getFieldValue('producto_descuento')
      ? 0
      : formProducto.getFieldValue('producto_descuento');

    const mora = !formProducto.getFieldValue('producto_mora')
      ? 0
      : formProducto.getFieldValue('producto_mora');

    const precio = (
      parseFloat(precio_venta) -
      (parseFloat(descuento) * parseFloat(precio_venta)) / 100 +
      (parseFloat(mora) * parseFloat(precio_venta)) / 100
    ).toFixed(2);

    const iva = (
      (parseFloat(precio) * parseFloat(cantidad) * parseFloat(alicuota)) /
      100
    ).toFixed(2);
    const neto = (parseFloat(precio) * parseFloat(cantidad)).toFixed(2);
    const subtotal = (parseFloat(iva) + parseFloat(neto)).toFixed(2);

    formProducto.setFieldsValue({
      producto_descuento: descuento,
      producto_mora: mora,
      producto_precio: precio,
      producto_iva: iva,
      producto_neto: neto,
      producto_subtotal: subtotal,
    });
  };

  const handleChangeAlicuotaDetalle = (index) => {
    let updateItems = form.getFieldValue('items');

    const result = alicuota.list.data.find(
      (item) => item.id === updateItems[index].producto_alicuota_id
    );
    updateItems[index].producto_alicuota = result.nombre;

    calcSubtotalDetalle(index);
  };

  const calcSubtotalDetalle = (index) => {
    let updateItems = form.getFieldValue('items');

    const cantidad = updateItems[index].producto_cantidad;
    const precio_venta = updateItems[index].producto_precio_venta;
    const descuento = !updateItems[index].producto_descuento
      ? 0
      : updateItems[index].producto_descuento;

    const mora = !updateItems[index].producto_mora
      ? 0
      : updateItems[index].producto_mora;

    const alicuota = updateItems[index].producto_alicuota;

    const precio = (
      parseFloat(precio_venta) -
      (parseFloat(descuento) * parseFloat(precio_venta)) / 100 +
      (parseFloat(mora) * parseFloat(precio_venta)) / 100
    ).toFixed(2);

    const iva = (
      (parseFloat(precio) * parseFloat(cantidad) * parseFloat(alicuota)) /
      100
    ).toFixed(2);
    const neto = (parseFloat(precio) * parseFloat(cantidad)).toFixed(2);
    const subtotal = (parseFloat(iva) + parseFloat(neto)).toFixed(2);

    if (!cantidad) {
      updateItems[index].producto_descuento = descuento;
      updateItems[index].producto_mora = mora;
      updateItems[index].producto_precio = precio;
      updateItems[index].producto_iva = (0).toFixed(2);
      updateItems[index].producto_neto = (0).toFixed(2);
      updateItems[index].producto_subtotal = (0).toFixed(2);
    } else {
      updateItems[index].producto_descuento = descuento;
      updateItems[index].producto_mora = mora;
      updateItems[index].producto_precio = precio;
      updateItems[index].producto_iva = iva;
      updateItems[index].producto_neto = neto;
      updateItems[index].producto_subtotal = subtotal;
    }

    form.setFieldsValue({ items: updateItems });

    calcTotales();

    if (updateItems[index].producto_parent) {
      handleUpdateParent(updateItems[index].producto_parent);
    }
  };

  const updateItemsDetalleCotizacion = (incluyePresupuestos) => {
    const detalle = form.getFieldValue('items');
    if (detalle) {
      detalle.forEach((item, index) => {
        if (item.producto_moneda_id === 2) {
          if (incluyePresupuestos) {
            item.producto_cotizacion = cotizacionDolar;
            item.producto_precio_venta = (
              parseFloat(item.producto_precio_real) *
              parseFloat(cotizacionDolar)
            ).toFixed(2);
          } else {
            let tipo = 0;
            if (form.getFieldValue('asociados') && item.producto_asociado) {
              tipo = form
                .getFieldValue('asociados')
                .find(
                  (asoc) => asoc.asociado_id === item.producto_asociado
                ).asociado_tipo_id;
            }

            if (tipo !== -2) {
              item.producto_cotizacion = cotizacionDolar;
              item.producto_precio_venta = (
                parseFloat(item.producto_precio_real) *
                parseFloat(cotizacionDolar)
              ).toFixed(2);
            }
          }

          form.setFieldsValue({ items: detalle });
        }
      });

      detalle.forEach((item, index) => {
        calcSubtotalDetalle(index);
      });
    }
  };

  const handleAddItems = (item) => {
    const items = form.getFieldValue('items');

    if (items) {
      if (item.producto_parent) {
        const arrKeys = [...items.map((el) => el.producto_parent)];
        const pos = arrKeys.lastIndexOf(item.producto_parent);

        if (pos === -1) {
          const parentIndex = items.findIndex(
            (value) => value.producto_nid === item.producto_parent
          );
          items.splice(parentIndex + 1, 0, {
            ...item,
            producto_nid: countNid,
            producto_new: true,
          });
        } else {
          items.splice(pos + 1, 0, {
            ...item,
            producto_nid: countNid,
            producto_new: true,
          });
        }

        form.setFieldsValue({
          items: [...items],
        });
      } else {
        form.setFieldsValue({
          items: [
            ...items,
            {
              ...item,
              producto_nid: countNid,
              producto_new: true,
            },
          ],
        });
      }
    } else {
      form.setFieldsValue({
        items: [
          {
            ...item,
            producto_nid: countNid,
            producto_new: true,
          },
        ],
      });
    }

    setCountNid((prev) => prev - 1);

    calcTotales();
    initProductoValues();
    setErrorItemList(null);
    setProductoList([]);
    if (item.producto_parent) {
      handleUpdateParent(item.producto_parent);
    }
    handleSetAgrupadores();
  };

  const handleSetAgrupadores = () => {
    const items = form.getFieldValue('items');

    if (items) {
      const newAgrupadores = items.filter(
        (value) => value.producto_tipo === 'agrupador'
      );
      setAgrupadores(newAgrupadores);
      if (parentId) {
        const search = newAgrupadores.findIndex(
          (value) => value.producto_nid === parentId
        );

        if (search < 0) {
          formProducto.setFieldsValue({ parent_id: null });
          setParentId(null);
        }
      }
    } else {
      setAgrupadores([]);
      formProducto.setFieldsValue({ parent_id: null });
      setParentId(null);
    }
  };

  const handleUpdateParent = (parent_nid) => {
    const updateItems = form.getFieldValue('items');
    const parentIndex = updateItems.findIndex(
      (value) => value.producto_nid === parent_nid
    );

    const childs = updateItems.filter(
      (value) => value.producto_parent === parent_nid
    );

    // const descuento = !updateItems[parentIndex].producto_descuento
    //   ? 0
    //   : updateItems[parentIndex].producto_descuento;

    // const mora = !updateItems[parentIndex].producto_mora
    //   ? 0
    //   : updateItems[parentIndex].producto_mora;

    // let precio_venta = 0;
    // let precio = 0;
    let neto = 0;
    let iva = 0;
    let subtotal = 0;
    let alicuota_id = childs.length > 0 ? childs[0].producto_alicuota_id : 3;
    let alicuota = childs.length > 0 ? childs[0].producto_alicuota : 0;

    childs.forEach((item) => {
      // const precio_venta_child = parseFloat(item.producto_precio_venta);

      // const precio_child = (
      //   parseFloat(precio_venta_child) -
      //   (parseFloat(descuento) * parseFloat(precio_venta_child)) / 100 +
      //   (parseFloat(mora) * parseFloat(precio_venta_child)) / 100
      // ).toFixed(2);

      neto = neto + parseFloat(item.producto_neto);
      iva = iva + parseFloat(item.producto_iva);
      subtotal = subtotal + parseFloat(item.producto_subtotal);

      // precio_venta = precio_venta + parseFloat(item.producto_precio_venta);
      // precio = precio + parseFloat(item.producto_precio);
    });

    // updateItems[parentIndex].producto_precio_real = precio_venta.toFixed(2);
    // updateItems[parentIndex].producto_precio_venta = precio_venta.toFixed(2);
    // updateItems[parentIndex].producto_precio = precio.toFixed(2);
    updateItems[parentIndex].producto_alicuota_id = alicuota_id;
    updateItems[parentIndex].producto_alicuota = alicuota;

    updateItems[parentIndex].producto_precio_real = neto.toFixed(2);
    updateItems[parentIndex].producto_precio_venta = neto.toFixed(2);
    updateItems[parentIndex].producto_precio = neto.toFixed(2);

    // updateItems[parentIndex].producto_iva = iva.toFixed(2);
    // updateItems[parentIndex].producto_neto = neto.toFixed(2);
    // updateItems[parentIndex].producto_subtotal = subtotal.toFixed(2);

    form.setFieldsValue({ items: updateItems });

    calcSubtotalDetalle(parentIndex);
    // calcTotales();
  };

  const handleUpdateItems = (item = undefined) => {
    if (item) {
      const items = form.getFieldValue('items');

      if (item.producto_parent) {
        handleUpdateParent(item.producto_parent);
      }

      if (item.producto_tipo === 'agrupador') {
        const newItems = items.filter(
          (value) => value.producto_parent !== item.producto_nid
        );

        form.setFieldsValue({
          items: newItems,
        });

        handleSetAgrupadores();
      }
    }

    calcTotales();
    initProductoValues();
    setErrorItemList(null);
  };

  // COTIZACION //////////////////////////////////////////////////////////

  const handleSetCotizacionDolar = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setCotizacionDolar(value);
  };

  const handleSaveCotizacionDolar = (update) => {
    if (configuracion.list.status === callStatus.LIST_SUCCESS) {
      const value = cotizacionDolar;
      const dolarConf = configuracion.list.data.find(
        (value) => value.nombre === 'cotizacion_dolar'
      ).valor;

      if (value && parseFloat(value) !== parseFloat(dolarConf)) {
        const cotizacion = configuracion.list.data.find(
          (value) => value.nombre === 'cotizacion_dolar'
        );
        const data = {
          id: cotizacion.id,
          nombre: cotizacion.nombre,
          valor: cotizacionDolar,
        };
        dispatch(saveConfiguracion(data));
      }
    }
  };

  const [distinctCotizacion, setDistinctCotizacion] = useState(false);

  const existeDetalleDolarDistinct = () => {
    const detalle = form.getFieldValue('items');
    let itemsDolar = [];

    if (detalle) {
      itemsDolar = detalle.filter((item) => item.producto_moneda_id === 2);
    }

    let distinct = false;
    itemsDolar.forEach((item, index) => {
      if (
        parseFloat(item.producto_cotizacion) !== parseFloat(cotizacionDolar)
      ) {
        distinct = true;
      }
    });

    setDistinctCotizacion(distinct);

    handleSaveCotizacionDolar(false);
  };

  // END COTIZACION //////////////////////////////////////////////////////////

  // IMPORTES ////////////////////////////////////
  const handleChangeEfectivo = (e) => {
    calcTotales();
  };

  const handleSetDevolucion = (e) => {
    setDevolucion(e.target.checked);

    form.setFieldsValue({
      asociados: undefined,
      items: undefined,
      alicuotas: undefined,
      total: 0,
      neto: 0,
      iva: 0,
      descuento: 0,
      mora: 0,
      totalNoItem: undefined,
      alicuota_id: undefined,
      observacion: undefined,
    });
  };

  const handleChangeAlicuota = (value) => {
    calcTotales();
  };

  // END IMPORTES ////////////////////////////////

  const handleChangeProductoAlicuota = (value) => {
    const result = alicuota.list.data.find((item) => item.id === value);

    formProducto.setFieldsValue({
      producto_alicuota: result.nombre,
    });
    calcSubtotal();
  };

  const getTipoItem = (tipo) => {
    switch (tipo) {
      case 'producto':
        return 1;
      case 'servicio':
        return 2;
      case 'especial':
        return 3;
      case 'agrupador':
        return 4;
      default:
        return null;
    }
  };

  const getTipoItemName = (tipo) => {
    switch (tipo) {
      case 1:
        return 'producto';
      case 2:
        return 'servicio';
      case 3:
        return 'especial';
      case 4:
        return 'agrupador';
      default:
        return null;
    }
  };

  const isMovilidad = () => {
    if (tipoItem === 'servicio' && form.getFieldValue('cliente_distancia')) {
      const servicio = productoList.find(
        (item) => item.id === formProducto.getFieldValue('producto_id')
      );
      return servicio && servicio.codigo === '0';
    } else {
      return false;
    }
  };

  // useEffect(() => {
  //   if (clienteId && clienteList) {
  //     if (!stateData.object.data.id) {
  //       const result = clienteList.find((item) => item.id === clienteId);
  //       if (result) {
  //         onChangeObservaciones(result.id);
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [clienteId, clienteList]);

  useEffect(() => {
    setObservacionesList(comprobanteObservacion.list.data);
    // eslint-disable-next-line
  }, [comprobanteObservacion]);

  const onSearchObservaciones = (value) => {
    if (value) {
      let result = [];
      result = observacionesList.filter((item) =>
        item.nombre.toLowerCase().includes(value.toLowerCase())
      );
      setResultSearchObservaciones(result);
    } else {
      setResultSearchObservaciones([]);
    }
  };

  const onChangeObservaciones = (value) => {
    const result = observacionesList.find((item) => item.id === value);

    if (result) {
      const textoExistente = form.getFieldValue('observacion');
      form.setFieldsValue({
        observacion: textoExistente
          ? textoExistente + '\n' + result.nombre
          : result.nombre,
        comprobante_observacion: null,
      });
    }
  };

  const handleOnSearchObservacionesEnter = (e) => {
    // const value = e.target.value;
    // e.preventDefault();
    // e.stopPropagation();
    // form.validateFields(['cliente_id']);
    // if (value) {
    //   handleConfirm();
    // }
  };

  const handleOnSearchObservacionesBlur = (e) => {
    // const value = e.target.value;
    // e.preventDefault();
    // e.stopPropagation();
    // form.validateFields(['cliente_id']);
    // if (value) {
    //   handleConfirm();
    // }
  };

  const handleSetCuotas = (data) => {
    form.setFieldsValue({
      cuotas: data,
    });
  };

  return (
    <Spin spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}>
      <Layout style={{ height: '100%' }}>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={stateData.object.data}
          size="small"
        >
          <Header>
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="id" label="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="cuotas"
                  label="cuotas"
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="forma_pago_id"
                  label="forma_pago_id"
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Card
              size="small"
              style={{ marginBottom: '10px' }}
              extra={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {stateData.object.data.numero && (
                    <span style={{ fontSize: '18px' }}>
                      <b> Comprobante N°: {stateData.object.data.numero}</b>
                    </span>
                  )}
                  {stateData.object.data.id &&
                    stateData.object.data.tipo_id > 0 &&
                    stateData.object.data.estado_afip_id === 2 && (
                      <span style={{ marginLeft: '10px', fontSize: '18px' }}>
                        CAE: {stateData.object.data.cae}
                      </span>
                    )}

                  {stateData.object.data.id &&
                    stateData.object.data.tipo_id > 0 &&
                    stateData.object.data.estado_afip_id !== 2 && (
                      <span style={{ marginLeft: '10px', fontSize: '18px' }}>
                        CAE: ERROR
                      </span>
                    )}
                  {stateData.object.data.parent_asociado && (
                    <Tag
                      color="#108ee9"
                      style={{ marginLeft: '10px', fontSize: '15px' }}
                    >
                      Asociado
                    </Tag>
                  )}
                </div>
              }
              title={
                <TitleCard>
                  <Avatar
                    icon={<CalendarOutlined />}
                    size="small"
                    style={{ backgroundColor: '#4f43ba' }}
                  />
                  <span style={{ marginLeft: '3px' }}> Datos Generales</span>
                </TitleCard>
              }
            >
              <Row gutter={[8, 0]} align="top">
                <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="fecha"
                    label="Fecha emisión"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    {!stateData.object.data.id ||
                    (stateData.object.data.tipo_id < 0 &&
                      !stateData.object.data.parent_asociado) ? (
                      <DatePicker
                        format={formatDisplay}
                        style={{ width: '100%' }}
                        initialValue={moment(new Date())}
                      />
                    ) : (
                      <Input readOnly />
                    )}
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="punto_venta_id"
                    label="Punto de Venta"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select
                      open={
                        stateData.object.data.tipo_id > 0 ||
                        (stateData.object.data.tipo_id < 0 &&
                          stateData.object.data.parent_asociado)
                          ? false
                          : undefined
                      }
                      onChange={onChangePuntoVenta}
                    >
                      {hasPermiso(321)
                        ? puntoVenta.list.data.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {`${item.nombre} - ${item.descripcion}`}
                            </Select.Option>
                          ))
                        : puntoVenta.list.data
                            .filter((item) => item.nombre === '0')
                            .map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {`${item.nombre} - ${item.descripcion}`}
                              </Select.Option>
                            ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="tipo_id"
                    label="Tipo"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                    extra={
                      (tipoId === -6 ||
                        tipoId === 3 ||
                        tipoId === 8 ||
                        tipoId === 13) && (
                        <>
                          <Checkbox
                            disabled={stateData.object.data.id}
                            checked={devolucion}
                            onChange={(e) => handleSetDevolucion(e)}
                            style={{ marginTop: '3px' }}
                          />{' '}
                          Devolución
                        </>
                      )
                    }
                  >
                    <Select
                      onChange={onChangeTipo}
                      open={stateData.object.data.id ? false : undefined}
                      // readOnly={!stateData.object.data.id}
                    >
                      {hasPermiso(321)
                        ? comprobanteTipo.list.data
                            .filter((item) => item.id !== -1)
                            .map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.letra
                                  ? item.nombre + ' ' + item.letra
                                  : item.nombre}
                              </Select.Option>
                            ))
                        : comprobanteTipo.list.data
                            .filter((item) => item.id !== -1 && item.id < 0)
                            .map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.letra
                                  ? item.nombre + ' ' + item.letra
                                  : item.nombre}
                              </Select.Option>
                            ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="rubro_id"
                    label="Rubro"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select>
                      {rubro.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="ubicacion_id"
                    label="Ubicación"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select>
                      {ubicacion.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="condicion_venta_id"
                    label="Condición de Venta"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select
                      open={
                        stateData.object.data.tipo_id > 0 ? false : undefined
                      }
                    >
                      {condicionVenta.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card
              extra={
                !stateData.object.data.id ||
                (stateData.object.data.tipo_id < 0 &&
                  !stateData.object.data.parent_asociado) ? (
                  clienteId ? (
                    <FormOutlined
                      style={{ fontSize: '18px' }}
                      onClick={() => setShowEditCliente(true)}
                    />
                  ) : (
                    <PlusCircleOutlined
                      style={{ fontSize: '18px' }}
                      onClick={() => setShowEditCliente(true)}
                    />
                  )
                ) : (
                  false
                )
              }
              title={
                <TitleCard>
                  <Avatar
                    icon={<UserOutlined />}
                    size="small"
                    style={{ backgroundColor: '#4f43ba' }}
                  />
                  <span style={{ marginLeft: '3px' }}> Cliente</span>
                </TitleCard>
              }
              style={{ marginBottom: '10px' }}
              size="small"
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={14} lg={14} xl={8}>
                  <Form.Item
                    name="cliente_id"
                    label="Cliente"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    {!stateData.object.data.id ||
                    (stateData.object.data.tipo_id < 0 &&
                      !stateData.object.data.parent_asociado) ? (
                      cliente.list.status === callStatus.LIST_SUCCESS ? (
                        <Select
                          showSearch
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={false}
                          onSearch={onSearchCliente}
                          onChange={onChangeCliente}
                          onPressEnter={(e) => handleOnSearchClienteEnter(e)}
                          onBlur={(e) => handleOnSearchClienteBlur(e)}
                          notFoundContent={null}
                          allowClear
                          ref={clienteRef}
                        >
                          {resultSearchCliente.map((item, index) => (
                            <Option key={index} value={item.id}>
                              {item.cuit} - {item.nombre}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Spin spinning={true} size="small">
                          <Input readOnly />
                        </Spin>
                      )
                    ) : (
                      <Input readOnly />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={14} lg={14} xl={8}>
                  <Form.Item
                    name="cliente_nombre"
                    label="Nombre"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                  <Form.Item
                    name="cliente_condicion_iva"
                    label="Condición IVA"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                  <Form.Item name="cliente_distancia" label="Distancia">
                    <Input
                      type="number"
                      style={{ textAlign: 'right' }}
                      prefix={'Km'}
                      readOnly
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                  <Form.Item name="cliente_descuento" label="Descuento">
                    <Input
                      type="number"
                      style={{ textAlign: 'right' }}
                      readOnly
                      prefix={'%'}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={3}>
                  <Form.Item name="cliente_mora" label="Mora">
                    <Input
                      type="number"
                      style={{ textAlign: 'right' }}
                      readOnly
                      prefix={'%'}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card
              extra={
                !stateData.object.data.id ||
                (stateData.object.data.tipo_id < 0 &&
                  !stateData.object.data.parent_asociado) ? (
                  <PlusCircleOutlined
                    style={{ fontSize: '18px' }}
                    onClick={() => handleAsociadosSearch()}
                  />
                ) : (
                  false
                )
              }
              title={
                <TitleCard>
                  <Avatar
                    icon={<FileAddOutlined />}
                    size="small"
                    style={{ backgroundColor: '#4f43ba' }}
                  />
                  <span style={{ marginLeft: '3px' }}>
                    Comprobantes Asociados
                  </span>
                </TitleCard>
              }
              style={{ marginBottom: '10px' }}
              size="small"
            >
              <Row>
                <Form.List name="asociados">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      <Row
                        gutter={[8, 0]}
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          fontWeight: '500',
                          display:
                            (!form.getFieldValue('asociados') ||
                              form.getFieldValue('asociados').length === 0) &&
                            'none',
                        }}
                        align="top"
                        // justify="center"
                      >
                        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                          Tipo
                        </Col>
                        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                          Fecha
                        </Col>

                        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                          Número
                        </Col>
                        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                          Total
                        </Col>
                        {/* <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col> */}
                        <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
                      </Row>
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, index) => (
                          <Row
                            gutter={[8, 0]}
                            style={{ width: '100%' }}
                            key={index}
                            align="top"
                            // justify="center"
                          >
                            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                              <Form.Item
                                {...restField}
                                name={[name, 'asociado_tipo']}
                                fieldKey={[fieldKey, 'asociado_tipo']}
                                style={{ marginBottom: '5px' }}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                              <Form.Item
                                {...restField}
                                name={[name, 'asociado_fecha']}
                                fieldKey={[fieldKey, 'asociado_fecha']}
                                style={{ marginBottom: '5px' }}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                              <Form.Item
                                {...restField}
                                name={[name, 'asociado_numero']}
                                fieldKey={[fieldKey, 'asociado_numero']}
                                style={{ marginBottom: '5px' }}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                              <Form.Item
                                {...restField}
                                name={[name, 'asociado_total']}
                                fieldKey={[fieldKey, 'asociado_total']}
                                style={{ marginBottom: '5px' }}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                              {(!stateData.object.data.id ||
                                (stateData.object.data.tipo_id < 0 &&
                                  !stateData.object.data.parent_asociado)) && (
                                <MinusCircleOutlined
                                  style={{ color: 'red' }}
                                  onClick={() => {
                                    handleRemoveAsociados(
                                      form.getFieldValue('asociados')[index]
                                    );
                                    remove(name);
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        )
                      )}
                    </>
                  )}
                </Form.List>
              </Row>
            </Card>
          </Header>
          <Content>
            {viewImportes(tipoId, devolucion) && (
              <Card
                title={
                  <TitleCard>
                    <Avatar
                      icon={<FileAddOutlined />}
                      size="small"
                      style={{ backgroundColor: '#4f43ba' }}
                    />
                    <span style={{ marginLeft: '3px' }}>Importe</span>
                  </TitleCard>
                }
                style={{ marginBottom: '10px' }}
                size="small"
              >
                <Row gutter={[8, 0]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row gutter={[8, 0]}>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Form.Item
                          label="Total"
                          name="totalNoItem"
                          rules={[
                            {
                              required: true,
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Input
                            prefix={'$'}
                            min={0}
                            type="number"
                            onBlur={(e) => handleChangeEfectivo(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Form.Item
                          name="alicuota_id"
                          label="Alicuota Iva"
                          rules={[
                            {
                              required: true,
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Select onChange={handleChangeAlicuota}>
                            {alicuota.list.data.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.nombre}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                        <Form.Item
                          name="observacion"
                          label="Descripción"
                          rules={[
                            {
                              required: true,
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            )}

            {viewItems(tipoId, devolucion) && (
              <Card
                extra={
                  !stateData.object.data.id ||
                  (stateData.object.data.tipo_id < 0 &&
                    !stateData.object.data.parent_asociado) ? (
                    productoId ? (
                      <FormOutlined
                        style={{ fontSize: '18px' }}
                        onClick={() => setShowEditProducto(true)}
                      />
                    ) : (
                      <PlusCircleOutlined
                        style={{ fontSize: '18px' }}
                        onClick={() => handleShowEditProducto()}
                      />
                    )
                  ) : (
                    false
                  )
                }
                title={
                  <TitleCard>
                    <Avatar
                      icon={<UnorderedListOutlined />}
                      size="small"
                      style={{ backgroundColor: '#4f43ba' }}
                    />
                    <span style={{ marginLeft: '3px' }}> Items</span>
                  </TitleCard>
                }
                style={{ marginBottom: '10px' }}
                size="small"
              >
                {(!stateData.object.data.id ||
                  (stateData.object.data.tipo_id < 0 &&
                    !stateData.object.data.parent_asociado)) && (
                  <Form
                    layout="vertical"
                    form={formProducto}
                    style={{ width: '100%' }}
                  >
                    <Row
                      gutter={[8, 0]}
                      style={{ marginBottom: '12px' }}
                      // justify="space-between"
                    >
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Radio.Group
                          size="medium"
                          value={tipoItem}
                          buttonStyle="solid"
                          onChange={(e) => handleChangeRadio(e)}
                        >
                          <Radio.Button value="producto">Producto</Radio.Button>
                          <Radio.Button value="servicio">Servicio</Radio.Button>
                          <Radio.Button value="especial">Especial</Radio.Button>
                          <Radio.Button value="agrupador">
                            Agrupador
                          </Radio.Button>
                        </Radio.Group>
                      </Col>

                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                width: '100px',
                                textAlign: 'right',
                                marginRight: '3px',
                                fontWeight: 'bold',
                                alignItems: 'center',
                              }}
                            >
                              Dolar hoy
                            </div>

                            <Input
                              prefix={'$'}
                              style={{ width: '100px', marginRight: '8px' }}
                              min={0}
                              type="number"
                              value={cotizacionDolar}
                              onChange={(e) => handleSetCotizacionDolar(e)}
                              // onBlur={(e) => handleSaveCotizacionDolar(e)}
                              onBlur={(e) => existeDetalleDolarDistinct()}
                            />

                            <Tooltip title="Actualizar items sin Presupuestos">
                              <Button
                                type="primary"
                                shape="circle"
                                icon={<ReloadOutlined />}
                                style={{
                                  marginRight: '8px',
                                  background: 'green',
                                  borderColor: 'green',
                                }}
                                onClick={(e) =>
                                  updateItemsDetalleCotizacion(false)
                                }
                              />
                            </Tooltip>

                            <Tooltip title="Actualizar items con Presupuestos">
                              <Button
                                type="primary"
                                shape="circle"
                                icon={<ReloadOutlined />}
                                // style={{ marginRight: '8px' }}
                                onClick={(e) =>
                                  updateItemsDetalleCotizacion(true)
                                }
                                style={{
                                  background: 'red',
                                  borderColor: 'red',
                                }}
                              />
                            </Tooltip>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              width: '100%',
                            }}
                          >
                            {distinctCotizacion && (
                              <span style={{ color: 'red' }}>
                                Existen cotizaciones diferentes, actualizar
                                items
                              </span>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {agrupadores.length > 0 && (
                      <Row gutter={[8, 0]}>
                        <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                          <Form.Item name="parent_id" label="Parent">
                            <Select
                              allowClear
                              onChange={handleOnChangeParent}
                              disabled={tipoItem === 'agrupador' ? true : false}
                            >
                              {agrupadores.map((item) => (
                                <Select.Option
                                  key={item.producto_nid}
                                  value={item.producto_nid}
                                >
                                  {item.producto_nombre}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}

                    <Row gutter={[8, 0]}>
                      {tipoItem !== 'especial' && tipoItem !== 'agrupador' ? (
                        <>
                          <Col xs={24} sm={24} md={18} lg={12} xl={12}>
                            <Form.Item
                              name="producto_id"
                              label="Item"
                              rules={[
                                {
                                  required: true,
                                  message: 'Requerido',
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={
                                  tipoItem === 'producto'
                                    ? handleOnSearchProducto
                                    : handleOnSearchServicio
                                }
                                onChange={(e) => onChangeProducto(e)}
                                notFoundContent={null}
                                allowClear
                                ref={productoRef}
                              >
                                {productoList.map((item, index) => (
                                  <Option key={index} value={item.id}>
                                    {item.codigo
                                      ? `${item.codigo} - ${item.nombre}`
                                      : item.nombre}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          {formProducto.getFieldValue('producto_stock') && (
                            <>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item name="producto_stock" label="Stock">
                                  <Input readOnly={true} />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  name="producto_stock_minimo"
                                  label="Mínimo"
                                >
                                  <Input readOnly={true} />
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </>
                      ) : (
                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                          <Form.Item
                            name="producto_nombre"
                            label="Item"
                            rules={[
                              {
                                required: true,
                                max: 200,
                                message: 'Requerido (máx 200 caracteres)',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Row gutter={[8, 0]}>
                      <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <Form.Item
                          name="lista_id"
                          label="Lista"
                          rules={[
                            {
                              required: tipoItem === 'producto' ? true : false,
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Select
                            open={listaList.length === 1 ? false : undefined}
                            onChange={handleOnChangeLista}
                            disabled={
                              tipoItem !== 'producto' ||
                              !formProducto.getFieldValue('producto_id')
                            }
                          >
                            {listaList.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.lista.nombre}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <Form.Item
                          name="deposito_id"
                          label="Depósito"
                          rules={[
                            {
                              required: tipoItem === 'producto' ? true : false,
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Select
                            open={depositoList.length === 1 ? false : undefined}
                            onChange={handleOnChangeDeposito}
                            disabled={
                              tipoItem !== 'producto' ||
                              !formProducto.getFieldValue('producto_id')
                            }
                          >
                            {depositoList.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {`${item.deposito.nombre} (${item.deposito.ubicacion.nombre})`}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                        <Form.Item
                          name="precio_updated"
                          label="Ult Actualización de Precio"
                        >
                          <Input
                            readOnly={true}
                            disabled={
                              tipoItem === 'agrupador' ||
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial')
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <Form.Item name="tipo_producto" label="Tipo">
                          <Input
                            readOnly={true}
                            disabled={
                              tipoItem === 'agrupador' ||
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial')
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[8, 0]}>
                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item
                          name="producto_precio_venta"
                          label="Precio U"
                          extra={
                            formProducto.getFieldValue('producto_moneda_id') ===
                              2 && (
                              <span style={{ color: 'green' }}>
                                U$S{' '}
                                {formProducto.getFieldValue(
                                  'producto_precio_real'
                                )}
                              </span>
                            )
                          }
                          rules={[
                            {
                              required: tipoItem === 'especial',
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Input
                            prefix={'$'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            onBlur={
                              tipoItem === 'especial' ? calcSubtotal : () => {}
                            }
                            readOnly={tipoItem !== 'especial'}
                            disabled={
                              tipoItem === 'agrupador' ||
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial')
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item name="producto_descuento" label="Desc.">
                          <Input
                            prefix={'%'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            onBlur={calcSubtotal}
                            disabled={
                              tipoItem === 'agrupador' ||
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial') ||
                              tipoId === -3
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item name="producto_mora" label="Mora">
                          <Input
                            prefix={'%'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            onBlur={calcSubtotal}
                            disabled={
                              tipoItem === 'agrupador' ||
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial') ||
                              tipoId === -3
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item name="producto_precio" label="Precio">
                          <Input
                            prefix={'$'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            readOnly
                            disabled={
                              tipoItem === 'agrupador' ||
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial')
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item
                          name="producto_alicuota_id"
                          label="Alic."
                          rules={[
                            {
                              required: true,
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Select
                            onChange={handleChangeProductoAlicuota}
                            disabled={
                              tipoItem === 'agrupador' ||
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial')
                            }
                          >
                            {alicuota.list.data.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.nombre}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      {isMovilidad() && (
                        <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                          <Form.Item
                            name="producto_viaje"
                            style={{ textAlign: 'right' }}
                            label="Viajes"
                            rules={[
                              {
                                required:
                                  tipoItem === 'agrupador' ? false : true,
                                message: 'Requerido',
                              },
                            ]}
                          >
                            <Input
                              min={0}
                              type="number"
                              style={{ textAlign: 'right' }}
                              onBlur={calcCantidad}
                              disabled={
                                tipoItem === 'agrupador' ||
                                (!formProducto.getFieldValue('producto_id') &&
                                  tipoItem !== 'especial')
                              }
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col
                        xs={24}
                        sm={24}
                        md={isMovilidad() ? 1 : 2}
                        lg={isMovilidad() ? 1 : 2}
                        xl={isMovilidad() ? 1 : 2}
                      >
                        <Form.Item
                          name="producto_cantidad"
                          style={{ textAlign: 'right' }}
                          label="Cantidad"
                          rules={[
                            {
                              required: tipoItem === 'agrupador' ? false : true,
                              message: 'Requerido',
                            },
                          ]}
                          extra={
                            isMovilidad() && (
                              <span style={{ color: 'green' }}>
                                {form.getFieldValue('cliente_distancia')} KM
                              </span>
                            )
                          }
                        >
                          <Input
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            onBlur={calcSubtotal}
                            disabled={
                              tipoItem === 'agrupador' ||
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial')
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item name="producto_iva" label="IVA">
                          <Input
                            prefix={'$'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            readOnly
                            disabled={
                              tipoItem === 'agrupador' ||
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial')
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item name="producto_neto" label="Neto">
                          <Input
                            prefix={'$'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            readOnly
                            disabled={
                              tipoItem === 'agrupador' ||
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial')
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item name="producto_subtotal" label="Subtotal">
                          <Input
                            prefix={'$'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            readOnly
                            disabled={
                              tipoItem === 'agrupador' ||
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial')
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                )}

                <Row gutter={[8, 0]} align="middle">
                  <Form.List
                    name="items"
                    rules={[
                      {
                        validator: async (_, items) => {
                          if (validateItems(tipoId, devolucion, items)) {
                            return Promise.reject(
                              new Error('Ingrese al menos un item')
                            );
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {(!stateData.object.data.id ||
                          (stateData.object.data.tipo_id < 0 &&
                            !stateData.object.data.parent_asociado)) && (
                          <Row
                            gutter={[8, 0]}
                            style={{ width: '100%' }}
                            justify="center"
                            align="top"
                          >
                            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => {
                                    formProducto.validateFields([
                                      'lista_id',
                                      'deposito_id',
                                      'producto_id',
                                      'producto_nombre',
                                      'producto_cantidad',
                                      'producto_precio_venta',
                                      'producto_alicuota',
                                      'producto_alicuota_id',
                                    ]);

                                    if (
                                      formProducto.getFieldValue(
                                        'producto_cantidad'
                                      ) &&
                                      formProducto.getFieldValue(
                                        'producto_precio_venta'
                                      ) &&
                                      formProducto.getFieldValue(
                                        'producto_alicuota'
                                      ) &&
                                      formProducto.getFieldValue(
                                        'producto_alicuota_id'
                                      )
                                    ) {
                                      const item = {
                                        producto_nid: null,
                                        producto_tipo: tipoItem,
                                        producto_tipo_id: getTipoItem(tipoItem),
                                        producto_asociado: null,
                                        producto_cotizacion:
                                          tipoItem === 'especial' ||
                                          tipoItem === 'agrupador'
                                            ? 1
                                            : formProducto.getFieldValue(
                                                'producto_cotizacion'
                                              ),
                                        producto_precio_real:
                                          tipoItem === 'especial' ||
                                          tipoItem === 'agrupador'
                                            ? parseFloat(
                                                formProducto.getFieldValue(
                                                  'producto_precio_venta'
                                                )
                                              )
                                            : formProducto.getFieldValue(
                                                'producto_precio_real'
                                              ),
                                        producto_moneda_id:
                                          tipoItem === 'especial' ||
                                          tipoItem === 'agrupador'
                                            ? 1
                                            : formProducto.getFieldValue(
                                                'producto_moneda_id'
                                              ),
                                        producto_lista: listaId,
                                        producto_deposito: depositoId,
                                        producto_parent: parentId
                                          ? parentId
                                          : null,
                                        producto_id:
                                          formProducto.getFieldValue(
                                            'producto_id'
                                          ),
                                        producto_nombre:
                                          formProducto.getFieldValue(
                                            'producto_nombre'
                                          ),
                                        producto_precio_venta: parseFloat(
                                          formProducto.getFieldValue(
                                            'producto_precio_venta'
                                          )
                                        ),
                                        producto_descuento:
                                          formProducto.getFieldValue(
                                            'producto_descuento'
                                          )
                                            ? formProducto.getFieldValue(
                                                'producto_descuento'
                                              )
                                            : 0,
                                        producto_mora:
                                          formProducto.getFieldValue(
                                            'producto_mora'
                                          )
                                            ? formProducto.getFieldValue(
                                                'producto_mora'
                                              )
                                            : 0,
                                        producto_precio:
                                          formProducto.getFieldValue(
                                            'producto_precio'
                                          ),
                                        producto_alicuota_id:
                                          formProducto.getFieldValue(
                                            'producto_alicuota_id'
                                          ),
                                        producto_alicuota:
                                          formProducto.getFieldValue(
                                            'producto_alicuota'
                                          ),

                                        producto_cantidad:
                                          formProducto.getFieldValue(
                                            'producto_cantidad'
                                          ),
                                        producto_iva:
                                          formProducto.getFieldValue(
                                            'producto_iva'
                                          ),
                                        producto_neto:
                                          formProducto.getFieldValue(
                                            'producto_neto'
                                          ),
                                        producto_subtotal:
                                          formProducto.getFieldValue(
                                            'producto_subtotal'
                                          ),
                                      };
                                      let error = false;
                                      if (
                                        form.getFieldValue('items') &&
                                        form
                                          .getFieldValue('items')
                                          .some((value) => {
                                            return (
                                              !value.producto_asociado &&
                                              value.producto_id ===
                                                item.producto_id &&
                                              value.producto_tipo ===
                                                item.producto_tipo &&
                                              value.producto_lista ===
                                                item.producto_lista &&
                                              value.producto_parent ===
                                                item.producto_parent &&
                                              tipoItem !== 'especial' &&
                                              tipoItem !== 'agrupador'
                                            );
                                          })
                                      ) {
                                        setErrorItemList(
                                          'El item seleccionado ya existe'
                                        );
                                        error = true;
                                      }

                                      if (!error && item.producto_parent) {
                                        const items =
                                          form.getFieldValue('items');

                                        const parent = items.find(
                                          (value) =>
                                            value.producto_nid ===
                                            item.producto_parent
                                        );

                                        if (
                                          parseFloat(parent.producto_subtotal) >
                                            0 &&
                                          parent.producto_alicuota_id !==
                                            item.producto_alicuota_id
                                        ) {
                                          setErrorItemList(
                                            'El item seleccionado debe tener la misma alicuota que los demás items del agrupador'
                                          );
                                          error = true;
                                        }
                                      }

                                      if (
                                        !error &&
                                        formProducto.getFieldValue(
                                          'producto_stock'
                                        )
                                      ) {
                                        const stock =
                                          formProducto.getFieldValue(
                                            'producto_stock'
                                          );
                                        const cantidad = item.producto_cantidad;
                                        const productoCantidad =
                                          parseFloat(cantidad) +
                                          getDetalleProductoCantidad(
                                            item.producto_id
                                          );
                                        if (
                                          productoCantidad > parseFloat(stock)
                                        ) {
                                          setErrorItemList(
                                            'La cantidad en stock del producto no es suficiente'
                                          );
                                          error = true;
                                        }
                                      }

                                      if (!error) {
                                        // add(item, 0);

                                        handleAddItems(item);
                                        handleUpdateItems();
                                      }
                                    }
                                  }}
                                  block
                                  icon={<PlusOutlined />}
                                  danger={
                                    errors.length || errorItemList
                                      ? true
                                      : false
                                  }
                                >
                                  Agregar item
                                </Button>
                                <span style={{ color: 'red' }}>
                                  {errorItemList}
                                </span>

                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        <Row
                          gutter={[8, 0]}
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            fontWeight: '500',
                            display:
                              (!form.getFieldValue('items') ||
                                form.getFieldValue('items').length === 0) &&
                              'none',
                          }}
                          align="top"
                          justify="center"
                        >
                          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                            Item
                          </Col>
                          {/* <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                            nid
                          </Col>
                          <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                            id
                          </Col>
                          <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                            p
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Item
                          </Col> */}
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Precio U
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Desc.
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Mora
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={3} xl={2}>
                            Precio
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Alic.
                          </Col>

                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Cantidad
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            IVA
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Neto
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Subtotal
                          </Col>
                          <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
                        </Row>
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, index) => (
                            <Row
                              gutter={[8, 0]}
                              style={{ width: '100%' }}
                              key={index}
                              align="top"
                              justify="center"
                            >
                              {/* <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_nid']}
                                  fieldKey={[fieldKey, 'producto_nid']}
                                >
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_id']}
                                  fieldKey={[fieldKey, 'producto_id']}
                                >
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_parent']}
                                  fieldKey={[fieldKey, 'producto_parent']}
                                >
                                  <Input readOnly />
                                </Form.Item>
                              </Col> */}
                              {form.getFieldValue('items')[index]
                                .producto_parent && (
                                <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                              )}

                              <Col
                                xs={24}
                                sm={24}
                                md={
                                  form.getFieldValue('items')[index]
                                    .producto_parent
                                    ? 4
                                    : 5
                                }
                                lg={
                                  form.getFieldValue('items')[index]
                                    .producto_parent
                                    ? 4
                                    : 5
                                }
                                xl={
                                  form.getFieldValue('items')[index]
                                    .producto_parent
                                    ? 4
                                    : 5
                                }
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_nombre']}
                                  fieldKey={[fieldKey, 'producto_nombre']}
                                  extra={
                                    form.getFieldValue('items')[index]
                                      .producto_moneda_id === 2 && (
                                      <span style={{ color: 'green' }}>
                                        U$S{' '}
                                        {
                                          form.getFieldValue('items')[index]
                                            .producto_precio_real
                                        }{' '}
                                        x{' '}
                                        {
                                          form.getFieldValue('items')[index]
                                            .producto_cotizacion
                                        }
                                      </span>
                                    )
                                  }
                                >
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_precio_venta']}
                                  fieldKey={[fieldKey, 'producto_precio_venta']}
                                  rules={[
                                    { required: true, message: 'Requerido' },
                                  ]}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    readOnly
                                    prefix={'$'}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_descuento']}
                                  fieldKey={[fieldKey, 'producto_descuento']}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    onBlur={() => calcSubtotalDetalle(index)}
                                    readOnly={
                                      form.getFieldValue('items')[index]
                                        .producto_parent ||
                                      stateData.object.data.tipo_id > 0 ||
                                      (stateData.object.data.tipo_id < 0 &&
                                        stateData.object.data.parent_asociado)
                                    }
                                    prefix={'%'}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_mora']}
                                  fieldKey={[fieldKey, 'producto_mora']}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    onBlur={() => calcSubtotalDetalle(index)}
                                    readOnly={
                                      form.getFieldValue('items')[index]
                                        .producto_parent ||
                                      stateData.object.data.tipo_id > 0 ||
                                      (stateData.object.data.tipo_id < 0 &&
                                        stateData.object.data.parent_asociado)
                                    }
                                    prefix={'%'}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_precio']}
                                  fieldKey={[fieldKey, 'producto_precio']}
                                  rules={[
                                    { required: true, message: 'Requerido' },
                                  ]}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    readOnly
                                    prefix={'$'}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                {/* <Form.Item
                                  {...restField}
                                  name={[name, 'producto_alicuota']}
                                  fieldKey={[fieldKey, 'producto_alicuota']}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    readOnly
                                    prefix={'%'}
                                  />
                                </Form.Item> */}
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_alicuota_id']}
                                  fieldKey={[fieldKey, 'producto_alicuota_id']}
                                >
                                  <Select
                                    // onChange={() => calcSubtotalDetalle(index)}
                                    onChange={() =>
                                      handleChangeAlicuotaDetalle(index)
                                    }
                                    open={
                                      stateData.object.data.tipo_id > 0 ||
                                      (stateData.object.data.tipo_id < 0 &&
                                        stateData.object.data.parent_asociado)
                                        ? false
                                        : undefined
                                    }

                                    // open={stateData.object.data.id ? false : undefined}
                                  >
                                    {alicuota.list.data.map((item) => (
                                      <Select.Option
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.nombre}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_cantidad']}
                                  fieldKey={[fieldKey, 'producto_cantidad']}
                                  rules={[
                                    { required: true, message: 'Requerido' },
                                  ]}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    onBlur={() => calcSubtotalDetalle(index)}
                                    readOnly={
                                      stateData.object.data.tipo_id > 0 ||
                                      (stateData.object.data.tipo_id < 0 &&
                                        stateData.object.data.parent_asociado)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_iva']}
                                  fieldKey={[fieldKey, 'producto_iva']}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    readOnly
                                    prefix={'$'}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_neto']}
                                  fieldKey={[fieldKey, 'producto_neto']}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    readOnly
                                    prefix={'$'}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_subtotal']}
                                  fieldKey={[fieldKey, 'producto_subtotal']}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    readOnly
                                    prefix={'$'}
                                  />
                                </Form.Item>
                              </Col>
                              {(!stateData.object.data.id ||
                                (stateData.object.data.tipo_id < 0 &&
                                  !stateData.object.data.parent_asociado)) && (
                                <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                                  <MinusCircleOutlined
                                    onClick={() => {
                                      const itemDeleted =
                                        form.getFieldValue('items')[index];
                                      remove(name);
                                      handleUpdateItems(itemDeleted);
                                    }}
                                    style={{ color: 'red' }}
                                  />
                                  {form.getFieldValue('items')[index]
                                    .producto_asociado && (
                                    <FileAddOutlined
                                      style={{
                                        marginLeft: '10px',
                                        color: 'blue',
                                      }}
                                    />
                                  )}
                                </Col>
                              )}
                            </Row>
                          )
                        )}
                      </>
                    )}
                  </Form.List>
                </Row>
              </Card>
            )}
          </Content>
          <Footer style={{ paddingRight: 0 }}>
            <Row gutter={[8, 0]} style={{ paddingBottom: '8px' }}>
              {!viewImportes(tipoId, devolucion) && (
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Card
                    title={
                      <TitleCard>
                        <Avatar
                          icon={<FileTextOutlined />}
                          size="small"
                          style={{ backgroundColor: '#4f43ba' }}
                        />
                        <span style={{ marginLeft: '3px' }}>Observaciones</span>
                      </TitleCard>
                    }
                    style={{ height: '100%' }}
                    size="small"
                    bodyStyle={{ paddingBottom: 0 }}
                  >
                    <Row gutter={[8, 0]}>
                      <Col xs={24} sm={24} md={12} lg={24} xl={24}>
                        <Form.Item
                          name="comprobante_observacion"
                          // label="Plantillas"
                        >
                          <Select
                            placeholder="Seleccione una plantilla"
                            showSearch
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={onSearchObservaciones}
                            onChange={onChangeObservaciones}
                            onPressEnter={(e) =>
                              handleOnSearchObservacionesEnter(e)
                            }
                            onBlur={(e) => handleOnSearchObservacionesBlur(e)}
                            notFoundContent={null}
                            allowClear
                            // ref={observacionesRef}
                          >
                            {resultSearchObservaciones.map((item, index) => (
                              <Option key={index} value={item.id}>
                                {item.nombre}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[8, 0]}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item name="observacion">
                          <Input.TextArea rows={3} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              )}
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card
                  title={
                    <TitleCard>
                      <Avatar
                        icon={<PercentageOutlined />}
                        size="small"
                        style={{ backgroundColor: '#4f43ba' }}
                      />
                      <span style={{ marginLeft: '3px' }}> Alicuotas</span>
                    </TitleCard>
                  }
                  style={{ height: '100%' }}
                  size="small"
                >
                  <Form.List name="alicuotas">
                    {(fields) => (
                      <>
                        <Row
                          gutter={[8, 0]}
                          style={{
                            width: '100%',
                            // textAlign: 'center',
                            // fontWeight: '500',
                            display:
                              (!form.getFieldValue('alicuotas') ||
                                form.getFieldValue('alicuotas').length === 0) &&
                              'none',
                          }}
                          align="top"
                        >
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            Alic.
                          </Col>

                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            Importe
                          </Col>
                          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            Base Imp
                          </Col>
                        </Row>
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, index) => (
                            <Row
                              gutter={[8, 0]}
                              style={{ width: '100%' }}
                              key={index}
                              align="top"
                              // justify="center"
                            >
                              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'alicuota_nombre']}
                                  fieldKey={[fieldKey, 'alicuota_nombre']}
                                  style={{ marginBottom: '5px' }}
                                >
                                  <Input
                                    prefix="%"
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    readOnly
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'alicuota_importe']}
                                  fieldKey={[fieldKey, 'alicuota_importe']}
                                  style={{ marginBottom: '5px' }}
                                >
                                  <Input
                                    prefix="$"
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    readOnly
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'alicuota_base']}
                                  fieldKey={[fieldKey, 'alicuota_base']}
                                  style={{ marginBottom: '5px' }}
                                >
                                  <Input
                                    prefix="$"
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    readOnly
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          )
                        )}
                      </>
                    )}
                  </Form.List>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card
                  title={
                    <TitleCard>
                      <Avatar
                        icon={<DollarOutlined />}
                        size="small"
                        style={{ backgroundColor: '#4f43ba' }}
                      />
                      <span style={{ marginLeft: '3px' }}> Totales</span>
                    </TitleCard>
                  }
                  style={{ height: '100%' }}
                  size="small"
                >
                  <Row gutter={[20, 0]} justify="end">
                    <Col>
                      <Form.Item name="neto" label="Neto">
                        <Statistic
                          prefix="$"
                          precision={2}
                          groupSeparator="."
                          decimalSeparator=","
                        />
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item name="iva" label="Iva">
                        <Statistic
                          prefix="$"
                          precision={2}
                          groupSeparator="."
                          decimalSeparator=","
                        />
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item name="total" label="Total">
                        <Statistic
                          prefix="$"
                          precision={2}
                          groupSeparator="."
                          decimalSeparator=","
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <CuotaList
              formaPagoId={form.getFieldValue('forma_pago_id')}
              dataSource={
                form.getFieldValue('cuotas') &&
                form.getFieldValue('cuotas').length
                  ? form.getFieldValue('cuotas')
                  : null
              }
              onChange={handleSetCuotas}
              onChangeFormaPago={onChangeFormaPago}
            />

            <Row gutter={[8, 0]} justify="end" style={{ marginBottom: '10px' }}>
              <Col>
                <Button
                  type="default"
                  icon={<CloseCircleOutlined />}
                  size="small"
                  onClick={(e) => handleOnCancel()}
                >
                  Cancelar
                </Button>
              </Col>
              {(!stateData.object.data.id ||
                (stateData.object.data.tipo_id < 0 &&
                  !stateData.object.data.parent_asociado)) && (
                <Col>
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    size="small"
                    onClick={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          onCreate(values);
                        })
                        .catch((info) => {
                          console.log('Validate Failed:', info);
                        });
                    }}
                  >
                    {stateData.object.data.id
                      ? 'Editar Comprobante'
                      : 'Generar Comprobante'}
                  </Button>
                </Col>
              )}
            </Row>
          </Footer>
        </Form>
        {showEditCliente ? (
          <ClienteEditor
            id={clienteId}
            onCancel={handleHideEditorCliente}
            visible={true}
          />
        ) : (
          false
        )}
        {showEditProducto ? (
          tipoItem === 'producto' ? (
            <ProductoListaPrecioEditor
              id={productoListaId}
              parentId={listaId}
              onCancel={handleHideEditorProducto}
              visible={true}
            />
          ) : (
            <ServicioEditor
              id={productoId}
              onCancel={handleHideEditorProducto}
              visible={true}
            />
          )
        ) : (
          false
        )}

        {showAddAsociados ? (
          <AsociadosPopup
            cliente={clienteId}
            tipo={tipoId}
            puntoVenta={puntoVentaId}
            onCancel={handleAsociadosCancel}
            onOk={handleAsociadosOk}
            itemSelected={form.getFieldValue('asociados')}
          />
        ) : (
          false
        )}
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  const {
    comprobante,
    cliente,
    comprobanteTipo,
    puntoVenta,
    lista,
    productoListaPrecio,
    deposito,
    productoDeposito,
    servicio,
    configuracion,
    alicuota,
    condicionVenta,
    comprobanteObservacion,
    formaPago,
    rubro,
    ubicacion,
    producto,
    productoTipo,
  } = state;
  const stateData = comprobante;
  return {
    stateData,
    cliente,
    comprobanteTipo,
    lista,
    puntoVenta,
    productoListaPrecio,
    deposito,
    productoDeposito,
    servicio,
    configuracion,
    alicuota,
    condicionVenta,
    comprobanteObservacion,
    formaPago,
    rubro,
    ubicacion,
    producto,
    productoTipo,
  };
};
export default connect(mapStateToProps)(ComprobanteEditor);
