import { Card, Row, Col } from 'antd';
import React from 'react';
import ClienteDeudores from './ClienteDeudores';
import ProductoBusqueda from './ProductoBusqueda';
import ChequeVencimientos from './ChequeVencimientos';
import CobroComisiones from './CobroComisiones';
import { hasPermiso } from '../utils/funtions';

const Dashboard = () => {
  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Card title={'Búsqueda de Producto'}>
            <ProductoBusqueda />
          </Card>
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Card title={'Deuda'} bodyStyle={{ padding: '5px', height: '350px' }}>
            <ClienteDeudores />
          </Card>
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Card
            title={'Cheques'}
            bodyStyle={{ padding: '5px', height: '350px' }}
          >
            <ChequeVencimientos />
          </Card>
        </Col>
        {hasPermiso(701) && (
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card
              title={'Comisiones'}
              bodyStyle={{ padding: '5px', height: '350px' }}
            >
              <CobroComisiones />
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Dashboard;
