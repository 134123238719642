import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { columnsCobroMediosPrint } from '../../utils/constants';
import { formatNumber } from '../../utils/funtions';

// Create styles
const styles = StyleSheet.create({
  text: {
    marginBottom: 3,
    fontSize: 14,
  },
  tableHeader: {
    fontSize: 10,
  },
  tableData: {
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 10,
  },
});

const CobroViewerAnticipo = ({ data }) => {
  return (
    <>
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <Text style={styles.text}>Anticipos Generados</Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          border: 1,
          padding: 3,
          marginTop: '5px',
        }}
      >
        {columnsCobroMediosPrint.map((col, idx) => {
          return (
            <Text
              style={[
                styles.tableHeader,
                { paddingLeft: '3px' },
                col.index === 'detalle' ? { flex: 1 } : { width: col.width },
                col.index === 'importe' ? {} : { borderRight: 1 },
              ]}
              key={idx}
            >
              {col.title}
            </Text>
          );
        })}
      </View>
      <View style={{ flexDirection: 'row', padding: 3 }}>
        <Text style={[styles.tableData, { textAlign: 'left', width: '100px' }]}>
          Anticipo
        </Text>
        <Text style={[styles.tableData, { textAlign: 'left', flex: 1 }]}></Text>
        <Text
          style={[styles.tableData, { textAlign: 'right', width: '100px' }]}
        >
          {formatNumber(data.total)}
        </Text>
      </View>
    </>
  );
};
export default CobroViewerAnticipo;
