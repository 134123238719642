import {
  CalendarOutlined,
  CloseCircleOutlined,
  DollarOutlined,
  ExclamationCircleOutlined,
  FileAddOutlined,
  FileTextOutlined,
  FormOutlined,
  MinusCircleOutlined,
  PercentageOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  UnorderedListOutlined,
  UserOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Statistic,
  Tag,
} from 'antd';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  get,
  save,
  setPending,
} from '../redux/actions/comprobanteCompraActions';
import {
  getAll as proveedores,
  setPending as setPendingProveedor,
} from '../redux/actions/proveedorActions';
import {
  compareObject,
  validateAsociados,
  validateItems,
  viewImportes,
  viewItems,
} from '../utils/funtions';

import { useHistory } from 'react-router-dom';
import { getAll as depositos } from '../redux/actions/depositoActions';
import {
  getAllByParent as getProductos,
  setPending as setPendingProducto,
} from '../redux/actions/productoActions';
import { getAll as rubros } from '../redux/actions/rubroCompraActions';
import { getAll as ubicaciones } from '../redux/actions/ubicacionActions';

import flattenDeep from 'lodash/flattenDeep';
import styled from 'styled-components';
import { callStatus } from '../utils/constants';
import { convertDate } from '../utils/funtions';
import AsociadosPopup from './AsociadosPopup';
import ProductoEditor from './ProductoEditor';
import ProveedorEditor from './ProveedorEditor';

const { confirm, info, error, success } = Modal;
const { Option } = Select;

const { Content, Header, Footer } = Layout;

const TitleCard = styled.div`
  display: flex;
  align-items: center;
`;

const ComprobanteCompraEditor = ({
  dispatch,
  stateData,
  visible,
  onCancel,
  proveedor,
  producto,
  comprobanteTipo,
  deposito,
  alicuota,
  rubroCompra,
  ubicacion,
  match: {
    params: { id },
  },
}) => {
  const [form] = Form.useForm();
  const [formProducto] = Form.useForm();

  const [initial, setInitial] = useState({});

  const [showEditProveedor, setShowEditProveedor] = useState(false);
  const [showEditProducto, setShowEditProducto] = useState(false);
  const [showAddAsociados, setShowAddAsociados] = useState(false);

  const [devolucion, setDevolucion] = useState(true);

  const [tipoId, setTipoId] = useState(null);
  const [depositoId, setDepositoId] = useState(null);

  const [countNid, setCountNid] = useState(-1);

  const [proveedorId, setProveedorId] = useState(null);
  const [productoId, setProductoId] = useState(null);
  const [tipoItem, setTipoItem] = useState('producto');

  const [resultSearchProducto, setResultSearchProducto] = useState([]);
  const [productoList, setProductoList] = useState([]);
  const [depositoList, setDepositoList] = useState([]);

  const [cotizacionDolar, setCotizacionDolar] = useState(null);

  const [resultSearchProveedor, setResultSearchProveedor] = useState([]);
  const [proveedorList, setProveedorList] = useState([]);

  const [errorItemList, setErrorItemList] = useState(null);

  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  const history = useHistory();

  const proveedorRef = useRef(null);

  useEffect(() => {
    if (id && id !== '0') {
      dispatch(get(id));
    }
    depositos(dispatch);
    proveedores(dispatch);
    rubros(dispatch);
    ubicaciones(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);
    if (stateData.object.status === callStatus.OBJECT_PENDING) {
      data['total'] = 0;
      data['neto'] = 0;
      data['iva'] = 0;
      data['percepcion_iva'] = 0;
      data['percepcion_iibb'] = 0;
      data['no_gravado'] = 0;
      data['descuento'] = 0;
      data['mora'] = 0;
      data['fecha'] = moment(moment(data.fecha));
      data['fecha_carga'] = moment(moment(data.fecha_carga));
    } else if (
      stateData.object.status === callStatus.OBJECT_SUCCESS ||
      stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS
    ) {
      if (data.tipo_id > 0) {
        data['fecha'] = moment(moment(data.fecha));
        data['fecha_carga'] = moment(moment(data.fecha_carga));

        data['proveedor_id'] = data.proveedor.cuit
          ? `${data.proveedor.cuit} - ${data.proveedor.nombre}`
          : `${data.proveedor.nombre}`;
      } else {
        if (data.parent_asociado) {
          data['fecha'] = dayjs(
            new Date(moment(moment(convertDate(data.fecha)))).toISOString()
          ).format(formatDisplay);
          data['fecha_carga'] = dayjs(
            new Date(
              moment(moment(convertDate(data.fecha_carga)))
            ).toISOString()
          ).format(formatDisplay);
          data[
            'proveedor_id'
          ] = `${data.proveedor.cuit} - ${data.proveedor.nombre}`;
        } else {
          data['fecha'] = moment(moment(data.fecha));
          data['fecha_carga'] = moment(moment(data.fecha_carga));
        }
      }

      data['alicuotas'] = data.alicuotas.map((item) => {
        return {
          alicuota_base: item.base_imponible,
          alicuota_id: item.alicuota_id,
          alicuota_importe: item.importe,
          alicuota_nombre: item.alicuota,
        };
      });

      data['asociados'] = data.asociados.map((item) => {
        return {
          asociado_id: item.asociado_id,
          asociado_fecha: dayjs(
            new Date(moment(moment(convertDate(item.fecha)))).toISOString()
          ).format(formatDisplay),
          asociado_numero: item.numero,
          asociado_tipo: item.tipo,
          asociado_tipo_id: item.tipo_id,
          asociado_total: item.total,
          asociado_cotizacion: item.cotizacion_dolar,
        };
      });

      data['items'] = data.items
        ? data.items.map((item) => {
            return {
              producto_nid: item.id,
              producto_alicuota: item.alicuota,
              producto_alicuota_id: item.alicuota_id,
              producto_nombre: item.descripcion,
              producto_cantidad: item.cantidad,
              producto_id: item.producto_id ? item.producto_id : null,
              producto_iva: item.iva,
              producto_neto: item.neto,
              producto_descuento: item.descuento,
              producto_mora: item.mora,
              producto_precio_compra: item.precio_unitario,
              producto_precio: item.precio,
              producto_subtotal: item.subtotal,
              producto_tipo_id: item.tipo_id,
              producto_tipo: getTipoItemName(item.tipo_id),
              producto_deposito: item.deposito_id,
              producto_asociado: item.asociado_id,
              producto_moneda_id: item.moneda_id,
              producto_cotizacion: item.cotizacion_dolar,
              producto_precio_real: item.precio_real,
            };
          })
        : [];

      setTipoId(stateData.object.data.tipo_id);

      if (data.items.length === 0) {
        setDevolucion(false);
        data['totalNoItem'] = data.total;
        data['alicuota_id'] = data.alicuotas[0].alicuota_id;
      }

      if (stateData.object.data.proveedor) {
        setResultSearchProveedor([stateData.object.data.proveedor]);
        setProveedorId(stateData.object.data.proveedor_id);
      }

      if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
        handleSaveSuccess('El comprobante se guardó con éxito');
      }
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleSaveError(stateData.object.error);
    }
    form.setFieldsValue(data);
    setInitial(data);

    // eslint-disable-next-line
  }, [stateData.object]);

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);
    data.fecha_carga = dayjs(new Date(data.fecha_carga).toISOString()).format(
      format
    );

    if (validateAsociados(data.tipo_id, devolucion, data.asociados)) {
      handleSaveError('Debe elegir un Comprobante Asociado');
    } else {
      dispatch(save(data));
    }
  };

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          dispatch(setPending());
          history.push(`/compras`);
        },
        onCancel() {},
      });
    } else {
      dispatch(setPending());
      history.push(`/compras`);
    }
  };

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      dispatch(setPending());
      return true;
    });

    return () => {
      unlisten();
    };
  });

  const onChangeTipo = (value) => {
    setTipoId(value);

    switch (value) {
      case -6:
      case -7:
      case 2:
      case 3:
      case 7:
      case 8:
      case 12:
      case 13:
        form.setFieldsValue({
          asociados: undefined,
          items: undefined,
          alicuotas: undefined,
          total: 0,
          neto: 0,
          iva: 0,
          descuento: 0,
          mora: 0,
          totalNoItem: undefined,
          alicuota_id: undefined,
          observacion: undefined,
          percepcion_iva: 0,
          percepcion_iibb: 0,
          no_gravado: 0,
        });
        break;

      default:
        form.setFieldsValue({
          totalNoItem: undefined,
          alicuota_id: undefined,
        });
        handleRemoveAllAsociados();
        break;
    }
  };

  // CLIENTE /////////////////////////////////////////////////////////////

  const initProveedorValues = () => {
    setProveedorId(null);
    setResultSearchProveedor([]);
    form.setFieldsValue({
      proveedor_id: null,
      proveedor_nombre: null,
      proveedor_condicion_iva: null,
    });
  };

  useEffect(() => {
    setProveedorList(proveedor.list.data);
    // eslint-disable-next-line
  }, [proveedor]);

  const handleOnSearchProveedorEnter = (e) => {
    const value = e.target.value;
    e.preventDefault();
    e.stopPropagation();
    form.validateFields(['proveedor_id']);

    if (value) {
      handleConfirm();
    }
  };

  const handleOnSearchProveedorBlur = (e) => {
    const value = e.target.value;
    e.preventDefault();
    e.stopPropagation();
    form.validateFields(['proveedor_id']);

    if (value) {
      handleConfirm();
    }
  };

  const handleHideEditorProveedor = () => {
    setShowEditProveedor(false);
    dispatch(setPendingProveedor());
  };

  useEffect(() => {
    if (proveedorId && proveedorList) {
      if (!stateData.object.data.id) {
        const result = proveedorList.find((item) => item.id === proveedorId);
        if (result) {
          onChangeProveedor(result.id);
        }
      }

      dispatch(getProductos(proveedorId));
    }
    // eslint-disable-next-line
  }, [proveedorId, proveedorList]);

  const onSearchProveedor = (value) => {
    if (value) {
      let result = [];
      result = proveedorList.filter(
        (item) =>
          item.nombre.toLowerCase().includes(value.toLowerCase()) ||
          (item.cuit && item.cuit.toLowerCase().includes(value.toLowerCase()))
      );
      setResultSearchProveedor(result);
    } else {
      setResultSearchProveedor([]);
    }
  };

  const onChangeProveedor = (value) => {
    const result = proveedorList.find((item) => item.id === value);

    if (result) {
      setResultSearchProveedor([{ ...result }]);

      form.setFieldsValue({
        proveedor_nombre: result.nombre,
        proveedor_condicion_iva: result.condicion_iva_nombre,
      });
      setProveedorId(result.id);
    } else {
      initProveedorValues();
    }

    handleRemoveAllAsociados();
    initProductoValues();
  };

  const handleInfo = (message) => {
    info({
      title: message,
      onOk() {},
    });
  };

  const handleSaveError = (message) => {
    error({
      title: message,
      onOk() {},
    });
  };

  const handleSaveSuccess = (message) => {
    success({
      title: message,
      onOk() {
        history.push(`/compras`);
      },
    });
  };

  const handleConfirm = () => {
    confirm({
      title: 'El proveedor no existe, desea cargarlo?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        setShowEditProveedor(true);
      },
      onCancel() {},
    });
  };

  // ASOCIADO /////////////////////////////////////////////////////////////

  const handleAsociadosCancel = () => {
    setShowAddAsociados(false);
  };

  const handleAsociadosOk = (data) => {
    let asociados = form.getFieldValue('asociados')
      ? form.getFieldValue('asociados')
      : [];

    let items = form.getFieldValue('items') ? form.getFieldValue('items') : [];

    asociados = [
      ...asociados,
      ...data.map((item) => {
        if (!asociados.some((value) => value.asociado_id === item.id)) {
          return {
            asociado_id: item.id,
            asociado_fecha: dayjs(
              new Date(moment(moment(convertDate(item.fecha)))).toISOString()
            ).format(formatDisplay),
            asociado_numero: item.numero,
            asociado_total: item.total,
            asociado_tipo: item.tipo_nombre,
            asociado_tipo_id: item.tipo_id,
            asociado_cotizacion: item.cotizacion_dolar,
          };
        } else {
          return null;
        }
      }),
    ];

    if (devolucion && tipoId !== -7 && tipoId !== 2 && tipoId !== 7) {
      data.forEach((comp) => {
        items = [
          ...items,
          comp.items.map((item) => {
            return {
              producto_new: true,
              producto_nid: item.id,
              producto_alicuota: item.alicuota,
              producto_alicuota_id: item.alicuota_id,
              producto_nombre: item.descripcion,
              producto_cantidad: item.cantidad,
              producto_id: item.producto_id ? item.producto_id : null,
              producto_iva: item.iva,
              producto_neto: item.neto,
              producto_descuento: item.descuento,
              producto_mora: item.mora,
              producto_precio_compra: item.precio_unitario,
              producto_precio: item.precio,
              producto_subtotal: item.subtotal,
              producto_tipo_id: item.tipo_id,
              producto_tipo: getTipoItemName(item.tipo_id),
              producto_asociado: comp.id,
              producto_deposito: item.deposito_id,
              producto_moneda_id: item.moneda_id,
              producto_precio_real:
                item.moneda_id === 2
                  ? (item.precio_unitario / cotizacionDolar).toFixed(2)
                  : item.precio_real,
              producto_cotizacion:
                item.moneda_id === 2 ? cotizacionDolar : item.cotizacion_dolar,
            };
          }),
        ];
      });

      items = flattenDeep(items);

      form.setFieldsValue({
        asociados: asociados.filter((item) => item !== null),
        items: items,
      });
    } else {
      form.setFieldsValue({
        asociados: asociados.filter((item) => item !== null),
        items: undefined,
      });
    }

    setShowAddAsociados(false);
    calcTotales();
  };

  const handleAsociadosSearch = () => {
    if (proveedorId && tipoId) {
      setShowAddAsociados(true);
    } else {
      handleInfo(
        'Debe seleccionar Tipo de Comprobante y Proveedor para asociar comprobantes'
      );
    }
  };

  const handleRemoveAllAsociados = () => {
    if (form.getFieldValue('items') && form.getFieldValue('asociados')) {
      const itemsAsociados = form
        .getFieldValue('items')
        .filter((item) => !item.producto_asociado);

      form.setFieldsValue({
        items: itemsAsociados,
        asociados: undefined,
      });
    }

    calcTotales();
  };

  const handleRemoveAsociados = (value) => {
    const itemsAsociados = form
      .getFieldValue('items')
      .filter((item) => item.producto_asociado !== value.asociado_id);

    form.setFieldsValue({
      items: itemsAsociados,
    });

    calcTotales();
  };

  // PRODUCTOS ////////////////////////////////////////////////////////////////

  const initProductoValues = (tipo = undefined) => {
    setProductoId(null);
    setResultSearchProducto([]);

    if (!tipo) {
      tipo = tipoItem;
    }

    formProducto.setFieldsValue({
      producto_id: null,
      producto_nombre: null,
      producto_precio_compra: null,
      producto_precio: null,
      producto_alicuota: null,
      producto_alicuota_id: null,
      producto_iva: null,
      producto_cantidad: null,
      producto_neto: null,
      producto_subtotal: null,
      producto_moneda_id: null,
      producto_descuento: null,
      producto_mora: null,
      producto_precio_real: null,
      deposito_id: null,
      producto_stock: null,
      producto_stock_minimo: null,
      tipo_producto: null,
    });
  };

  const handleHideEditorProducto = () => {
    setShowEditProducto(false);
    dispatch(setPendingProducto());
  };

  useEffect(() => {
    setDepositoList(deposito.list.data);
    if (deposito.list.data.length === 1) {
      setDepositoId(deposito.list.data[0].id);
      formProducto.setFieldsValue({ deposito_id: deposito.list.data[0].id });
    }
    // eslint-disable-next-line
  }, [deposito]);

  useEffect(() => {
    setProductoList(producto.list.data);
    // eslint-disable-next-line
  }, [producto]);

  useEffect(() => {
    if (productoId) {
      const result = productoList.find((item) => item.id === productoId);
      if (result) {
        if (tipoItem === 'producto') {
          onChangeProducto(result.producto_id);
        } else {
          onChangeProducto(result.id);
        }
      }
    }
    // eslint-disable-next-line
  }, [productoList]);

  const handleOnChangeDeposito = () => {
    // initProductoValues();
    const id = formProducto.getFieldValue('deposito_id');
    setDepositoId(id);

    if (productoId) {
      let result = productoList.find((item) => item.id === productoId);

      if (result.depositos.length > 0) {
        let depositoFind = result.depositos.find(
          (item) => item.deposito_id === id
        );

        if (depositoFind) {
          formProducto.setFieldsValue({
            deposito_id: depositoFind.deposito_id,
            producto_stock: depositoFind.cantidad,
            producto_stock_minimo: depositoFind.cantidad_minima,
          });
        } else {
          formProducto.setFieldsValue({
            deposito_id: id,
            producto_stock: null,
            producto_stock_minimo: null,
          });
        }
      }
    }
  };

  const onSearchProducto = (value) => {
    if (value) {
      let result = [];

      result = productoList.filter((item) => {
        return (
          (item.nombre &&
            item.nombre.toLowerCase().includes(value.toLowerCase())) ||
          (item.codigo &&
            item.codigo.toLowerCase().includes(value.toLowerCase()))
        );
      });
      setResultSearchProducto(result);
    } else {
      setResultSearchProducto([]);
    }
  };

  const onChangeProducto = (value) => {
    if (cotizacionDolar) {
      const result = productoList.find((item) => item.id === value);

      if (result) {
        let depositoProducto = null;
        if (result.depositos.length > 0) {
          depositoProducto = result.depositos[0];
        }

        setResultSearchProducto([{ ...result }]);

        const precio_real = result.precio_compra;

        const alicuota = result.alicuota.nombre;

        let cantidad = !formProducto.getFieldValue('producto_cantidad')
          ? 1
          : formProducto.getFieldValue('producto_cantidad');

        formProducto.getFieldValue('producto_descuento');

        const descuento = formProducto.getFieldValue('producto_descuento')
          ? formProducto.getFieldValue('producto_descuento')
          : 0;
        const mora = formProducto.getFieldValue('producto_mora')
          ? formProducto.getFieldValue('producto_mora')
          : 0;

        const cotizacion =
          result.moneda_id && result.moneda_id === 1 ? 1 : cotizacionDolar;

        const precio_compra = precio_real * cotizacion;

        const precio = (
          parseFloat(precio_compra) -
          (parseFloat(descuento) * parseFloat(precio_compra)) / 100 +
          (parseFloat(mora) * parseFloat(precio_compra)) / 100
        ).toFixed(2);

        const iva = (
          (parseFloat(precio) * parseFloat(cantidad) * parseFloat(alicuota)) /
          100
        ).toFixed(2);
        const neto = (parseFloat(precio) * parseFloat(cantidad)).toFixed(2);
        const subtotal = (parseFloat(iva) + parseFloat(neto)).toFixed(2);

        formProducto.setFieldsValue({
          producto_nombre: result.nombre,
          producto_precio_real: precio_real,
          producto_precio_compra: precio_compra.toFixed(2),
          producto_precio: precio,
          producto_alicuota_id: result.alicuota_id,
          producto_alicuota: result.alicuota ? result.alicuota.nombre : null,

          producto_cantidad: cantidad,
          producto_iva: iva,
          producto_neto: neto,
          producto_subtotal: subtotal,
          producto_moneda_id: result.moneda_id,
          producto_cotizacion: cotizacion,

          producto_descuento: descuento,
          producto_mora: mora,
          deposito_id: depositoProducto.deposito_id,
          producto_stock: depositoProducto ? depositoProducto.cantidad : null,
          producto_stock_minimo: depositoProducto
            ? depositoProducto.cantidad_minima
            : null,
          tipo_producto: result.tipo ? result.tipo.nombre : null,
        });

        setProductoId(result.id);

        setDepositoId(depositoProducto.deposito_id);
      } else {
        initProductoValues();

        setDepositoId(null);
      }
    } else {
      initProductoValues();
      handleSaveError('Debe ingresar la cotizacion del Dolar Compra de hoy');
    }
  };

  const handleChangeRadio = (e) => {
    const value = e.target.value;
    setTipoItem(value);
    initProductoValues(value);

    if (value === 'producto') {
      if (depositoList.length === 1) {
        setDepositoId(depositoList[0].id);
        formProducto.setFieldsValue({ deposito_id: depositoList[0].id });
      }
    } else {
      formProducto.setFieldsValue({ deposito_id: null });
      setDepositoId(null);
    }
  };

  const handleShowEditProducto = () => {
    if (proveedorId) {
      setShowEditProducto(true);
    } else {
      handleInfo('Debe seleccionar Proveedor para asociar un producto');
    }
  };

  // CALCULO DE TOTALES //////////////////////////////////////////////////////////////////////

  const calcTotales = () => {
    const items = form.getFieldValue('items');

    const totalNoItem = form.getFieldValue('totalNoItem');
    const alicuotaNoItem = form.getFieldValue('alicuota_id');

    const percepcionIva = form.getFieldValue('percepcion_iva');
    const percepcionIibb = form.getFieldValue('percepcion_iibb');
    const noGravado = form.getFieldValue('no_gravado');

    let neto = 0;
    let iva = 0;
    let total = 0;
    let alicuotas = [];

    if (items && items.length > 0) {
      const detalle = items;

      detalle.forEach((item) => {
        neto = neto + parseFloat(item.producto_neto);
        iva = iva + parseFloat(item.producto_iva);
        total = total + parseFloat(item.producto_subtotal);
        if (
          !alicuotas.some(
            (alic) => alic.alicuota_id === item.producto_alicuota_id
          )
        ) {
          alicuotas.push({
            alicuota_id: item.producto_alicuota_id,
            alicuota_nombre: item.producto_alicuota,
            alicuota_importe: item.producto_iva,
            alicuota_base: item.producto_neto,
          });
        } else {
          alicuotas = alicuotas.map((alic) => {
            if (alic.alicuota_id === item.producto_alicuota_id) {
              return {
                ...alic,
                alicuota_importe: (
                  parseFloat(alic.alicuota_importe) +
                  parseFloat(item.producto_iva)
                ).toFixed(2),
                alicuota_base: (
                  parseFloat(alic.alicuota_base) +
                  parseFloat(item.producto_neto)
                ).toFixed(2),
              };
            } else {
              return alic;
            }
          });
        }
      });
    } else {
      if (totalNoItem && alicuotaNoItem) {
        const alicuotaValor = alicuota.list.data.find(
          (item) => item.id === alicuotaNoItem
        ).nombre;

        total = parseFloat(totalNoItem);
        neto = total / (1 + parseFloat(alicuotaValor) / 100);
        iva = total - neto;

        alicuotas.push({
          alicuota_id: alicuotaNoItem,
          alicuota_nombre: alicuotaValor,
          alicuota_importe: iva.toFixed(2),
          alicuota_base: neto.toFixed(2),
        });
      }
    }

    if (percepcionIva) {
      total = total + parseFloat(percepcionIva);
    }
    if (percepcionIibb) {
      total = total + parseFloat(percepcionIibb);
    }
    if (noGravado) {
      total = total + parseFloat(noGravado);
    }
    form.setFieldsValue({
      producto_id: null,
      neto: neto.toFixed(2),
      iva: iva.toFixed(2),
      total: total.toFixed(2),
      alicuotas: alicuotas,
      cotizacion_dolar: cotizacionDolar,
    });

    existeDetalleDolarDistinct();
  };

  const calcSubtotal = (dolar = true) => {
    const moneda = formProducto.getFieldValue('producto_moneda_id');
    const cotizacion = moneda && moneda === 1 ? 1 : cotizacionDolar;
    const cantidad = formProducto.getFieldValue('producto_cantidad');
    let precio_compra = formProducto.getFieldValue('producto_precio_compra');
    const alicuota = formProducto.getFieldValue('producto_alicuota');
    const descuento = !formProducto.getFieldValue('producto_descuento')
      ? 0
      : formProducto.getFieldValue('producto_descuento');

    const mora = !formProducto.getFieldValue('producto_mora')
      ? 0
      : formProducto.getFieldValue('producto_mora');

    const precio_real = dolar
      ? formProducto.getFieldValue('producto_precio_real')
      : (parseFloat(precio_compra) / parseFloat(cotizacion)).toFixed(6);

    precio_compra =
      tipoItem === 'especial' || !dolar
        ? formProducto.getFieldValue('producto_precio_compra')
        : (parseFloat(precio_real) * parseFloat(cotizacion)).toFixed(2);

    const precio = (
      parseFloat(precio_compra) -
      (parseFloat(descuento) * parseFloat(precio_compra)) / 100 +
      (parseFloat(mora) * parseFloat(precio_compra)) / 100
    ).toFixed(2);

    const iva = (
      (parseFloat(precio) * parseFloat(cantidad) * parseFloat(alicuota)) /
      100
    ).toFixed(2);
    const neto = (parseFloat(precio) * parseFloat(cantidad)).toFixed(2);
    const subtotal = (parseFloat(iva) + parseFloat(neto)).toFixed(2);

    formProducto.setFieldsValue({
      producto_descuento: descuento,
      producto_mora: mora,
      producto_precio: precio,
      producto_iva: iva,
      producto_neto: neto,
      producto_subtotal: subtotal,
      producto_precio_compra: precio_compra,
      producto_cotizacion: cotizacion,
      producto_precio_real: precio_real,
    });
  };

  const handleChangeAlicuotaDetalle = (index) => {
    let updateItems = form.getFieldValue('items');

    const result = alicuota.list.data.find(
      (item) => item.id === updateItems[index].producto_alicuota_id
    );
    updateItems[index].producto_alicuota = result.nombre;

    calcSubtotalDetalle(index);
  };

  const calcSubtotalDetalle = (index) => {
    let updateItems = form.getFieldValue('items');

    const cantidad = updateItems[index].producto_cantidad;
    const precio_compra = updateItems[index].producto_precio_compra;
    const descuento = !updateItems[index].producto_descuento
      ? 0
      : updateItems[index].producto_descuento;

    const mora = !updateItems[index].producto_mora
      ? 0
      : updateItems[index].producto_mora;

    const alicuota = updateItems[index].producto_alicuota;

    const precio = (
      parseFloat(precio_compra) -
      (parseFloat(descuento) * parseFloat(precio_compra)) / 100 +
      (parseFloat(mora) * parseFloat(precio_compra)) / 100
    ).toFixed(2);

    const iva = (
      (parseFloat(precio) * parseFloat(cantidad) * parseFloat(alicuota)) /
      100
    ).toFixed(2);
    const neto = (parseFloat(precio) * parseFloat(cantidad)).toFixed(2);
    const subtotal = (parseFloat(iva) + parseFloat(neto)).toFixed(2);

    if (!cantidad) {
      updateItems[index].producto_descuento = descuento;
      updateItems[index].producto_mora = mora;
      updateItems[index].producto_precio = precio;
      updateItems[index].producto_iva = (0).toFixed(2);
      updateItems[index].producto_neto = (0).toFixed(2);
      updateItems[index].producto_subtotal = (0).toFixed(2);
    } else {
      updateItems[index].producto_descuento = descuento;
      updateItems[index].producto_mora = mora;
      updateItems[index].producto_precio = precio;
      updateItems[index].producto_iva = iva;
      updateItems[index].producto_neto = neto;
      updateItems[index].producto_subtotal = subtotal;
    }

    form.setFieldsValue({ items: updateItems });

    calcTotales();
  };

  const updateItemsDetalleCotizacion = (incluyePresupuestos) => {
    const detalle = form.getFieldValue('items');
    if (detalle) {
      detalle.forEach((item, index) => {
        if (item.producto_moneda_id === 2) {
          if (incluyePresupuestos) {
            item.producto_cotizacion = cotizacionDolar;
            item.producto_precio_real = (
              parseFloat(item.producto_precio_compra) /
              parseFloat(cotizacionDolar)
            ).toFixed(6);
          }
        }
      });
    }
  };

  const handleAddItems = (item) => {
    const items = form.getFieldValue('items');

    if (items) {
      form.setFieldsValue({
        items: [
          ...items,
          {
            ...item,
            producto_nid: countNid,
            producto_new: true,
          },
        ],
      });
    } else {
      form.setFieldsValue({
        items: [
          {
            ...item,
            producto_nid: countNid,
            producto_new: true,
          },
        ],
      });
    }

    setCountNid((prev) => prev - 1);

    handleUpdateItems();
  };

  const handleUpdateItems = (item = undefined) => {
    calcTotales();
    initProductoValues();
    setErrorItemList(null);
  };

  // COTIZACION //////////////////////////////////////////////////////////

  const handleSetCotizacionDolar = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setCotizacionDolar(value);
  };

  const handleSaveCotizacionDolar = (update) => {
    if (update) {
      updateItemsDetalleCotizacion(true);
    }
  };

  const existeDetalleDolarDistinct = () => {
    handleSaveCotizacionDolar(true);

    calcSubtotal();
  };

  // END COTIZACION //////////////////////////////////////////////////////////

  // IMPORTES ////////////////////////////////////
  const handleChangeEfectivo = (e) => {
    calcTotales();
  };

  const handleSetDevolucion = (e) => {
    setDevolucion(e.target.checked);

    form.setFieldsValue({
      asociados: undefined,
      items: undefined,
      alicuotas: undefined,
      total: 0,
      neto: 0,
      iva: 0,
      descuento: 0,
      mora: 0,
      totalNoItem: undefined,
      alicuota_id: undefined,
      observacion: undefined,
      percepcion_iva: 0,
      percepcion_iibb: 0,
      no_gravado: 0,
    });
  };

  const handleChangeAlicuota = (value) => {
    calcTotales();
  };

  // END IMPORTES ////////////////////////////////

  const handleChangeProductoAlicuota = (value) => {
    const result = alicuota.list.data.find((item) => item.id === value);

    formProducto.setFieldsValue({
      producto_alicuota: result.nombre,
    });
    calcSubtotal();
  };

  const getTipoItem = (tipo) => {
    switch (tipo) {
      case 'producto':
        return 1;

      case 'especial':
        return 3;

      default:
        return null;
    }
  };

  const getTipoItemName = (tipo) => {
    switch (tipo) {
      case 1:
        return 'producto';

      case 3:
        return 'especial';

      default:
        return null;
    }
  };

  return (
    <Spin spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}>
      <Layout style={{ height: '100%' }}>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={stateData.object.data}
          size="small"
        >
          <Header>
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="id" label="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Card
              size="small"
              style={{ marginBottom: '10px' }}
              extra={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {stateData.object.data.numero && (
                    <span style={{ fontSize: '18px' }}>
                      <b> Comprobante N°: {stateData.object.data.numero}</b>
                    </span>
                  )}

                  {stateData.object.data.parent_asociado && (
                    <Tag
                      color="#108ee9"
                      style={{ marginLeft: '10px', fontSize: '15px' }}
                    >
                      Asociado
                    </Tag>
                  )}
                </div>
              }
              title={
                <TitleCard>
                  <Avatar
                    icon={<CalendarOutlined />}
                    size="small"
                    style={{ backgroundColor: '#4f43ba' }}
                  />
                  <span style={{ marginLeft: '3px' }}> Datos Generales</span>
                </TitleCard>
              }
            >
              <Row gutter={[8, 0]} align="top">
                <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="fecha"
                    label="Fecha emisión"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <DatePicker
                      format={formatDisplay}
                      style={{ width: '100%' }}
                      initialValue={moment(new Date())}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="fecha_carga"
                    label="Fecha carga"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <DatePicker
                      format={formatDisplay}
                      style={{ width: '100%' }}
                      initialValue={moment(new Date())}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="numero"
                    label="Número"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="tipo_id"
                    label="Tipo"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                    extra={
                      (tipoId === -6 ||
                        tipoId === 3 ||
                        tipoId === 8 ||
                        tipoId === 13) && (
                        <>
                          <Checkbox
                            disabled={stateData.object.data.id}
                            checked={devolucion}
                            onChange={(e) => handleSetDevolucion(e)}
                            style={{ marginTop: '3px' }}
                          />{' '}
                          Devolución
                        </>
                      )
                    }
                  >
                    <Select
                      onChange={onChangeTipo}
                      open={stateData.object.data.id ? false : undefined}
                    >
                      {comprobanteTipo.list.data
                        .filter((item) => item.id !== -1)
                        .map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.letra
                              ? item.nombre + ' ' + item.letra
                              : item.nombre}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="rubro_id"
                    label="Rubro"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select>
                      {rubroCompra.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="ubicacion_id"
                    label="Ubicación"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select>
                      {ubicacion.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card
              extra={
                !stateData.object.data.id ||
                (stateData.object.data.tipo_id < 0 &&
                  !stateData.object.data.parent_asociado) ? (
                  proveedorId ? (
                    <FormOutlined
                      style={{ fontSize: '18px' }}
                      onClick={() => setShowEditProveedor(true)}
                    />
                  ) : (
                    <PlusCircleOutlined
                      style={{ fontSize: '18px' }}
                      onClick={() => setShowEditProveedor(true)}
                    />
                  )
                ) : (
                  false
                )
              }
              title={
                <TitleCard>
                  <Avatar
                    icon={<UserOutlined />}
                    size="small"
                    style={{ backgroundColor: '#4f43ba' }}
                  />
                  <span style={{ marginLeft: '3px' }}> Proveedor</span>
                </TitleCard>
              }
              style={{ marginBottom: '10px' }}
              size="small"
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={14} lg={14} xl={8}>
                  <Form.Item
                    name="proveedor_id"
                    label="Proveedor"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    {!stateData.object.data.id ||
                    (stateData.object.data.tipo_id < 0 &&
                      !stateData.object.data.parent_asociado) ? (
                      proveedor.list.status === callStatus.LIST_SUCCESS ? (
                        <Select
                          showSearch
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={false}
                          onSearch={onSearchProveedor}
                          onChange={onChangeProveedor}
                          onPressEnter={(e) => handleOnSearchProveedorEnter(e)}
                          onBlur={(e) => handleOnSearchProveedorBlur(e)}
                          notFoundContent={null}
                          allowClear
                          ref={proveedorRef}
                        >
                          {resultSearchProveedor.map((item, index) => (
                            <Option key={index} value={item.id}>
                              {item.cuit && `${item.cuit} - `}
                              {item.nombre}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Spin spinning={true} size="small">
                          <Input readOnly />
                        </Spin>
                      )
                    ) : (
                      <Input readOnly />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                  <Form.Item
                    name="proveedor_condicion_iva"
                    label="Condición IVA"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card
              extra={
                !stateData.object.data.id ||
                (stateData.object.data.tipo_id < 0 &&
                  !stateData.object.data.parent_asociado) ? (
                  <PlusCircleOutlined
                    style={{ fontSize: '18px' }}
                    onClick={() => handleAsociadosSearch()}
                  />
                ) : (
                  false
                )
              }
              title={
                <TitleCard>
                  <Avatar
                    icon={<FileAddOutlined />}
                    size="small"
                    style={{ backgroundColor: '#4f43ba' }}
                  />
                  <span style={{ marginLeft: '3px' }}>
                    Comprobantes Asociados
                  </span>
                </TitleCard>
              }
              style={{ marginBottom: '10px' }}
              size="small"
            >
              <Row>
                <Form.List name="asociados">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      <Row
                        gutter={[8, 0]}
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          fontWeight: '500',
                          display:
                            (!form.getFieldValue('asociados') ||
                              form.getFieldValue('asociados').length === 0) &&
                            'none',
                        }}
                        align="top"
                      >
                        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                          Tipo
                        </Col>
                        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                          Fecha
                        </Col>

                        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                          Número
                        </Col>
                        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                          Total
                        </Col>
                        <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
                      </Row>
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, index) => (
                          <Row
                            gutter={[8, 0]}
                            style={{ width: '100%' }}
                            key={index}
                            align="top"
                          >
                            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                              <Form.Item
                                {...restField}
                                name={[name, 'asociado_tipo']}
                                fieldKey={[fieldKey, 'asociado_tipo']}
                                style={{ marginBottom: '5px' }}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                              <Form.Item
                                {...restField}
                                name={[name, 'asociado_fecha']}
                                fieldKey={[fieldKey, 'asociado_fecha']}
                                style={{ marginBottom: '5px' }}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                              <Form.Item
                                {...restField}
                                name={[name, 'asociado_numero']}
                                fieldKey={[fieldKey, 'asociado_numero']}
                                style={{ marginBottom: '5px' }}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                              <Form.Item
                                {...restField}
                                name={[name, 'asociado_total']}
                                fieldKey={[fieldKey, 'asociado_total']}
                                style={{ marginBottom: '5px' }}
                              >
                                <Input readOnly />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                              {(!stateData.object.data.id ||
                                (stateData.object.data.tipo_id < 0 &&
                                  !stateData.object.data.parent_asociado)) && (
                                <MinusCircleOutlined
                                  style={{ color: 'red' }}
                                  onClick={() => {
                                    handleRemoveAsociados(
                                      form.getFieldValue('asociados')[index]
                                    );
                                    remove(name);
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        )
                      )}
                    </>
                  )}
                </Form.List>
              </Row>
            </Card>
          </Header>
          <Content>
            {viewImportes(tipoId, devolucion) && (
              <Card
                title={
                  <TitleCard>
                    <Avatar
                      icon={<FileAddOutlined />}
                      size="small"
                      style={{ backgroundColor: '#4f43ba' }}
                    />
                    <span style={{ marginLeft: '3px' }}>Importe</span>
                  </TitleCard>
                }
                style={{ marginBottom: '10px' }}
                size="small"
              >
                <Row gutter={[8, 0]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row gutter={[8, 0]}>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Form.Item
                          label="Total"
                          name="totalNoItem"
                          rules={[
                            {
                              required: true,
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Input
                            prefix={'$'}
                            min={0}
                            type="number"
                            onBlur={(e) => handleChangeEfectivo(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Form.Item
                          name="alicuota_id"
                          label="Alicuota Iva"
                          rules={[
                            {
                              required: true,
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Select onChange={handleChangeAlicuota}>
                            {alicuota.list.data.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.nombre}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                        <Form.Item
                          name="observacion"
                          label="Descripción"
                          rules={[
                            {
                              required: true,
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            )}

            {viewItems(tipoId, devolucion) && (
              <Card
                extra={
                  !stateData.object.data.id ||
                  (stateData.object.data.tipo_id < 0 &&
                    !stateData.object.data.parent_asociado) ? (
                    productoId ? (
                      <FormOutlined
                        style={{ fontSize: '18px' }}
                        onClick={() => setShowEditProducto(true)}
                      />
                    ) : (
                      <PlusCircleOutlined
                        style={{ fontSize: '18px' }}
                        onClick={() => handleShowEditProducto()}
                      />
                    )
                  ) : (
                    false
                  )
                }
                title={
                  <TitleCard>
                    <Avatar
                      icon={<UnorderedListOutlined />}
                      size="small"
                      style={{ backgroundColor: '#4f43ba' }}
                    />
                    <span style={{ marginLeft: '3px' }}> Items</span>
                  </TitleCard>
                }
                style={{ marginBottom: '10px' }}
                size="small"
              >
                {(!stateData.object.data.id ||
                  (stateData.object.data.tipo_id < 0 &&
                    !stateData.object.data.parent_asociado)) && (
                  <Form
                    layout="vertical"
                    form={formProducto}
                    style={{ width: '100%' }}
                  >
                    <Row gutter={[8, 0]} style={{ marginBottom: '12px' }}>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Radio.Group
                          size="medium"
                          value={tipoItem}
                          buttonStyle="solid"
                          onChange={(e) => handleChangeRadio(e)}
                        >
                          <Radio.Button value="producto">Producto</Radio.Button>
                          <Radio.Button value="especial">Especial</Radio.Button>
                        </Radio.Group>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                width: '100px',
                                textAlign: 'right',
                                marginRight: '3px',
                                fontWeight: 'bold',
                                alignItems: 'center',
                              }}
                            >
                              Dolar hoy
                            </div>

                            <Input
                              prefix={'$'}
                              style={{ width: '100px', marginRight: '8px' }}
                              min={0}
                              type="number"
                              value={cotizacionDolar}
                              onChange={(e) => handleSetCotizacionDolar(e)}
                              onBlur={(e) => existeDetalleDolarDistinct()}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                      {tipoItem !== 'especial' ? (
                        <Col xs={24} sm={24} md={18} lg={12} xl={12}>
                          <Form.Item
                            name="producto_id"
                            label="Item"
                            rules={[
                              {
                                required: true,
                                message: 'Requerido',
                              },
                            ]}
                          >
                            {proveedorId &&
                            producto.list.status === callStatus.LIST_SUCCESS ? (
                              <Select
                                showSearch
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={onSearchProducto}
                                onChange={onChangeProducto}
                                notFoundContent={null}
                                allowClear
                                disabled={
                                  tipoItem === 'producto'
                                    ? proveedorId
                                      ? false
                                      : true
                                    : false
                                }
                              >
                                {resultSearchProducto.map((item, index) => (
                                  <Option key={index} value={item.id}>
                                    {item.codigo} - {item.nombre}
                                  </Option>
                                ))}
                              </Select>
                            ) : (
                              <Spin spinning={true} size="small">
                                <Input readOnly />
                              </Spin>
                            )}
                          </Form.Item>
                        </Col>
                      ) : (
                        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                          <Form.Item
                            name="producto_nombre"
                            label="Item"
                            rules={[
                              {
                                required: true,
                                max: 200,
                                message: 'Requerido (máx 200 caracteres)',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Row gutter={[8, 0]}>
                      <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <Form.Item
                          name="deposito_id"
                          label="Depósito"
                          rules={[
                            {
                              required: tipoItem === 'producto' ? true : false,
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Select
                            open={depositoList.length === 1 ? false : undefined}
                            onChange={handleOnChangeDeposito}
                            disabled={tipoItem !== 'producto' ? true : false}
                          >
                            {depositoList.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {`${item.nombre} (${item.ubicacion_nombre})`}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <Form.Item name="tipo_producto" label="Tipo">
                          <Input
                            readOnly={true}
                            disabled={
                              tipoItem === 'agrupador' ||
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial')
                            }
                          />
                        </Form.Item>
                      </Col>
                      {productoId &&
                      formProducto.getFieldValue('deposito_id') &&
                      formProducto.getFieldValue('producto_stock') ? (
                        <>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            <Form.Item
                              name="producto_stock"
                              label="Stock Actual"
                            >
                              <Input readOnly={true} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            <Form.Item
                              name="producto_stock_minimo"
                              label="Stock Mín"
                            >
                              <Input readOnly={true} />
                            </Form.Item>
                          </Col>
                        </>
                      ) : (
                        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                          {productoId && (
                            <div
                              style={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                              }}
                            >
                              Atención: Este producto no se encuentra ubicado en
                              el depósito seleccionado
                            </div>
                          )}
                        </Col>
                      )}
                    </Row>
                    <Row gutter={[8, 0]}>
                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item
                          name="producto_precio_compra"
                          label="Precio U"
                          rules={[
                            {
                              required: true,
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Input
                            prefix={'$'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            onBlur={() => calcSubtotal(false)}
                            disabled={
                              tipoItem !== 'especial' &&
                              !formProducto.getFieldValue('producto_id')
                            }
                          />
                        </Form.Item>
                      </Col>
                      {tipoItem !== 'especial' &&
                        formProducto.getFieldValue('producto_moneda_id') ===
                          2 && (
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            <Form.Item
                              name="producto_precio_real"
                              label="Precio U"
                            >
                              <Input
                                prefix={'U$D'}
                                min={0}
                                type="number"
                                style={{ textAlign: 'right' }}
                                onBlur={calcSubtotal}
                                disabled={
                                  !formProducto.getFieldValue('producto_id')
                                }
                              />
                            </Form.Item>
                          </Col>
                        )}

                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item name="producto_descuento" label="Desc.">
                          <Input
                            prefix={'%'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            onBlur={calcSubtotal}
                            disabled={
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial') ||
                              tipoId === -3
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item name="producto_mora" label="Mora">
                          <Input
                            prefix={'%'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            onBlur={calcSubtotal}
                            disabled={
                              (!formProducto.getFieldValue('producto_id') &&
                                tipoItem !== 'especial') ||
                              tipoId === -3
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item name="producto_precio" label="Precio">
                          <Input
                            prefix={'$'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            readOnly
                            disabled={
                              !formProducto.getFieldValue('producto_id') &&
                              tipoItem !== 'especial'
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item
                          name="producto_alicuota_id"
                          label="Alic."
                          rules={[
                            {
                              required: true,
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Select
                            onChange={handleChangeProductoAlicuota}
                            disabled={
                              !formProducto.getFieldValue('producto_id') &&
                              tipoItem !== 'especial'
                            }
                          >
                            {alicuota.list.data.map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.nombre}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item
                          name="producto_cantidad"
                          style={{ textAlign: 'right' }}
                          label="Cantidad"
                          rules={[
                            {
                              required: true,
                              message: 'Requerido',
                            },
                          ]}
                        >
                          <Input
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            onBlur={calcSubtotal}
                            disabled={
                              !formProducto.getFieldValue('producto_id') &&
                              tipoItem !== 'especial'
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item name="producto_iva" label="IVA">
                          <Input
                            prefix={'$'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            readOnly
                            disabled={
                              !formProducto.getFieldValue('producto_id') &&
                              tipoItem !== 'especial'
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item name="producto_neto" label="Neto">
                          <Input
                            prefix={'$'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            readOnly
                            disabled={
                              !formProducto.getFieldValue('producto_id') &&
                              tipoItem !== 'especial'
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                        <Form.Item name="producto_subtotal" label="Subtotal">
                          <Input
                            prefix={'$'}
                            min={0}
                            type="number"
                            style={{ textAlign: 'right' }}
                            readOnly
                            disabled={
                              !formProducto.getFieldValue('producto_id') &&
                              tipoItem !== 'especial'
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                )}
                <Row gutter={[8, 0]} align="middle">
                  <Form.List
                    name="items"
                    rules={[
                      {
                        validator: async (_, items) => {
                          if (validateItems(tipoId, devolucion, items)) {
                            return Promise.reject(
                              new Error('Ingrese al menos un item')
                            );
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        {(!stateData.object.data.id ||
                          (stateData.object.data.tipo_id < 0 &&
                            !stateData.object.data.parent_asociado)) && (
                          <Row
                            gutter={[8, 0]}
                            style={{ width: '100%' }}
                            justify="center"
                            align="top"
                          >
                            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => {
                                    formProducto.validateFields([
                                      'deposito_id',
                                      'producto_id',
                                      'producto_nombre',
                                      'producto_cantidad',
                                      'producto_precio_compra',
                                      'producto_alicuota',
                                      'producto_alicuota_id',
                                    ]);

                                    if (
                                      formProducto.getFieldValue(
                                        'producto_cantidad'
                                      ) &&
                                      formProducto.getFieldValue(
                                        'producto_precio_compra'
                                      ) &&
                                      formProducto.getFieldValue(
                                        'producto_alicuota'
                                      ) &&
                                      formProducto.getFieldValue(
                                        'producto_alicuota_id'
                                      )
                                    ) {
                                      const item = {
                                        producto_nid: null,
                                        producto_tipo: tipoItem,
                                        producto_tipo_id: getTipoItem(tipoItem),
                                        producto_asociado: null,
                                        producto_cotizacion:
                                          tipoItem === 'especial'
                                            ? 1
                                            : formProducto.getFieldValue(
                                                'producto_cotizacion'
                                              ),
                                        producto_precio_real:
                                          tipoItem === 'especial'
                                            ? parseFloat(
                                                formProducto.getFieldValue(
                                                  'producto_precio_compra'
                                                )
                                              )
                                            : formProducto.getFieldValue(
                                                'producto_precio_real'
                                              ),
                                        producto_moneda_id:
                                          tipoItem === 'especial'
                                            ? 1
                                            : formProducto.getFieldValue(
                                                'producto_moneda_id'
                                              ),
                                        producto_deposito:
                                          formProducto.getFieldValue(
                                            'deposito_id'
                                          ),
                                        producto_id:
                                          formProducto.getFieldValue(
                                            'producto_id'
                                          ),
                                        producto_nombre:
                                          formProducto.getFieldValue(
                                            'producto_nombre'
                                          ),
                                        producto_precio_compra: parseFloat(
                                          formProducto.getFieldValue(
                                            'producto_precio_compra'
                                          )
                                        ),
                                        producto_descuento:
                                          formProducto.getFieldValue(
                                            'producto_descuento'
                                          )
                                            ? formProducto.getFieldValue(
                                                'producto_descuento'
                                              )
                                            : 0,
                                        producto_mora:
                                          formProducto.getFieldValue(
                                            'producto_mora'
                                          )
                                            ? formProducto.getFieldValue(
                                                'producto_mora'
                                              )
                                            : 0,
                                        producto_precio:
                                          formProducto.getFieldValue(
                                            'producto_precio'
                                          ),
                                        producto_alicuota_id:
                                          formProducto.getFieldValue(
                                            'producto_alicuota_id'
                                          ),
                                        producto_alicuota:
                                          formProducto.getFieldValue(
                                            'producto_alicuota'
                                          ),

                                        producto_cantidad:
                                          formProducto.getFieldValue(
                                            'producto_cantidad'
                                          ),
                                        producto_iva:
                                          formProducto.getFieldValue(
                                            'producto_iva'
                                          ),
                                        producto_neto:
                                          formProducto.getFieldValue(
                                            'producto_neto'
                                          ),
                                        producto_subtotal:
                                          formProducto.getFieldValue(
                                            'producto_subtotal'
                                          ),
                                      };
                                      let error = false;
                                      if (
                                        form.getFieldValue('items') &&
                                        form
                                          .getFieldValue('items')
                                          .some((value) => {
                                            return (
                                              !value.producto_asociado &&
                                              value.producto_id ===
                                                item.producto_id &&
                                              value.producto_tipo ===
                                                item.producto_tipo &&
                                              tipoItem !== 'especial'
                                            );
                                          })
                                      ) {
                                        setErrorItemList(
                                          'El item seleccionado ya existe'
                                        );
                                        error = true;
                                      }

                                      if (!error) {
                                        handleAddItems(item);
                                      }
                                    }
                                  }}
                                  block
                                  icon={<PlusOutlined />}
                                  danger={
                                    errors.length || errorItemList
                                      ? true
                                      : false
                                  }
                                >
                                  Agregar item
                                </Button>
                                <span style={{ color: 'red' }}>
                                  {errorItemList}
                                </span>

                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        <Row
                          gutter={[8, 0]}
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            fontWeight: '500',
                            display:
                              (!form.getFieldValue('items') ||
                                form.getFieldValue('items').length === 0) &&
                              'none',
                          }}
                          align="top"
                          justify="center"
                        >
                          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                            Item
                          </Col>

                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Precio U
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Desc.
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Mora
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={3} xl={2}>
                            Precio
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Alic.
                          </Col>

                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Cantidad
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            IVA
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Neto
                          </Col>
                          <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                            Subtotal
                          </Col>
                          <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
                        </Row>
                        {fields.map(
                          ({ key, name, fieldKey, ...restField }, index) => (
                            <Row
                              gutter={[8, 0]}
                              style={{ width: '100%' }}
                              key={index}
                              align="top"
                              justify="center"
                            >
                              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_nombre']}
                                  fieldKey={[fieldKey, 'producto_nombre']}
                                  extra={
                                    form.getFieldValue('items')[index]
                                      .producto_moneda_id === 2 && (
                                      <span style={{ color: 'green' }}>
                                        U$S{' '}
                                        {
                                          form.getFieldValue('items')[index]
                                            .producto_precio_real
                                        }{' '}
                                        x{' '}
                                        {
                                          form.getFieldValue('items')[index]
                                            .producto_cotizacion
                                        }
                                      </span>
                                    )
                                  }
                                >
                                  <Input readOnly />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_precio_compra']}
                                  fieldKey={[
                                    fieldKey,
                                    'producto_precio_compra',
                                  ]}
                                  rules={[
                                    { required: true, message: 'Requerido' },
                                  ]}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    readOnly
                                    prefix={'$'}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_descuento']}
                                  fieldKey={[fieldKey, 'producto_descuento']}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    onBlur={() => calcSubtotalDetalle(index)}
                                    readOnly={
                                      stateData.object.data.tipo_id > 0 ||
                                      (stateData.object.data.tipo_id < 0 &&
                                        stateData.object.data.parent_asociado)
                                    }
                                    prefix={'%'}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_mora']}
                                  fieldKey={[fieldKey, 'producto_mora']}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    onBlur={() => calcSubtotalDetalle(index)}
                                    readOnly={
                                      stateData.object.data.tipo_id > 0 ||
                                      (stateData.object.data.tipo_id < 0 &&
                                        stateData.object.data.parent_asociado)
                                    }
                                    prefix={'%'}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_precio']}
                                  fieldKey={[fieldKey, 'producto_precio']}
                                  rules={[
                                    { required: true, message: 'Requerido' },
                                  ]}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    readOnly
                                    prefix={'$'}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_alicuota_id']}
                                  fieldKey={[fieldKey, 'producto_alicuota_id']}
                                >
                                  <Select
                                    onChange={() =>
                                      handleChangeAlicuotaDetalle(index)
                                    }
                                    open={
                                      stateData.object.data.tipo_id > 0 ||
                                      (stateData.object.data.tipo_id < 0 &&
                                        stateData.object.data.parent_asociado)
                                        ? false
                                        : undefined
                                    }
                                  >
                                    {alicuota.list.data.map((item) => (
                                      <Select.Option
                                        key={item.id}
                                        value={item.id}
                                      >
                                        {item.nombre}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_cantidad']}
                                  fieldKey={[fieldKey, 'producto_cantidad']}
                                  rules={[
                                    { required: true, message: 'Requerido' },
                                  ]}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    onBlur={() => calcSubtotalDetalle(index)}
                                    readOnly={
                                      stateData.object.data.tipo_id > 0 ||
                                      (stateData.object.data.tipo_id < 0 &&
                                        stateData.object.data.parent_asociado)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_iva']}
                                  fieldKey={[fieldKey, 'producto_iva']}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    readOnly
                                    prefix={'$'}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_neto']}
                                  fieldKey={[fieldKey, 'producto_neto']}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    readOnly
                                    prefix={'$'}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'producto_subtotal']}
                                  fieldKey={[fieldKey, 'producto_subtotal']}
                                >
                                  <Input
                                    min={0}
                                    type="number"
                                    style={{ textAlign: 'right' }}
                                    readOnly
                                    prefix={'$'}
                                  />
                                </Form.Item>
                              </Col>
                              {(!stateData.object.data.id ||
                                (stateData.object.data.tipo_id < 0 &&
                                  !stateData.object.data.parent_asociado)) && (
                                <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                                  <MinusCircleOutlined
                                    onClick={() => {
                                      const itemDeleted =
                                        form.getFieldValue('items')[index];
                                      remove(name);
                                      handleUpdateItems(itemDeleted);
                                    }}
                                    style={{ color: 'red' }}
                                  />
                                  {form.getFieldValue('items')[index]
                                    .producto_asociado && (
                                    <FileAddOutlined
                                      style={{
                                        marginLeft: '10px',
                                        color: 'blue',
                                      }}
                                    />
                                  )}
                                </Col>
                              )}
                            </Row>
                          )
                        )}
                      </>
                    )}
                  </Form.List>
                </Row>
              </Card>
            )}
          </Content>
          <Footer style={{ paddingRight: 0 }}>
            <Row gutter={[8, 0]} style={{ paddingBottom: '8px' }}>
              {!viewImportes(tipoId, devolucion) && (
                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                  <Card
                    title={
                      <TitleCard>
                        <Avatar
                          icon={<FileTextOutlined />}
                          size="small"
                          style={{ backgroundColor: '#4f43ba' }}
                        />
                        <span style={{ marginLeft: '3px' }}>Observaciones</span>
                      </TitleCard>
                    }
                    size="small"
                    style={{ height: '100%' }}
                    bodyStyle={{ paddingBottom: 0 }}
                  >
                    <Row gutter={[0, 0]}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item name="observacion">
                          <Input.TextArea rows={4} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              )}
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Card
                  title={
                    <TitleCard>
                      <Avatar
                        icon={<PercentageOutlined />}
                        size="small"
                        style={{ backgroundColor: '#4f43ba' }}
                      />
                      <span style={{ marginLeft: '3px' }}>Alicuotas</span>
                    </TitleCard>
                  }
                  size="small"
                  style={{ height: '100%' }}
                >
                  <Row gutter={[0, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.List name="alicuotas">
                        {(fields) => (
                          <>
                            <Row
                              gutter={[8, 0]}
                              style={{
                                width: '100%',

                                // textAlign: 'center',
                                // fontWeight: '500',
                                paddingBottom: '8px',
                                display:
                                  (!form.getFieldValue('alicuotas') ||
                                    form.getFieldValue('alicuotas').length ===
                                      0) &&
                                  'none',
                              }}
                              align="top"
                            >
                              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                Alic.
                              </Col>

                              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                Importe
                              </Col>
                              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                Base Imp
                              </Col>
                            </Row>
                            {fields.map(
                              (
                                { key, name, fieldKey, ...restField },
                                index
                              ) => (
                                <Row
                                  gutter={[8, 0]}
                                  style={{ width: '100%' }}
                                  key={index}
                                  align="top"
                                >
                                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'alicuota_nombre']}
                                      fieldKey={[fieldKey, 'alicuota_nombre']}
                                      style={{ marginBottom: '5px' }}
                                    >
                                      <Input
                                        prefix="%"
                                        min={0}
                                        type="number"
                                        style={{ textAlign: 'right' }}
                                        readOnly
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'alicuota_importe']}
                                      fieldKey={[fieldKey, 'alicuota_importe']}
                                      style={{ marginBottom: '5px' }}
                                    >
                                      <Input
                                        prefix="$"
                                        min={0}
                                        type="number"
                                        style={{ textAlign: 'right' }}
                                        readOnly
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'alicuota_base']}
                                      fieldKey={[fieldKey, 'alicuota_base']}
                                      style={{ marginBottom: '5px' }}
                                    >
                                      <Input
                                        prefix="$"
                                        min={0}
                                        type="number"
                                        style={{ textAlign: 'right' }}
                                        readOnly
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              )
                            )}
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Card
                  title={
                    <TitleCard>
                      <Avatar
                        icon={<PercentageOutlined />}
                        size="small"
                        style={{ backgroundColor: '#4f43ba' }}
                      />
                      <span style={{ marginLeft: '3px' }}> Percepciones</span>
                    </TitleCard>
                  }
                  style={{ height: '100%' }}
                  size="small"
                >
                  <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item name="percepcion_iva" label="Percepción Iva">
                        <Input
                          prefix={'$'}
                          min={0}
                          type="number"
                          style={{ textAlign: 'right' }}
                          onBlur={calcTotales}
                          readOnly={stateData.object.data.id}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item name="percepcion_iibb" label="Percepción IIBB">
                        <Input
                          prefix={'$'}
                          min={0}
                          type="number"
                          style={{ textAlign: 'right' }}
                          onBlur={calcTotales}
                          readOnly={stateData.object.data.id}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Form.Item name="no_gravado" label="No gravado">
                        <Input
                          prefix={'$'}
                          min={0}
                          type="number"
                          style={{ textAlign: 'right' }}
                          onBlur={calcTotales}
                          readOnly={stateData.object.data.id}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card
                  title={
                    <TitleCard>
                      <Avatar
                        icon={<DollarOutlined />}
                        size="small"
                        style={{ backgroundColor: '#4f43ba' }}
                      />
                      <span style={{ marginLeft: '3px' }}> Totales</span>
                    </TitleCard>
                  }
                  style={{ height: '100%' }}
                  size="small"
                >
                  <Row gutter={[20, 0]} justify="end">
                    <Col>
                      <Form.Item name="neto" label="Neto">
                        <Statistic
                          prefix="$"
                          precision={2}
                          groupSeparator="."
                          decimalSeparator=","
                        />
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item name="iva" label="Iva">
                        <Statistic
                          prefix="$"
                          precision={2}
                          groupSeparator="."
                          decimalSeparator=","
                        />
                      </Form.Item>
                    </Col>

                    <Col>
                      <Form.Item name="total" label="Total">
                        <Statistic
                          prefix="$"
                          precision={2}
                          groupSeparator="."
                          decimalSeparator=","
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={[8, 0]} justify="end" style={{ marginBottom: '10px' }}>
              <Col>
                <Button
                  type="default"
                  icon={<CloseCircleOutlined />}
                  size="small"
                  onClick={(e) => handleOnCancel()}
                >
                  Cancelar
                </Button>
              </Col>

              <Col>
                <Button
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  size="small"
                  onClick={() => {
                    form
                      .validateFields()
                      .then((values) => {
                        onCreate(values);
                      })
                      .catch((info) => {
                        console.log('Validate Failed:', info);
                      });
                  }}
                >
                  {stateData.object.data.id
                    ? 'Editar Comprobante'
                    : 'Generar Comprobante'}
                </Button>
              </Col>
            </Row>
          </Footer>
        </Form>
        {showEditProveedor ? (
          <ProveedorEditor
            id={proveedorId}
            onCancel={handleHideEditorProveedor}
            visible={true}
          />
        ) : (
          false
        )}
        {showEditProducto && tipoItem === 'producto' ? (
          <ProductoEditor
            id={productoId}
            onCancel={handleHideEditorProducto}
            visible={true}
            proveedorId={form.getFieldValue('proveedor_id')}
          />
        ) : (
          false
        )}

        {showAddAsociados ? (
          <AsociadosPopup
            proveedor={proveedorId}
            tipo={tipoId}
            onCancel={handleAsociadosCancel}
            onOk={handleAsociadosOk}
            itemSelected={form.getFieldValue('asociados')}
          />
        ) : (
          false
        )}
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  const {
    comprobanteCompra,
    proveedor,
    producto,
    comprobanteTipo,
    deposito,
    alicuota,
    rubroCompra,
    ubicacion,
  } = state;
  const stateData = comprobanteCompra;
  return {
    stateData,
    proveedor,
    producto,
    comprobanteTipo,
    deposito,
    alicuota,
    rubroCompra,
    ubicacion,
  };
};
export default connect(mapStateToProps)(ComprobanteCompraEditor);
