import React from 'react';
import { formatNumber } from '../../utils/funtions';
const CustomCellRender = ({ prefix, text, justify, style = {} }) => {
  return (
    <div
      style={{
        ...{
          display: 'flex',
          justifyContent: justify,
          width: '100%',
        },
        ...style,
      }}
    >
      <div>{prefix}</div>
      <div>{prefix === '$' ? formatNumber(text) : text}</div>
    </div>
  );
};
export default CustomCellRender;
