import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { columnsCobroComisionesPrint } from '../../utils/constants';
import { formatNumber } from '../../utils/funtions';

// Create styles
const styles = StyleSheet.create({
  text: {
    marginBottom: 3,
    fontSize: 14,
  },
  tableHeader: {
    fontSize: 10,
  },
  tableData: {
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 10,
  },
});

const CobroComisionesViewerItems = ({ data }) => {
  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          border: 1,
          padding: 3,
        }}
      >
        {columnsCobroComisionesPrint.map((col, idx) => {
          return (
            <Text
              style={[
                styles.tableHeader,
                { paddingLeft: '3px', width: col.width },
              ]}
              key={idx}
            >
              {col.title}
            </Text>
          );
        })}
      </View>
      {data.map((item, idx) => (
        <View style={{ flexDirection: 'row', padding: 3 }} key={idx}>
          {columnsCobroComisionesPrint.map((col, idx2) => {
            return (
              <Text
                style={[
                  styles.tableData,
                  col.index === 'total_comision'
                    ? { textAlign: 'right', width: col.width }
                    : { textAlign: 'left', width: col.width },
                ]}
                key={idx2}
              >
                {col.index === 'total_comision'
                  ? formatNumber(item[col.index])
                  : item[col.index]}
              </Text>
            );
          })}
        </View>
      ))}
    </>
  );
};
export default CobroComisionesViewerItems;
