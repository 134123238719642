import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { columnsCobroComprobantesPrint } from '../../utils/constants';
import { convertDate2, formatNumber } from '../../utils/funtions';

// Create styles
const styles = StyleSheet.create({
  text: {
    marginBottom: 3,
    fontSize: 14,
  },
  tableHeader: {
    fontSize: 10,
  },
  tableData: {
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 10,
  },
});

const CobroViewerComprobantes = ({ data, title }) => {
  return (
    <>
      <View
        style={{
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <Text style={styles.text}>{title}</Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          border: 1,
          padding: 3,
          marginTop: '5px',
        }}
      >
        {columnsCobroComprobantesPrint.map((col, idx) => {
          return (
            <Text
              style={[
                styles.tableHeader,
                { paddingLeft: '3px', width: col.width },
                col.index === 'total' ? {} : { borderRight: 1 },
              ]}
              key={idx}
            >
              {col.title}
            </Text>
          );
        })}
      </View>
      {data.map((item, idx) => (
        <View style={{ flexDirection: 'row', padding: 3 }} key={idx}>
          <Text
            style={[styles.tableData, { textAlign: 'left', width: '150px' }]}
          >
            {item.comprobante.tipo_nombre}
          </Text>
          <Text
            style={[styles.tableData, { textAlign: 'left', width: '150px' }]}
          >
            {item.comprobante.numero.padStart(8, '0')}
          </Text>
          <Text
            style={[styles.tableData, { textAlign: 'left', width: '150px' }]}
          >
            {convertDate2(item.comprobante.fecha)}
          </Text>

          <Text
            style={[styles.tableData, { textAlign: 'right', width: '150px' }]}
          >
            {formatNumber(item.comprobante.total)}
          </Text>
          <Text
            style={[styles.tableData, { textAlign: 'right', width: '150px' }]}
          >
            {formatNumber(item.comprobante.pendiente)}
          </Text>
          <Text
            style={[styles.tableData, { textAlign: 'right', width: '150px' }]}
          >
            {formatNumber(item.total)}
          </Text>
        </View>
      ))}
    </>
  );
};
export default CobroViewerComprobantes;
