import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  // PrinterOutlined,
} from '@ant-design/icons';
import { Button, Layout, Modal, Space } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  deleted,
  getAll,
  setPending,
} from '../redux/actions/productoMovimientoActions';
import { callStatus } from '../utils/constants';
import { convertDate, hasPermiso } from '../utils/funtions';
// import CustomCellRender from './base/CustomCellRender';
import MetaTable from './base/MetaTable';
// import ProductoMovimientoViewer from './viewer/ProductoMovimientoViewer';
const { confirm, error, success } = Modal;

const { Content, Header, Footer } = Layout;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const ProductoMovimientoList = ({
  location,
  stateData,
  productoMovimientoEstado,
  dispatch,
}) => {
  const [dataSource, setDataSource] = useState([]);
  // const [rowEdit, setRowEdit] = useState(null);
  // const [showViewer, setShowViewer] = useState(false);

  const history = useHistory();
  const formatDisplay = 'DD/MM/YYYY';

  useEffect(() => {
    getAll(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (stateData.list.status === callStatus.LIST_SUCCESS) {
      const data = [
        ...stateData.list.data.map((item) => {
          return {
            ...item,
            fecha: dayjs(
              new Date(moment(moment(convertDate(item.fecha)))).toISOString()
            ).format(formatDisplay),
            fecha_recepcion: item.fecha_recepcion
              ? dayjs(
                  new Date(
                    moment(moment(convertDate(item.fecha_recepcion)))
                  ).toISOString()
                ).format(formatDisplay)
              : '',
            receptor_nombre: item.receptor ? item.receptor.nombre : '',
            ubicacion_destino_nombre: item.ubicacion_destino
              ? item.ubicacion_destino.nombre
              : '',
          };
        }),
      ];

      setDataSource(data);
    }

    // eslint-disable-next-line
  }, [stateData]);

  const handleDelete = (data) => {
    dispatch(deleted(data.id));
  };

  const handleConfirm = (item) => {
    if (item.tipo_id > 0 && item.estado_afip_id === 2) {
      error({
        title: 'Este productoMovimiento no puede eliminarse, debe Anularlo',
        onOk() {},
      });
    } else {
      confirm({
        title: 'Esta a punto de eliminar un registro, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          handleDelete(item);
        },
        onCancel() {},
      });
    }
  };

  const handleEditor = (data) => {
    history.push(`/producto-movimientos/producto-movimiento/${data.id}`);
  };

  const handleAdd = () => {
    history.push(`/producto-movimientos/producto-movimiento/0`);
  };

  // const handlePrint = (data) => {
  //   setRowEdit(data);
  //   setShowViewer(true);
  // };

  // const handleHideViewer = () => {
  //   setShowViewer(false);
  //   setRowEdit(null);
  //   dispatch(setPending());
  // };

  const columns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={(e) => handleEditor(record)} />
          {hasPermiso(322) && (
            <DeleteFilled onClick={(e) => handleConfirm(record)} />
          )}
          {/* <PrinterOutlined onClick={(e) => handlePrint(record)} /> */}
        </Space>
      ),
      width: 90,
      ellipsis: true,
    },

    {
      width: 120,
      ellipsis: true,
      title: 'Número',
      dataIndex: 'id',
      sorter: (a, b) => a.numero.localeCompare(b.id),
      search: true,
    },

    {
      width: 150,
      ellipsis: true,
      title: 'Estado',
      dataIndex: 'estado',
      onFilter: (value, record) => {
        return record.estado_id === value;
      },
      sorter: (a, b) => a.estado.nombre.localeCompare(b.estado.nombre),
      filterMultiple: true,
      filters: productoMovimientoEstado.list.data.map((value) => {
        value['value'] = value.id;
        value['text'] = value.nombre;
        return value;
      }),
      render: (text, record) => {
        let colorEstado = '';
        switch (record.estado_id) {
          case 1:
            colorEstado = 'blue';
            break;
          case 2:
            colorEstado = 'orange';
            break;
          case 3:
            colorEstado = 'green';
            break;
          default:
            colorEstado = 'red';
            break;
        }

        return (
          <span style={{ color: colorEstado }}>{record.estado.nombre}</span>
        );
      },
    },
    {
      width: 120,
      ellipsis: true,
      title: 'Fecha emisión',
      dataIndex: 'fecha',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Emisor',
      dataIndex: 'emisor',
      onFilter: (value, record) => {
        return record.emisor_id === value;
      },
      sorter: (a, b) => a.emisor.nombre.localeCompare(b.emisor.nombre),
      // search: true,
      render: (text, record) => record.emisor.nombre,
    },

    {
      width: 200,
      ellipsis: true,
      title: 'Ubicación origen',
      dataIndex: 'ubicacion_origen',
      onFilter: (value, record) => {
        return record.ubicacion_origen_id === value;
      },
      sorter: (a, b) =>
        a.ubicacion_origen.nombre.localeCompare(b.ubicacion_origen.nombre),
      render: (text, record) => record.ubicacion_origen.nombre,
    },
    {
      width: 120,
      ellipsis: true,
      title: 'Fecha recepción',
      dataIndex: 'fecha_recepcion',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Receptor',
      dataIndex: 'receptor',
      onFilter: (value, record) => {
        return record.receptor_id === value;
      },
      sorter: (a, b) => a.receptor_nombre.localeCompare(b.receptor_nombre),
      // search: true,
      render: (text, record) => record.receptor_nombre,
    },

    {
      width: 200,
      ellipsis: true,
      title: 'Ubicación destino',
      dataIndex: 'ubicacion_destino',
      onFilter: (value, record) => {
        return record.ubicacion_destino === value;
      },
      sorter: (a, b) =>
        a.ubicacion_destino_nombre.localeCompare(b.ubicacion_destino_nombre),
      render: (text, record) => record.ubicacion_destino_nombre,
    },
  ];

  const handleShowMessage = (message, type, handler) => {
    switch (type) {
      case 'success':
        success({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      case 'error':
        error({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      // handleShowMessage(
      //   'El ProductoMovimiento se actualizó con éxito',
      //   'success',
      //   () => {}
      // );
      dispatch(setPending());
    }
    if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleShowMessage(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  return (
    <Layout style={{ height: '100%' }}>
      <Header></Header>
      <Content>
        <MetaTable
          dataSource={dataSource}
          dataColumns={columns}
          bordered={false}
          selection={false}
          loading={
            stateData.list.status === callStatus.LIST_IN_PROCESS ||
            stateData.object.status === callStatus.LIST_IN_PROCESS
          }
          pagination={true}
        />
      </Content>
      <Footer>
        <FooterContainer>
          <SelectedContainer></SelectedContainer>
          <ActionsContainer>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={(e) => handleAdd()}
            >
              Agregar
            </Button>
          </ActionsContainer>
        </FooterContainer>
      </Footer>

      {/* {showViewer && (
        <ProductoMovimientoViewer
          id={rowEdit.id}
          onCancel={handleHideViewer}
          visible={true}
        />
   
      )} */}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { productoMovimiento, productoMovimientoEstado } = state;
  const stateData = productoMovimiento;
  return {
    stateData,
    productoMovimientoEstado,
  };
};
export default connect(mapStateToProps)(withRouter(ProductoMovimientoList));
